import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { withStyles } from "@material-ui/core/styles";
import { Elements, StripeProvider } from "react-stripe-elements";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Close from "@material-ui/icons/Close";
//import decode from "jwt-decode";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import Avatar from "@material-ui/core/Avatar";
//import UpdateCard from './UpdateCard';

import CheckoutForm from "./CheckoutForm";
import MaterialTable from "material-table";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { dollar } from "./helper";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

import { apiUrl } from "../helpers";
import moment from "moment";
//import Info from "@material-ui/icons/Info";
import { STT_SVG } from "../utils/logo/SttLogoExport";
import { handleError } from "../utils/errorHandling/errorHelper";
import { TEST_STRIPE_KEY, STRIPE_KEY } from "../helpers";
import FreshdeskWidget from "@personare/react-freshdesk-widget";

let pathname = window.location.pathname;

let today = moment().format("MMM Do YY");
//moment js method to caculate ubsubsribe dates
moment.addRealMonth = function addRealMonth(d) {
  let fm = moment(d).add(1, "M");
  let fmEnd = moment(fm).endOf("month");
  return d.date() !== fm.date() && fm.isSame(fmEnd.format("YYYY-MM-DD"))
    ? fm.add(1, "d")
    : fm;
};

let nextMonth = moment.addRealMonth(moment()).format("MMM Do YY");
// console.log(today);
// console.log(nextMonth);

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  avatar: {
    margin: 0,
  },
  greenAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: green[500],
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 15,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },

  button: {
    margin: theme.spacing(1),
    cursor: "pointer",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    color: "#fff!important",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  unsubButton: {
    fontWeight: "400!important",
    textTransform: "lowercase!important",
  },
  upgradeButton: {
    backgroundColor: `${green[500]}!important`,
    color: "#fff!important",
    "&:hover": {
      backgroundColor: `${green[500]}!important`,
    },
  },
  updatePayment: {
    backgroundColor: "#0d47a1!important",
    color: "#fff!important",
    "&:hover": {
      backgroundColor: "#2164cc!important",
    },
  },
  resubButton: {
    backgroundColor: `${green[500]}!important`,
    marginLeft: "10px",
    color: "#fff!important",
    "&:hover": {
      backgroundColor: `${green[500]}!important`,
    },
  },
  linkStyle: {
    textDecoration: "none",
    color: "#fff",
  },
  linkBtnStyle: {
    textDecoration: "underline",
    color: "#3f51b5",
    border: "none",
    backgroundColor: "#fff",
    "&:hover": {
      color: "#0288d1",
      cursor: "pointer",
    },
  },
  unsubMsgWrapper: {
    //border: '1px solid red',
    [theme.breakpoints.down("lg")]: {
      marginBottom: "0",
      height: "100vh",
      //border: '5px solid purple',
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "250px",
      height: "100vh",
      //border: '1px solid blue',
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
      //border: '1px solid green',
      height: "100vh",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
      //border: '1px solid green',
      height: "100vh",
    },
  },
  surveyContentWrapper: {
    [theme.breakpoints.down("xs")]: {
      //marginBottom: '0px',
      border: "1px solid green",
      margin: "0px!important",
      //minWidth: '300px'
      //height: '100vh',
    },
  },
  unsubWrapper: {
    //border: '1px solid blue',
    padding: "0px",
  },
  pwdResetWrapper: {
    marginTop: "10px",
  },
  helpButton: {
    backgroundColor: "#fff",
    textDecoration: "underline",
    padding: "0px",
    margin: "0px",
    display: "inline",
    color: blue[800],
    border: "none",
    fontSize: "1rem",
    "&:hover": {
      color: blue[700],
    },
  },
  actionsWrapper: {
    height: "30px",
    margin: "0px",
    padding: "0px",
    paddingTop: "25px",
  },
  pwdDialogTitle: {
    paddingTop: "0px",
  },
  freshStyle: {
    display: "inline!important",
    "& div": {
      display: "inline!important",
    },
    "& button": {
      display: "inline!important",
    },
  },
  loaderCircle: {
    display: "block!important",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bottomBtnWrapper: {
    margin: "10px",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    //marginTop: '10px'
  },
  // ===== Survey Form Styling =======
  formControl: {
    //border: '1px solid yellow',
    width: "100%",
    margin: "0px",
    textAlign: "left!important",
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      padding: "0px!important",
      margin: "0px",
    },
  },
  groupLabel: {
    //border: '1px solid pink',
    minWidth: "250px",
    margin: "0px",
    marginLeft: "25%",
    marginRight: "25%",
    "& span": {
      fontSize: "1.1rem!important",
    },
    [theme.breakpoints.down("md")]: {
      // maxWidth: '280px!important',
      // marginLeft: '26%',
      // marginRight: '0',
      //border: '1px solid purple',
    },
    [theme.breakpoints.down("sm")]: {
      //maxWidth: '200px!important',
      // marginLeft: '14%',
      // marginRight: '16%',
      //border: '1px solid pink',
    },
    [theme.breakpoints.between("xs", "sm")]: {
      // marginLeft: '14%',
      // marginRight: '16%',
      //border: '1px solid purple',
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%!important",
      marginLeft: "20%",
      marginRight: "25%",
      // marginLeft: '10%!important',
      // marginRight: '10%!important',
      //border: '1px solid yellow',
    },
  },
  formContainer: {
    textAlign: "center",
    maxWidth: "480px",
  },
  textField: {
    minWidth: "200px",
    maxWidth: "200px",
    marginLeft: "33%",
    marginRight: "33%",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      marginRight: "auto",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      // maxWidth: '200px',
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  closeIcon: {
    width: "50px",
    margin: "10px",
  },
  option1Msg: {
    //border: '1px solid orange',
    fontSize: "1rem",
    color: "#3f51b5",
    textAlign: "center",
    marginTop: "0px",
    marginLeft: "10%",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      marginLeft: "10%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  option2Msg: {
    //border: '1px solid orange',
    fontSize: ".8rem",
    color: "#f21d2b",
    textAlign: "center",
    marginTop: "0px",
    marginLeft: "10%",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      marginLeft: "10%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  sendButton: {
    backgroundColor: "#f44336!important",
    color: "#fff",
    minWidth: "200px",
    maxWidth: "200px",
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: "20px",
  },

  // buttonProgress: {
  //   color: green[500],
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   marginTop: -12,
  //   marginLeft: -12,
  // },
  logoWrapper: {
    width: "60px",
    height: "60px",
    borderRadius: "200px",
    borderWidth: "3px",
    borderColor: "#cfd8dc",
    borderStyle: "solid",
    marginRight: "auto",
    marginLeft: "auto",
  },
  btnOptionsWrapper: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  accountInfoWrapper: {
    //textAlign: 'right',
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  collapseMenu: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  expandIcon: {
    paddingTop: "10px!important",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberUpdate: 0,
      invoices: [],
      posts: [],
      subscription: false,
      subscriptionType: "",
      fname: "",
      lname: "",
      email: "",
      unsubscribed: "",
      free: "",
      admin: false,
      cardupdated: false,
      dataready: "",
      loading: false,
      legacyMember: false,
      unsubMsg: false,
      pwdResetLoad: false,
      pwdResetSuccess: false,
      pwdResetSucessMsg: false,
      pwdResetFail: false,
      upgraded: false,
      openyearly: false,
      menuOpen: false,

      //survery state
      value: "",
      text: "",
      userEmail: "",
      showText: false,
      loadingSubmit: false,
      success: false,
      redirect: false,
      redirectMsg: false,
      feedback: false,
      processing: false,
      otherError: false,
    };
    //this.handleFormChange2 = this.handleFormChange2.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleCloseMsg = this.handleCloseMsg.bind(this);
    this.pwdResetClose = this.pwdResetClose.bind(this);
    this.closeFeedback = this.closeFeedback.bind(this);
    this.unsubSubmit = this.unsubSubmit.bind(this);
  }

  handleClickMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleFormChange(value) {
    //console.log(value);

    this.setState({
      text: "",
      value: value,
      otherError: false,
    });
  }

  // handleFormChange2 = event => {
  //   this.setState({ value: event.target.value });
  // };

  handleTextChange = name => event => {
    this.setState({
      [name]: event.target.value,
      otherError: false,
    });
  };

  closeFeedback() {
    this.setState({ feedback: false });
  }

  // go to next popup with unsubscribe and feedback
  nextHandleFeedback() {
    this.setState({
      open: false,
      feedback: true,
    });
  }

  // unsubscribe
  unsubSubmit() {
    let { text, value } = this.state;

    if (value === "option5" && text === "") {
      this.setState({ otherError: true });
      return;
    } else {
      try {
        if (!this.state.loadingSubmit) {
          this.setState(
            {
              otherError: false,
              success: false,
              loadingSubmit: true,
            },
            () => {
              // cancel subscription
              axios({
                method: "post",
                headers: {
                  authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
                },
                url: `${apiUrl}/rest/stripe/cancelSubscription/`,
                data: { text: text, value: value },
              })
                .then(res => {
                  try {
                    //console.log("unsub response", res);
                    if (res.status === 200) {
                      this.timer = setTimeout(() => {
                        this.setState({
                          loadingSubmit: false,
                          success: true,
                          feedback: false,
                          unsubscribed: true,
                          unsubMsg: true,
                          memberUpdate: this.state.memberUpdate + 1,
                        });
                      }, 2000);
                    }
                  } catch (err) {
                    handleError(err, pathname);
                  }
                })
                .catch(e => {
                  handleError(e, pathname);
                  this.setState({
                    unsubError: true,
                    feedback: false,
                    loadingSubmit: false,
                  });
                  //window.location.replace('/logout');
                });
            }
          );
        }
      } catch (err) {
        handleError(err, pathname);
      }
    }
  }

  handleCloseMsg() {
    this.setState({ unsubMsg: false });
  }

  componentDidMount() {
    //For user information
    document.title = "My Account - Search Texas Tax";
    ReactGA.initialize("UA-80211377-3");
    ReactGA.pageview(window.location.pathname);

    // Decoding token  for user sub status
    // const decoded = decode(localStorage.getItem('token'));

    // //console.log(decoded);
    // this.setState({ subscriptionType: decoded.a.subscriptionPlan});

    // make call to check if they are "old plan member" or thier "current sub status, monthly or yearly"
    axios({
      method: "get",
      url: `${apiUrl}/rest/stripe/subscription`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
    })
      .then(res => {
        // console.log("subscription status", res.data)
        let subscriptionType = res.data.subscriptionPlan;
        this.setState({ subscriptionType });
        if (subscriptionType === 0 || subscriptionType === 1) {
          this.setState({ legacyMember: true });
        }
      })
      .catch(e => {
        handleError(e, pathname);
        // window.location.replace('/logout');
      });

    axios({
      method: "get",
      url: `${apiUrl}/rest/history/users/`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
    })
      .then(res => {
        //console.log(res.data);
        const posts = res.data;
        this.setState({ posts: posts });
        try {
          if (this.state.posts[0].admin === 1) {
            this.setState({ admin: true });
          } else {
            this.setState({ admin: false });
          }

          const free = this.state.posts[0].free;
          this.setState({ free: free });

          const subscription = this.state.posts[0].subscriptionPlan;
          if (subscription === 0 || subscription === 2) {
            this.setState({ subscription: false });
          }
          const fname = this.state.posts[0].fname;
          const lname = this.state.posts[0].lname;
          const email = this.state.posts[0].email;
          this.setState({
            fname,
            lname,
            email,
          });
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .then(res => {
        try {
          if (this.state.free !== 1) {
            // For invoices
            axios
              .get(`${apiUrl}/rest/stripe/invoices`, {
                headers: {
                  authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
                },
              })
              .then(res => {
                const invoices = res.data.data;
                invoices.forEach(post => {
                  post.amount_paid = dollar(post.amount_paid / 100);
                  post.date = new Date(post.date * 1000) + "";
                });
                this.setState({ invoices: invoices });
              })
              .catch(e => {
                handleError(e, pathname);
                //window.location.replace('/logout');
              });

            // unsubscribe or resubscribe status
            axios({
              method: "get",
              url: `${apiUrl}/rest/history/checkstatus`,
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
              },
            })
              .then(res => {
                const unsubscribed = res.data;
                if (unsubscribed === true) {
                  this.setState({ menuOpen: true });
                }
                this.setState({ unsubscribed: unsubscribed });
              })
              .catch(e => {
                handleError(e, pathname);
                //window.location.replace('/logout');
              });
          }
        } catch (err) {
          handleError(err, pathname);
        }
        this.setState({ pagerender: "ready" });
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
  }

  componentDidUpdate(prevState) {
    if (
      this.state.memberUpdate !== prevState.memberUpdate &&
      this.state.upgraded === true
    ) {
      //console.log("upraded subscription plan!!!!");
      axios({
        method: "get",
        url: `${apiUrl}/rest/stripe/subscription`,
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
        },
      })
        .then(res => {
          //console.log("subscription status", res.data)
          let subscriptionType = res.data.subscriptionPlan;
          this.setState({ subscriptionType, upgraded: false });
          if (subscriptionType === 0 || subscriptionType === 1) {
            this.setState({ legacyMember: true });
          }
        })
        .catch(e => {
          handleError(e, pathname);
          // window.location.replace('/logout');
        });
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClickOpenYearly = () => {
    this.setState({ openyearly: true });
  };
  handleCloseYearly = () => {
    this.setState({ openyearly: false });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  //upgrade to yearly plan
  handleClickUpgrade() {
    // take away upgrade button
    // this.setState({
    //   upgraded: false,
    // })

    axios
      .get(`${apiUrl}/rest/stripe/upgradeSubscription`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
        },
      })
      .then(res => {
        //////console.log(res);
        try {
          if (res.data.token) localStorage.setItem("token", res.data.token);
          this.setState({
            unsubscribed: false,
            openyearly: false,
            memberUpdate: this.state.memberUpdate + 1,
            upgraded: true,
          });

          // For invoices
          axios
            .get(`${apiUrl}/rest/stripe/invoices`, {
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
              },
            })
            .then(res1 => {
              const invoices = res1.data.data;
              invoices.forEach(post => {
                post.amount_paid = dollar(post.amount_paid / 100);
                post.date = new Date(post.date * 1000) + "";
              });
              this.setState({ invoices: invoices });
              this.setState({ subscription: true });
            })
            .catch(e => {
              handleError(e, pathname);
              //window.location.replace('/logout');
            });
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
  }

  handleCloseUpdate = () => {
    this.setState({ opennew: false });
  };

  handleClickOpenUpdate = () => {
    //this.setState({ opennew: true });
    this.props.history.push("/stt/updatecard");
  };

  handlePasswordReset = () => {
    try {
      this.setState(
        {
          pwdResetSuccess: false,
          pwdResetLoad: true,
        },
        () => {
          this.timer = setTimeout(() => {
            axios({
              method: "post",
              url: `${apiUrl}/rest/passwordreset`,
              data: { email: this.state.email },
            })
              .then(res => {
                if (res.status === 200) {
                  this.setState({
                    pwdResetSuccess: true,
                    pwdResetSucessMsg: true,
                    pwdResetLoad: false,
                  });
                } else {
                  this.setState({ pwdResetFail: true, pwdResetLoad: false });
                }
              })
              .catch(e => {
                handleError(e, pathname);
                this.setState({ pwdResetFail: true, pwdResetLoad: false });
                //window.location.replace('/logout');
              });
          }, 2000);
        }
      );
    } catch (err) {
      handleError(err, pathname);
    }
  };

  pwdResetClose() {
    this.setState({ pwdResetSucessMsg: false, pwdResetLoad: false });
  }

  render() {
    const { classes } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: this.state.pwdResetSuccess,
    });

    let pagedisplay;
    if (this.state.pagerender === "ready" && this.state.free !== 1) {
      pagedisplay = (
        <Grid item xs={12}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <MaterialTable
            columns={[
              { title: "Amount Paid", field: "amount_paid" },
              {
                title: "Invoice PDF",
                render: rowData => {
                  return (
                    <a
                      href={`${rowData.invoice_pdf}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Download Invoice
                    </a>
                  );
                },
              },
              {
                title: "Date",
                field: "date",
                type: "String",
                render: rowData => {
                  return (
                    <div>{moment(rowData.date).format("MMMM Do YYYY")}</div>
                  );
                },
              },

              {
                title: "Status",
                render: rowData => {
                  return (
                    <Avatar className={classes.greenAvatar}>
                      {rowData.status}
                    </Avatar>
                  );
                },
              },
            ]}
            data={this.state.invoices}
            title="Invoices"
            options={{
              columnsButton: true,
                pageSizeOptions: [10, 20, 50, 100],
              pageSize: 5,
            }}
          />
        </Grid>
      );
    } else if (this.state.pagerender === "") {
      pagedisplay = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }
    let pagedisplaybutton;
    if (this.state.pagerender === "ready" && this.state.free === 0) {
      if (this.state.unsubscribed) {
        pagedisplaybutton = (
          <Button size="large" className={classes.resubButton}>
            <Link className={classes.linkStyle} to="/paymentplan">
              Resubscribe
            </Link>
          </Button>
        );
      } else {
        pagedisplaybutton = (
          <Button
            color="primary"
            size="large"
            className={classes.unsubButton}
            onClick={() => {
              this.handleClickOpen();
            }}
          >
            Cancel Plan
          </Button>
        );
      }
    } else if (this.state.pagerender === "") {
      pagedisplaybutton = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }
    let pagedisplayupgrade;
    if (this.state.pagerender === "ready") {
      // also check if unsubscribed is true
      if (
        (this.state.subscription ||
          this.state.free ||
          this.state.unsubscribed) &&
        (this.state.subscriptionType !== 1 || this.state.subscriptionType !== 3)
      ) {
        pagedisplayupgrade = <p></p>;
      } else if (
        this.state.subscription === false &&
        (this.state.subscriptionType === 0 || this.state.subscriptionType === 2)
      ) {
        pagedisplayupgrade = (
          <Button
            variant="contained"
            size="large"
            className={classes.upgradeButton}
            onClick={() => {
              this.handleClickOpenYearly();
            }}
          >
            Upgrade To Yearly
          </Button>
        );
      }
    }
    let pagedisplaypayment;
    if (this.state.pagerender === "ready") {
      if (this.state.free !== 1) {
        pagedisplaypayment = (
          <Button
            variant="contained"
            size="medium"
            className={classes.updatePayment}
            onClick={() => {
              this.handleClickOpenUpdate();
            }}
          >
            Update Payment details
          </Button>
        );
      }
    }

    let memberStatus;
    if (
      this.state.subscriptionType === 3 ||
      this.state.subscriptionType === 1
    ) {
      memberStatus = "Yearly";
    } else if (
      this.state.subscriptionType === 2 ||
      this.state.subscriptionType === 0
    ) {
      memberStatus = "Monthly";
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="search_paper">
            {this.state.success ? (
              <p className="success">
                Successfully unsubscribed, hope to see you soon!
              </p>
            ) : null}
            {this.state.unsubError ? (
              <p className="errormsg">
                Sorry, something went wrong. For assistance in unsubscribing
                please contact us via{" "}
                <span className={classes.freshStyle}>
                  &nbsp;
                  <FreshdeskWidget
                    url="https://searchtexastax.freshdesk.com"
                    type="pop-up"
                  >
                    <button className={classes.helpButton}>
                      {" "}
                      Help & Support.
                    </button>
                  </FreshdeskWidget>{" "}
                </span>
              </p>
            ) : null}
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.accountInfoWrapper}
              >
                <Grid item xs={12}>
                  <Typography variant="h5" component="h3">
                    My Account
                  </Typography>
                  <br />
                  <div style={{ marginBottom: "10px" }}>
                    <Typography color="textSecondary" variant="p" component="p">
                      Member Status : <strong>{memberStatus}</strong>
                    </Typography>
                  </div>
                </Grid>
                <div style={{ marginBottom: "3px" }}>
                  <Typography color="textSecondary" variant="p" component="p">
                    Name : {this.state.fname} {this.state.lname}
                  </Typography>
                </div>

                <Typography color="textSecondary" variant="p" component="p">
                  Email : {this.state.email}
                </Typography>
                <Grid item xs={12} className={classes.pwdResetWrapper}>
                  {this.state.pwdResetFail ? (
                    <p className="errormsg">
                      Password Reset Failed, please contact us via{" "}
                      <FreshdeskWidget
                        className={classes.freshStyle}
                        url="https://searchtexastax.freshdesk.com"
                        type="pop-up"
                      >
                        <button className={classes.helpButton}>
                          {" "}
                          Help & Support.
                        </button>
                      </FreshdeskWidget>{" "}
                      or try again later.
                    </p>
                  ) : null}
                  <Button
                    color="primary"
                    className={buttonClassname}
                    disabled={
                      this.state.pwdResetLoad || this.state.pwdResetSuccess
                    }
                    onClick={this.handlePasswordReset}
                  >
                    {!this.state.pwdResetSuccess ? (
                      <span>Reset Password</span>
                    ) : (
                      <span>Request Sent!</span>
                    )}
                    {this.state.pwdResetLoad && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                  <br />
                </Grid>
                <Grid item xs={12}>
                  {pagedisplayupgrade}
                  <br />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={classes.btnOptionsWrapper}
              >
                <List
                  component="nav"
                  subheader={
                    <ListSubheader component="div">User Options</ListSubheader>
                  }
                  className={classes.root}
                >
                  {this.state.admin ? (
                    <Grid item xs={12}>
                      <Link to="/stt/admin">
                        <Button
                          variant="contained"
                          size="large"
                          color="primary"
                          className="adminbutton"
                        >
                          User Details
                        </Button>
                      </Link>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {pagedisplaypayment}
                  <div>
                    <a> more </a>
                    {this.state.menuOpen ? (
                      <span>
                        <ExpandLess
                          className={classes.expandIcon}
                          onClick={this.handleClickMenu}
                        />
                      </span>
                    ) : (
                      <span>
                        <ExpandMore
                          className={classes.expandIcon}
                          onClick={this.handleClickMenu}
                        />
                      </span>
                    )}
                  </div>
                  <Collapse
                    in={this.state.menuOpen}
                    timeout="auto"
                    unmountOnExit
                    className={classes.collapseMenu}
                  >
                    <List component="div" disablePadding>
                      {pagedisplaybutton}
                      {this.state.free === 1 ? (
                        <small className="errormsg">
                          <em>Free Account</em>
                        </small>
                      ) : null}
                    </List>
                  </Collapse>
                </List>
              </Grid>
              {/* <Grid item xs={12} sm={6} className={classes.btnOptionsWrapper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h6">
                      User Options
                    </Typography>
                  </Grid>
                  {(this.state.admin) ? (
                    <Grid item xs={12}>
                      <Link to="/stt/admin">
                      <Button variant="contained" size="large" color="primary" className="adminbutton">
                        User Details
                      </Button>
                      </Link>
                    </Grid>
                  ) : ''}
                  <Grid item xs={12}>
                    <br />
                    {pagedisplaypayment}
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    {pagedisplaybutton}
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>

            <Dialog
              open={this.state.opennew}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleCloseUpdate}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <StripeProvider apiKey={STRIPE_KEY}>
                  <div className="example">
                    <Elements>
                      <CheckoutForm
                        formType="updatePayment"
                        onCardUpdate={this.handleCardUpdate}
                      />
                    </Elements>
                  </div>
                </StripeProvider>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseUpdate} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
        {pagedisplay}

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.unsubMsgWrapper}
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to Cancel?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography style={{ color: "#424242", textAlign: "center" }}>
                <h3>Before You Go...</h3>
                <div style={{ maxWidth: "500px", padding: 30 }}>
                  <h2
                    style={{
                      marginTop: "15px",
                      marginBottom: "0px",
                      color: "#424242",
                    }}
                  >
                    We're the Best!
                  </h2>
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "15px",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    We provide you the most current Texas hotel AND mixed
                    beverage revenue data for a single price much lower than our
                    competitors. Our site's also much faster than the others.
                  </p>
                  <h2
                    style={{
                      margin: "0px",
                      color: "#424242",
                      marginTop: "30px",
                    }}
                  >
                    We'll Make it Right!
                  </h2>
                  <p
                    style={{
                      textAlign: "left",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    We understand issues with our site pop up from time to time,
                    but if you let us know, we're pretty quick about fixing
                    them!
                  </p>
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "15px",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    Anywho, we'd appreciate another chance and we're glad you
                    gave us a swing but if you've gotta go, we understand.
                  </p>
                  <h2
                    style={{
                      margin: "0px",
                      color: "#424242",
                      marginTop: "30px",
                    }}
                  >
                    No Hard Feeings
                  </h2>
                  <p
                    style={{
                      textAlign: "left",
                      marginBottom: "15px",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    If you cancel today, you can continue using our site until
                    the current plan is completed. Hey, you can also change your
                    mind and resubscribe later!
                  </p>

                  {this.state.legacyMember ? (
                    <div style={{ marginTop: "25px", color: "#455a64" }}>
                      <p>
                        <h3 style={{ marginBottom: "5px" }}>
                          <strong>PLEASE READ:</strong>
                        </h3>
                        <Divider style={{ marginBottom: "5px" }} />
                        <p style={{ margin: "0px" }}>
                          <strong>
                            Prices have changed from the time you registerd!
                          </strong>
                        </p>
                        <em>
                          Unsubscribing now from your original plan you will
                          lose your current pricing as a SearchTexasTax Legacy
                          Member. Whenever you choose to re-subscribe, you will
                          start a new subscription with our{" "}
                          <strong>new pricing</strong>.{" "}
                        </em>
                      </p>
                    </div>
                  ) : null}
                </div>
              </Typography>
            </DialogContentText>
            <Grid container>
              <Grid item xs={12} sm={6} className="unsubFormWrapper">
                <Button
                  onClick={this.handleClose}
                  color="primary"
                  className="nevermindButton"
                >
                  Nevermind
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} className="unsubFormWrapper">
                <Button
                  onClick={() => {
                    this.nextHandleFeedback();
                  }}
                  color="secondary"
                  className="yesUnsubButton"
                >
                  Yes, Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        {/* =========== User Feedback Optional ==========*/}
        <Dialog
          open={this.state.feedback}
          //onClose={this.closeFeedback}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.unsubMsgWrapper}
        >
          <IconButton
            className={classes.closeIcon}
            onClick={this.closeFeedback}
            aria-label="Delete"
          >
            <Close />
          </IconButton>
          <DialogContent className={classes.unsubWrapper}>
            <DialogContentText id="alert-dialog-description">
              <Grid container className={classes.formContainer}>
                <div className={classes.logoWrapper}>
                  <STT_SVG />
                </div>
                <Grid item xs={12}>
                  {this.state.success ? (
                    <p className="success">We Appreciate your feedback!</p>
                  ) : null}
                  {this.state.processing ? <small>Processing...</small> : null}
                  <div style={{ marginTop: "20px" }}>
                    <Typography variant="h4">
                      We Value Your Feedback!
                    </Typography>
                    <div style={{ textAlign: "center" }}>
                      <p style={{ fontSize: "1.2rem" }}>
                        Please let us know why you're leaving.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormControlLabel
                      className={classes.groupLabel}
                      checked={this.state.value === "option1"}
                      value="option1"
                      onChange={e => this.handleFormChange("option1", e)}
                      control={<Radio name="option" />}
                      label="It's too expensive."
                    />
                    {this.state.value === "option1" ? (
                      <p className={classes.option1Msg}>
                        We strive to make our services as affordable as possible
                        and much lower than our current competitors.
                      </p>
                    ) : null}
                    <FormControlLabel
                      className={classes.groupLabel}
                      checked={this.state.value === "option3"}
                      value="option3"
                      name="option"
                      onChange={e => this.handleFormChange("option3", e)}
                      control={<Radio name="option" />}
                      label="Didn't work as expected."
                    />
                    {this.state.value === "option3" ? (
                      <TextField
                        placeholder="Please specify. (optional)"
                        multiline
                        rows="3"
                        inputProps={{ maxLength: 300 }}
                        value={this.state.text}
                        onChange={this.handleTextChange("text")}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                      />
                    ) : null}
                    <FormControlLabel
                      className={classes.groupLabel}
                      checked={this.state.value === "option2"}
                      value="option2"
                      name="option"
                      onChange={e => this.handleFormChange("option2", e)}
                      control={<Radio name="option" />}
                      label="I'm having too many technical issues."
                    />
                    {this.state.value === "option2" ? (
                      <TextField
                        placeholder="Please specify. (optional)"
                        multiline
                        rows="3"
                        inputProps={{ maxLength: 300 }}
                        value={this.state.text}
                        onChange={this.handleTextChange("text")}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                      />
                    ) : null}
                    <FormControlLabel
                      className={classes.groupLabel}
                      checked={this.state.value === "option4"}
                      value="option4"
                      name="option"
                      onChange={e => this.handleFormChange("option4", e)}
                      control={<Radio name="option" />}
                      label="I don't use the site often enough"
                    />
                    <FormControlLabel
                      className={classes.groupLabel}
                      checked={this.state.value === "option5"}
                      value="option5"
                      name="option"
                      onChange={e => this.handleFormChange("option5", e)}
                      control={<Radio name="option" />}
                      label="Other (please specify)."
                    />
                    {this.state.value === "option5" ? (
                      <TextField
                        error={this.state.otherError}
                        placeholder="Please specify."
                        multiline
                        rows="3"
                        inputProps={{ maxLength: 300 }}
                        value={this.state.text}
                        onChange={this.handleTextChange("text")}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                      />
                    ) : null}
                    {this.state.otherError ? (
                      <p className={classes.option2Msg}>
                        please specify before submitting.
                      </p>
                    ) : null}
                  </FormControl>
                  <div style={{ display: "block" }}>
                    <button
                      className={classes.linkBtnStyle}
                      onClick={this.closeFeedback}
                    >
                      I've changed my mind.
                    </button>
                  </div>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.state.success ? null : (
              <React.Fragment>
                <Button
                  //color="primary"
                  variant="contained"
                  className={classes.sendButton}
                  disabled={this.state.loadingSubmit || this.state.otherError}
                  onClick={this.unsubSubmit}
                >
                  cancel plan
                  {this.state.loadingSubmit && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </React.Fragment>
            )}
          </DialogActions>
        </Dialog>

        {/* ====== Yearly Message ======= */}
        <Dialog
          open={this.state.openyearly}
          onClose={this.handleCloseYearly}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Upgrade to Yearly Plan"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Upgrading to Yearly you will be charged $150, your subscription
              will start today, {moment().format("YYYY[-]MM[-]DD")} and auto
              renew yearly.
              <p>
                <em>
                  **If you first signed up with our monthly plan and are
                  upgrading <strong>the same month</strong> you will only be
                  charged the difference.
                </em>
              </p>
              {this.state.upgraded ? (
                <div style={{ textAlign: "center" }}>
                  <small>Upgrading Subscription...</small>
                  <CircularProgress
                    className={classes.loaderCircle}
                    size={50}
                  />
                </div>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseYearly} color="primary">
              Nevermind
            </Button>
            <Button
              onClick={() => {
                this.handleClickUpgrade();
              }}
              color="primary"
              autoFocus
            >
              Yes, Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* ======= unsub feedback ======= */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.unsubMsg}
          onClose={this.handleCloseMsg}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Plan canceled.</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseMsg}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
        {/* ====== Reset Password Feedback */}
        <Dialog
          open={this.state.pwdResetSucessMsg}
          onClose={this.pwdResetClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogActions className={classes.actionsWrapper}>
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.pwdResetClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogTitle
            id="alert-dialog-title"
            className={classes.pwdDialogTitle}
          >
            {"Request for Password Reset has been sent!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                You will receive an email to <strong>{this.state.email}</strong>{" "}
                for password reset.
                <p>
                  If you do not receive an email, please check your spam folder.{" "}
                </p>
                <p>
                  <span>
                    Still haven't received an email? Shoot us a message so we
                    can assist you via
                  </span>
                  <span style={{ display: "inline" }}>
                    {" "}
                    <FreshdeskWidget
                      url="https://searchtexastax.freshdesk.com"
                      type="pop-up"
                    >
                      <button className={classes.helpButton}>
                        {" "}
                        Help & Support.
                      </button>
                    </FreshdeskWidget>
                  </span>
                </p>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Grid>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Settings);
