import React, { Component } from 'react';
import RecentlyViewed from './RecentlyViewed';
import GetUniDataTable from './GetUniDataTable';
import GetUniMBTTable from './GetUniMBTTable';
import ReactGA from 'react-ga';


class SearchResults extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    };

    componentDidMount() {
        //let searchItem = this.props.match.params.item;
        let searchValue = this.props.match.params.itemvalue;
        document.title = `Search Results ${searchValue.toUpperCase()}, TX - Search Texas Tax`;
        ReactGA.initialize('UA-80211377-3');
        ReactGA.pageview(window.location.pathname);
    }

    render() {

        return (
            <div>
                {this.props.match.params.type === "mbt" ?
                    <GetUniMBTTable className="table-box" id="table-box" /> :
                    <GetUniDataTable />
                }
                <br />
                <RecentlyViewed />
            </div>
        )
    }
}
export default SearchResults;