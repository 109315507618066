import React from 'react'
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

class Logout extends React.Component {

  componentDidMount() {
    if (localStorage.getItem('token')) {
      var tokenid = localStorage.getItem('token');
      localStorage.removeItem('token');
      //console.log(tokenid);
      axios.get(`${apiUrl}/rest/basic/userlogout`,
        {
          headers: {
            authorization: 'Bearer ' + tokenid //the token is a variable which holds the token
          }
        })
        .then(res => {

          localStorage.removeItem('token');
          localStorage.removeItem('stripeStatus');
        }).catch(e => {
          handleError(e, pathname);
          //window.location.replace('/logout');
        });

    }
  }

  render() {
    return (

      <Redirect to='/login' />

    )
  }

}
export default Logout;