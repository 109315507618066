export const apiUrl = "https://api.searchtexastax.com";
// export const apiUrl = "https://api-dev.searchtexastax.com";
export const TEST_STRIPE_KEY = "pk_test_cgxtYgq9haMuyFLdDMJJJaBK";
export const STRIPE_KEY = "pk_live_axE2h5roRUpqf3AlMHbupm6n";
export const RECAPTCHA_SITE_KEY = "6Lf3n6wUAAAAAJOctgkaFRBKlAjDoCN15srEJZmr";
// roxanne's key below
//export const RECAPTCHA_SITE_KEY = "6LcNj3oUAAAAANc4bazisYk960Wq4L0NfSFqSJlt";
export const GM_KEY = "AIzaSyDjmwSKus5TViafCXw_52tKauOpTbKbvy4";

 

