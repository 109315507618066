import React, {Component} from 'react'
import { Redirect, Route } from 'react-router-dom';
import {Elements, StripeProvider} from 'react-stripe-elements';
import {TEST_STRIPE_KEY, STRIPE_KEY} from '../helpers';
import axios from 'axios';
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

class PaymentRoute extends Component {
  constructor(props){
    super(props);
    this.state ={
      redirect: false
    }
  }

  

  componentWillMount(){

    // first check what path we are on since Update Card shares same file
    // if(this.props.path = "/stt/updatecard"){
    //   this.setState({})
    // }
    // check subscrption status
    
    axios({
      method: 'get',
      url: `${apiUrl}/rest/stripe/subscription`,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
      }
    }).then((res) => {
    //  console.log("subscription status", res.data)
     let subStatus = res.data.stripeActive
     // if active redirect to home, else show page
     subStatus === 1 ? this.setState({ redirect: true }) : this.setState({ redirect: false});
    }).catch(e => {
      handleError(e, pathname);
      // window.location.replace('/logout');
    });
  }

  render() {

    const { component: Component, ...rest } = this.props;


    return (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem('token') && this.state.redirect === false ? (
            <StripeProvider apiKey={STRIPE_KEY}>
            {/* <StripeProvider apiKey={TEST_STRIPE_KEY}> */}
               <Elements>
                 <Component {...props} />
               </Elements>
            </StripeProvider>
            
          ) : (
              <Redirect to={{ pathname: '/stt/search', state: { from: props.location } }} />
            )
        }
      />
    )
  }
}

export default PaymentRoute;