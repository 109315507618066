/* eslint-disable no-new-object */
import React, { useContext } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import Icon from "@material-ui/core/Icon";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";

let pathname = window.location.pathname;

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 350,
    paddingTop: 100,
  },
});

class GetUniDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      token: "",
      getposts: [],
      isLoading: true,
      noOptions: false,
      displayMessage: "SEARCHING...",
    };
  }

  // on component initialization, get results and save to state as array of objects -- posts & getPosts
  componentDidMount() {
    axios
      .get(
        `${apiUrl}/rest/search/${this.props.match.params.type}/${this.props.match.params.item}/${this.props.match.params.itemvalue}`,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
          },
        }
      )
      .then(res => {
        try {
          const posts = res.data;
          var getposts = [];
          for (var i = 0; i < posts.length; i++) {
            getposts.push(new Object());
            getposts[i].date = posts[i].date;
            getposts[i].filer_type = posts[i].filer_type;
            getposts[i].taxpayer_name = posts[i].taxpayer_name;
            getposts[i].taxpayer_address = posts[i].taxpayer_address;
            getposts[i].taxpayer_number = posts[i].taxpayer_number;
            getposts[i].taxpayer_city = posts[i].taxpayer_city;
            getposts[i].taxpayer_phone = posts[i].taxpayer_phone;
            getposts[i].obligation_end_date = posts[i].obligation_end_date;
            getposts[i].responsibility_begin_date =
              posts[i].responsibility_begin_date;
            getposts[i].responsibility_end_date =
              posts[i].responsibility_end_date;
            getposts[i].location_name = posts[i].location_name;
            getposts[i].location_phone = posts[i].location_phone;
            getposts[i].location_address = posts[i].location_address;
            getposts[i].location_city = posts[i].location_city;
            getposts[i].location_county = posts[i].verified_county;
            getposts[i].location_zip = posts[i].location_zip;
            getposts[i].room_receipts = posts[i].room_receipts;
            getposts[i].taxable_receipts = posts[i].taxable_receipts;
            getposts[i].RevPAR = posts[i].RevPAR;
            getposts[i].room_capacity = posts[i].room_capacity;
            getposts[i].month = posts[i].month;
            getposts[i].year = posts[i].year;
          }
          // console.log(getposts);
          // the the state to array of objects
          if (getposts.length === 0 || getposts) {
            this.setState({ displayMessage: "No Records Found." });
          }
          this.setState({
            getposts,
            posts,
            isLoading: false,
          });
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .catch(e => {
        this.setState({ isLoading: false });
        handleError(e, pathname);
        // window.location.replace('/login');
      });
  }

  componentDidUpdate(nextProps) {
    if (nextProps.queryFromSearch !== this.props.queryFromSearch) {
      axios
        .get(this.props.queryFromSearch, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
          },
        })
        .then(res => {
          ////console.log(res);
          try {
            const posts = res.data;

            var getposts = [];
            for (var i = 0; i < posts.length; i++) {
              getposts.push(new Object());
              getposts[i].date = posts[i].date;
              getposts[i].filer_type = posts[i].filer_type;
              getposts[i].taxpayer_name = posts[i].taxpayer_name;
              getposts[i].taxpayer_address = posts[i].taxpayer_address;
              getposts[i].taxpayer_number = posts[i].taxpayer_number;
              getposts[i].taxpayer_city = posts[i].taxpayer_city;
              getposts[i].taxpayer_phone = posts[i].taxpayer_phone;
              getposts[i].obligation_end_date = posts[i].obligation_end_date;
              getposts[i].responsibility_begin_date =
                posts[i].responsibility_begin_date;
              getposts[i].responsibility_end_date =
                posts[i].responsibility_end_date;
              getposts[i].location_name = posts[i].location_name;
              getposts[i].location_phone = posts[i].location_phone;
              getposts[i].location_address = posts[i].location_address;
              getposts[i].location_city = posts[i].location_city;
              getposts[i].location_county = posts[i].verified_county;
              getposts[i].location_zip = posts[i].location_zip;
              getposts[i].room_receipts = posts[i].room_receipts;
              getposts[i].taxable_receipts = posts[i].taxable_receipts;
              getposts[i].RevPAR = posts[i].RevPAR;
              getposts[i].room_capacity = posts[i].room_capacity;
              getposts[i].month = posts[i].month;
              getposts[i].year = posts[i].year;
            }
            //console.log(getposts);
            this.setState({
              getposts,
              posts,
            });
          } catch (err) {
            handleError(err, pathname);
          }
        })
        .catch(e => {
          handleError(e, pathname);
          //window.location.replace('/login');
        });
    }
  }

  render() {
    const { classes } = this.props;
    //const columns = ["Name", "Location City", "Total Revenue", "Taxable Revenue"];
    return (
      <Grid item xs={12}>
        <CSVLink
          data={this.state.getposts}
          className="csvdownload"
          filename="HotelSearchResults.csv"
        >
          <Button className="csvbutton" title="Export As CSV">
            <Icon className="csvicon">save_alt</Icon>
          </Button>
        </CSVLink>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <MaterialTable
          isLoading={this.state.isLoading}
          columns={[
            {
              title: "Name",
              field: "name",
              render: rowData => {
                return (
                  <Link
                    to={`/stt/hotelinfo/${rowData.name
                      .replace("/", "(slash)")
                      .replace("#", "(hash)")}/${rowData.taxpayer_number}/${
                      rowData.location_number
                    }`}
                  >
                    {rowData.name}
                  </Link>
                );
              },
              type: "html",
            },
            {
              title: "TaxPayer Number",
              field: "taxpayer_number",
              hidden: true,
            },
            // { title: 'TaxPayer Name', field: 'taxpayer_name', hidden: true },
            { title: "Address", field: "address" },
            {
              title: "City",
              render: rowData => {
                return (
                  <Link
                    to={`/stt/city/${rowData.city}`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {rowData.city}
                  </Link>
                );
              },
            },
            {
              title: "County",
              render: rowData => {
                return (
                  <Link
                    to={`/stt/county/${rowData.county}`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {rowData.county}
                  </Link>
                );
              },
            },
            { title: "Zip", field: "zip" },
          ]}
          data={this.state.posts}
          title="Hotel Search Results"
          options={{
            //sorting: true,
            columnsButton: true,
            pageSizeOptions: [10, 50, 100],
            pageSize: 10,
            search: true,
            showEmptyDataSourceMessage: true,
            actionsColumnIndex: 10,
            loadingType: "linear",
          }}
          localization={{
            body: {
              emptyDataSourceMessage: this.state.displayMessage,
            },
          }}
          //isLoading={this.state.isLoading}
        />
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(GetUniDataTable));
