import React from 'react';
import "./infoWindow.css";

function InfoWindow(props) {
    return (
        <div className="info-window">
            {props.children}
        </div>
    )
}

export default InfoWindow;