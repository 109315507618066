import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import green from "@material-ui/core/colors/green";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { Chart } from "react-google-charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";

let pathname = window.location.pathname;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    margin: 0,
  },
  pinkAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#d50000",
    width: 100,
    height: 30,
    borderRadius: 5,
    fontSize: 15,
  },
  blueAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#0d47a1",
    width: 100,
    height: 30,
    borderRadius: 5,
    fontSize: 15,
  },
  greenAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: green[500],
    width: 100,
    height: 30,
    borderRadius: 5,
    fontSize: 15,
  },
  greenButton: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#388e3c",
    width: 100,
    height: 30,
    borderRadius: 5,
    fontSize: 15,
  },
  orangeButton: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#ff3d00",
    width: 100,
    height: 30,
    borderRadius: 5,
    fontSize: 15,
  },
  greyButton: {
    margin: 0,
    color: "#424242",
    backgroundColor: "#e0e0e0",
    fontWeight: "bold",
    width: 110,
    height: 30,
    borderRadius: 5,
    fontSize: 15,
  },
  recent: {},
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "relative",
    bottom: theme.spacing(10),
    left: theme.spacing(170),
  },
});

const options = {
  title: "Active Subscibers",
  pieHole: 0.4,
};
const customeroptions = {
  title: "Total Number of Users",
  pieHole: 0.4,
};
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      token: "",
      dataLoadingStatus: "loading",
      chartData: [],
      value: 0,
      type: "",
      portfolio: "",
      errormsg: false,
      donutarray: [],
      customertrend: [],
      notAdmin: false,
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ errormsg: false });
    this.setState({ portfolio: "" });
  };
  componentDidMount() {
    axios({
      method: "get",
      url: `${apiUrl}/rest/history/users`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
    })
      .then(res => {
        const posts = res.data;
        this.setState({ posts: posts });
        if (this.state.posts[0].admin === 1) {
          document.title = "Admin Page- Search Texas Tax";
          ReactGA.initialize("UA-80211377-3");
          ReactGA.pageview(window.location.pathname);

          axios({
            method: "get",
            url: `${apiUrl}/rest/admin`,
            headers: {
              authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
            },
          })
            .then(res => {
              try {
                const posts = res.data;
                this.setState({ posts: posts });

                //customer trends
                var donutarray = [];
                var customertrend = [];
                var monthlycount = 0;
                var yearlycount = 0;
                var freecount = 0;
                var activecount = 0;
                var inactivecount = 0;
                for (var i = 0; i < posts.length; i++) {
                  if (posts[i].stripeActive === 1 && posts[i].free === 0) {
                    activecount = activecount + 1;

                    if (posts[i].subscriptionPlan === 0) {
                      monthlycount = monthlycount + 1;
                    } else if (posts[i].subscriptionPlan === 1) {
                      yearlycount = yearlycount + 1;
                    }
                  } else if (
                    posts[i].stripeActive === 0 &&
                    posts[i].free === 0
                  ) {
                    inactivecount = inactivecount + 1;
                  }
                  //customer trends
                  if (posts[i].free === 1) {
                    freecount = freecount + 1;
                  }
                }
                donutarray.push(["Subscription Plan", "Number of People"]);
                donutarray.push(["Active Monthly Subscribers", monthlycount]);
                donutarray.push(["Active Yearly Subscribers", yearlycount]);
                this.setState({
                  donutarray: donutarray,
                  dataLoadingStatus1: "ready",
                });

                customertrend.push(["Customer Trends", "Number of People"]);
                customertrend.push(["Free Users", freecount]);
                customertrend.push(["Active Users", activecount]);
                customertrend.push(["Inactive Users", inactivecount]);
                this.setState({
                  customertrend: customertrend,
                  dataLoadingStatus: "ready",
                });
              } catch (err) {
                handleError(err, pathname);
              }
            })
            .catch(e => {
              handleError(e, pathname);
              //window.location.replace('/login');
            });
        } else {
          this.setState({ notAdmin: true });
        }
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }

  render() {
    const { classes } = this.props;
    //const { anchorEl } = this.state;
    //const open = Boolean(anchorEl);
    // const { value } = this.state;

    let firstdonut;
    if (this.state.dataLoadingStatus1 === "ready") {
      firstdonut = (
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={this.state.customertrend}
          options={customeroptions}
        />
      );
    } else {
      firstdonut = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }
    let seconddonut;
    if (this.state.dataLoadingStatus === "ready") {
      seconddonut = (
        <Chart
          chartType="PieChart"
          width="100%"
          height="400px"
          data={this.state.donutarray}
          options={options}
        />
      );
    } else {
      seconddonut = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <Grid container spacing={24}>
          <Grid item xs={6} className="chartmobilegrid">
            <Paper>{firstdonut}</Paper>
          </Grid>
          <Grid item xs={6} className="chartmobilegrid">
            <Paper>{seconddonut}</Paper>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              columns={[
                {
                  title: "Stripe Customer Id",
                  field: "stripeCustomerId",
                  type: "html",
                  render: rowData => {
                    return rowData.stripeCustomerId ? (
                      <span style={{ fontSize: ".8rem" }}>
                        {rowData.stripeCustomerId}
                      </span>
                    ) : (
                      "null"
                    );
                  },
                },
                { title: "First Name", field: "fname" },
                { title: "Last Name", field: "lname" },
                { title: "Email", field: "email" },
                {
                  title: "Last Login",
                  field: "lastLogin",
                  type: "html",
                  render: rowData => {
                    return rowData.lastLogin === "0000-00-00 00:00:00" ? (
                      <span>
                        <em>No login.</em>
                      </span>
                    ) : (
                      <span>
                        {moment(rowData.lastLogin).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </span>
                    );
                  },
                },

                {
                  title: "Subscription Plan",
                  field: "subscriptionPlan",
                  type: "html",
                  render: rowData => {
                    return rowData.subscriptionPlan === 0 ? (
                      <Avatar className={classes.pinkAvatar}>Monthly</Avatar>
                    ) : rowData.subscriptionPlan === 1 ? (
                      <Avatar className={classes.blueAvatar}>Yearly</Avatar>
                    ) : rowData.subscriptionPlan === 2 ? (
                      <Avatar className={classes.blueAvatar}>
                        New Monthly
                      </Avatar>
                    ) : rowData.subscriptionPlan === 3 ? (
                      <Avatar className={classes.blueAvatar}>New Yearly</Avatar>
                    ) : null;
                  },
                },
                {
                  title: "Stripe Active Status",
                  field: "stripeActive",
                  type: "html",
                  render: rowData => {
                    return rowData.stripeActive === 1 ? (
                      <Avatar className={classes.blueAvatar}>Subscribed</Avatar>
                    ) : rowData.stripeActive === 0 ? (
                      <Avatar className={classes.greyButton}>
                        Unsubscribed
                      </Avatar>
                    ) : null;
                  },
                },
                {
                  title: "Account Type",
                  field: "free",
                  type: "html",
                  render: rowData => {
                    return rowData.free === 0 ? (
                      <Avatar className={classes.greenButton}>Paid</Avatar>
                    ) : rowData.free === 1 ? (
                      <Avatar className={classes.orangeButton}>Free</Avatar>
                    ) : null;
                  },
                },
              ]}
              data={this.state.posts}
              title="List of Users"
              options={{
                columnsButton: true,
                exportButton: true,
                pageSize: 10,
              }}
            />

            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            />
          </Grid>
        </Grid>
        {/* } */}
      </React.Fragment>
    );
  }
}
Admin.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Admin);
