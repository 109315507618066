import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";
import { TEST_STRIPE_KEY, STRIPE_KEY } from "../../helpers";
import axios from "axios";
import { apiUrl } from "../../helpers";
import { handleError } from "../../utils/errorHandling/errorHelper";

let pathname = window.location.pathname;

class PaymentUpdateRoute extends Component {
  constructor(props) {
    super(props);
    //console.log("this is the props from payment route", props);
    this.state = {
      redirect: false,
    };
  }

  componentWillMount() {
    // axios({
    //   method: 'get',
    //   url: `${apiUrl}/rest/stripe/subscription`,
    //   headers: {
    //     authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
    //   }
    // }).then((res) => {
    //  console.log("subscription status", res.data)
    //  let subStatus = res.data.stripeActive
    //  // if active redirect to home, else show page
    //  subStatus === 1 ? this.setState({ redirect: true }) : this.setState({ redirect: false});
    // }).catch(e => {
    //   handleError(e, pathname);
    //   // window.location.replace('/logout');
    // });
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem("token") ? (
            // <StripeProvider apiKey={TEST_STRIPE_KEY}>
            <StripeProvider apiKey={STRIPE_KEY}>
              <Elements>
                <Component {...props} />
              </Elements>
            </StripeProvider>
          ) : (
            <Redirect
              to={{ pathname: "/stt/search", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
}

export default PaymentUpdateRoute;
