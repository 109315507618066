import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'
import history from './components/history/history';
import App from './components/App';
import 'babel-polyfill';
//import { NotFoundProvider } from './components/globalState/404Message';
//import { SnackbarProvider } from 'notistack';

render((

  <Router history={history}>
    <div className="app">
      <App />
    </div>
  </Router>

), document.getElementById('root'));

// remember to comment out
// if (module.hot) {
//   module.hot.accept();
// }