// ===== THIS IS A QUICK FIX UNTIL i'M ABLE TO ADD STATE MANAGEMNT TO ENTIER APP ======

import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Admin from '../Admin';
//import Test from './Test';
import { apiUrl } from '../../helpers';
//import { handleError } from '../../utils/errorHandling/errorHelper';
//import AuthContext from '../../context/Auth/AuthContext';

const getData = async (url) => {
    const response = await axios({
        method: 'get',
        url: url,
        headers: {
            authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
        }
    });
    return response;
}

// may not need this wrapper component
const WrapperComponent = () => {
    
    const [isAdmin, setAdmin] = useState(false);
    const [notAdmin, setNotAdmin] = useState(false);
    useEffect(() => {
        (async () => {

            let response = await getData(`${apiUrl}/rest/history/users/`);
            // verify admin value
            if(response.data[0].admin === 1){
               return setAdmin(true);
               //return isAdmin;
            } else {
                return setNotAdmin(true);
            }
             
            
        })();
    }, []);
    //console.log(isAdmin);
    
    return (  
        <React.Fragment>
            { !isAdmin && <span className="admin-loader"></span>}
            {isAdmin && <Admin />}
            {notAdmin && <Redirect to="/stt/search" />}
        </React.Fragment>
    )
}


const PrivateAdminRoute = ({ component: Component, ...rest}) => {

    // could possibley move all the logic in here  
    return (

        <Route
        {...rest}
        render={props => localStorage.getItem('token') ? <WrapperComponent {...props}/> : <Redirect to="/logout" /> 
           
        }
        />
        // <Route
        //     {...rest}
        //     render={props => 
        //                 //console.log(props)
        //              isAuth ?
        //         (
        //             <WrapperComponent {...props} />
        //         ) :
        //         (
        //             <Redirect to="/stt/search" />
        //         )
        //     }
        // />
    
        
    )


}

export default PrivateAdminRoute;