import React, { Component } from 'react';
import RecentlyViewed from './RecentlyViewed';
import GetDataTable from './GetDataTable';
import GetMBTTable from './GetMBTTable';
import ReactGA from 'react-ga';


class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //querySearchBox: props.location.state.query,
      // querySearchBox1: "",
      // viewTable : false,
      // viewMBTTable: false,
      // hideSearches: true
    };
  };

  componentDidMount() {
    //let searchItem = this.props.match.params.item;
    let searchValue = this.props.match.params.itemvalue;
    document.title = `Search Results ${searchValue.toUpperCase()}, TX - Search Texas Tax`;
    ReactGA.initialize('UA-80211377-3');
    ReactGA.pageview(window.location.pathname);
  }

  render() {

    return (
      <div>
        {this.props.match.params.type === "mbt" ?
          <GetMBTTable className="table-box" id="table-box" /> :
          <GetDataTable />
        }
        <br />
        <RecentlyViewed />
      </div>
    )
  }
}
export default SearchResults;
