import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class HotelInfoRedirect extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    props.history.push(props.location.state);
    this.state = {
      query1: "",
    };
  }

  render() {
    return <div></div>;
  }
}

export default withRouter(HotelInfoRedirect);
