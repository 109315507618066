import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { CardNumberElement, CardExpiryElement, CardCVCElement, PostalCodeElement, injectStripe } from 'react-stripe-elements';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { apiUrl, GM_KEY } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';
import BillingForm from './test/BillingForm';

let pathname = window.location.pathname;

const styles = theme => ({
  GridMargin: {
    marginBottom: '0px',
    marginTop: '0px'
  },
  checkoutButton: {
    color: "#fff",
    backgroundColor: '#d50000',
    "&:hover": {
      backgroundColor: '#ef5350'
    }
  },
  formWrapper: {
    paddingTop: '50px',
    padding: '25px',
    margin: '0 auto',
    maxWidth: '600px'
  },
  confirmWrapper: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    },
  },
  paymentDetailsWrapper: {
    padding: '10px 15px 10px 10px',
    marginTop: '-10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  billingDetailsWrapper: {
    padding: '10px 10px 10px 15px',

  },
  checkWrapper: {
    marginTop: '15px'
  },
  successUpdateWrapper: {
    textAlign: 'center'
  },
  loaderCircle: {
    display: 'block!important',
    marginLeft: 'auto',
    marginRight: 'auto', 
  },
  successDialog: {
    marginLeft: '15%',
    marginTop: '-35%!important',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  }
});

// OM FRIGGEN GGGGGG
const google = window.google = window.google ? window.google : {}

let autocomplete, zipAutocomplete;
// object that has properties sepecfic to google map
let componentForm = {
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name'
};


class UpdateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      name: '',
      country_code: '',

      fullstreet_address: '',
      address_line2: '',
      locality: '',
      administrative_area_level_1: '',
      postal_code: '',
      country: '',

      paymentprocessing: false,
      errormsg: 0,
      cardupdated: false,
      redirect: false,
      redirectTimout: false
    };
    this.handleCardUpdate = this.handleCardUpdate.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.fillInAddress = this.fillInAddress.bind(this);
    this.fillInAddress2 = this.fillInAddress2.bind(this);
    this.initAutocomplete = this.initAutocomplete.bind(this);
  }

  handleCardUpdate = () => {
    this.setState({ cardupdated: true });
    setTimeout(function(){ 
      this.setState({ redirectTimout: true})
    }.bind(this), 2000);
    setTimeout(function(){ 
      this.setState({ redirect: true})
    }.bind(this), 3000);
  }


  fillInAddress() {

  
    // Get the place details from the autocomplete object.
    var place = autocomplete.getPlace();
    //console.log(place);
    for (var component in componentForm) {
      document.getElementById(component).value = '';
      document.getElementById(component).disabled = false;
    }

    let fullstreet_address;

    try {
      if (place !== undefined) {
        // make switch statement to check what search term was used first
        // to determine what the autocomplete feild should have in it
        switch (place !== undefined) {
          case place.address_components[0].types[0] === "locality":
            this.setState({ fullstreet_address: " " })
            break;
          case place.address_components[0].types[0] === "postal_code":
            this.setState({ fullstreet_address: " " })
            break;
          case place.address_components[0].types[0] === "street_number":
            fullstreet_address = place.address_components[0].short_name + " " + place.address_components[1].short_name;
            //console.log( fullstreet_address);
            this.setState({ fullstreet_address })
          default:
        }
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];
  
          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            // console.log(val);
            // console.log(addressType);
            document.getElementById(addressType).value = val;
            this.setState({
              [addressType]: val
            });
          }
        }
      }
    } catch(err){
      handleError(err, pathname);
    }

  }

  fillInAddress2() {

    let place2 = zipAutocomplete.getPlace();

    for (var component in componentForm) {
      document.getElementById(component).value = '';
      document.getElementById(component).disabled = false;
    }

    let fullstreet_address;

    try{
      if (place2 !== undefined) {
        switch (place2 !== undefined) {
          case place2.address_components[0].types[0] === "locality":
            this.setState({ fullstreet_address: " " })
            break;
          case place2.address_components[0].types[0] === "street_number":
            fullstreet_address = place2.address_components[0].short_name + " " + place2.address_components[1].short_name;
            this.setState({ fullstreet_address })
          default:
  
        }
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        for (var i = 0; i < place2.address_components.length; i++) {
          var addressType = place2.address_components[i].types[0];
  
          if (componentForm[addressType]) {
            var val = place2.address_components[i][componentForm[addressType]];
            // console.log(val);
            // console.log(addressType);
            document.getElementById(addressType).value = val;
            this.setState({
              [addressType]: val
            });
          }
        }
      }

    } catch(err){
      handleError(err, pathname)
    }

    
  }

  enableEnterKey(input) {
    //console.log("called")

    /* Store original event listener */
    const _addEventListener = input.addEventListener

    const addEventListenerWrapper = (type, listener) => {
      //console.log("type", type)
      if (type === "keydown") {
        /* Store existing listener function */
        const _listener = listener
        listener = (event) => {
          /* Simulate a 'down arrow' keypress if no address has been selected */
          const suggestion_selected = document.getElementsByClassName('pac-item-selected').length > 0
          if (event.key === 'Enter' && !suggestion_selected) {
            const e = JSON.parse(JSON.stringify(event))
            e.key = 'ArrowDown'
            e.code = 'ArrowDown'
            _listener.apply(input, [e])
          }
          _listener.apply(input, [event])
        }
      }
      if(type ==="place_changed"){
        //console.log("CALLED")
        this.fillInAddress();
      }
      _addEventListener.apply(input, [type, listener])
    }
    input.addEventListener = addEventListenerWrapper
  }

  

  initAutocomplete() {
    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    try {
      autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'));
      zipAutocomplete = new google.maps.places.Autocomplete(document.getElementById('postal_code'));
      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      autocomplete.setFields(['address_component']);
      zipAutocomplete.setFields(['address_component']);
      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.addListener('place_changed', this.fillInAddress);
      //this.enableEnterKey(autocomplete)
      //autocomplete.addListener('keydown', this.enableEnterKey(autocomplete));
      zipAutocomplete.addListener('place_changed', this.fillInAddress2);

    } catch(err){
      handleError(err, pathname)
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  async submitUpdate(ev) {

    if (this.state.name === '' || this.state.fullstreet_address === '' || this.state.locality === '' || this.state.country === '' || this.state.administrative_area_level_1 === ''
    ) {
      this.setState({ errormsg: 1 });
      return;
    }
    else {
      ////console.log("false0");
      this.setState({ errormsg: 0 });
    }
    this.setState({ paymentprocessing: true });
    // User clicked submit
    let { token } = await this.props.stripe.createToken({
      name: this.state.name,
      address_line1: this.state.fullstreet_address + ", " + this.state.address_line2,
      address_city: this.state.locality,
      address_country: this.state.country,
      address_state: this.state.administrative_area_level_1,
      address_zip: this.state.postal_code,
      country_code: this.state.country_code,

    });
    //console.log(token);
    if (token !== undefined) {
      axios({
        method: 'post',
        url: `${apiUrl}/rest/stripe/updatecard`,
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        data: {
          token: token.id,
          name: token.card.name,
          address_city: token.card.address_city,
          address_country: token.card.address_country,
          address_line1: token.card.address_line1,
          address_state: token.card.address_state,
          address_zip: token.card.address_zip,
          country_code: token.card.country
        }
      })
        .then((response) => {
          //console.log(response);
          if(response.data === "card updated"){
            this.handleCardUpdate();
            this.setState({
              complete: true,
              name: '',
              fullstreet_address: '',
              locality: '',
              country: '',
              administrative_area_level_1: '',
              postal_code: '',

            });
          } else {
            handleError("Response for Updating Card did not return with correct 200 response. line 284.", pathname)
          }

        }).catch(e => {
          //console.log(e);
          this.setState({ errormsg: 2 });
          this.setState({ paymentprocessing: false });
          handleError(e, pathname);
          //window.location.replace('/logout');
        });

    }
    else {
      this.setState({ errormsg: 2 });
      this.setState({ paymentprocessing: false });
    }

  };

  render() {
    const { classes } = this.props;
    let errormsg;
    if (this.state.errormsg === 1) {
      errormsg = (
        <p className="errormsg">Please Fill all the details</p>
      )
    }
    else if (this.state.errormsg === 2) {
      errormsg = (
        <p className="errormsg">Bummer!! Something went wrong. Please contact our Support Team for assistance.</p>

      )
    }

    return (
      <div style={{ margin: '0px', padding: '25px', textAlign: 'center', paddingTop: '25px' }}>
        <Paper className={classes.formWrapper}>
        {(this.state.paymentprocessing) ?
          (<Typography className="paymsg" variant="h5" color="textSecondary">
            Card Updating... Please do not refresh the page.
          </Typography>) : ''

        }
        {errormsg}
        <BillingForm
          name={this.state.name}
          fullstreet_address={this.state.fullstreet_address}
          address_line2={this.state.address_line2}
          locality={this.state.locality}
          administrative_area_level_1={this.state.administrative_area_level_1}
          country={this.state.country}
          postal_code={this.state.postal_code}
          handleChange={this.handleChange}
          monthlyPlan={this.state.monthlyPlan}
          disabled={this.state.disabled}
          submitUpdate={this.submitUpdate}
          checkMonthly={this.state.checkMonthly}
          initAutocomplete={this.initAutocomplete}
          handleCheck={this.handleCheck}
          fillInAddress={this.fillInAddress}
          fillInAddress2={this.fillInAddress2}
          // indicator which form we are on
          checkoutForm={false}

        />
      </Paper>
      <Dialog
          open={this.state.cardupdated}
          //onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.successDialog}
        >
          <DialogContent className={classes.successUpdateWrapper}>
            <DialogContentText id="alert-dialog-description">
            <h3 className="success">Card Details updated successfully!</h3>
            <small>Redirecting back to your account...</small>
            {this.state.redirectTimout ? 
               <CircularProgress className={classes.loaderCircle} size={45} />
            : null}
            {this.state.redirect ? <Redirect to="/stt/settings" />  : null}
            
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

    );
  }
}
UpdateCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(injectStripe(UpdateCard));