
import React, { Component } from 'react';
//import {CardElement, injectStripe} from 'react-stripe-elements';
import { injectStripe } from 'react-stripe-elements';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import BillingForm from './test/BillingForm';
import { apiUrl, GM_KEY } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

const styles = theme => ({
  GridMargin: {
    marginBottom: '0px',
    marginTop: '0px'
  },
  checkoutButton: {
    color: "#fff",
    backgroundColor: '#d50000',
    "&:hover": {
      backgroundColor: '#ef5350'
    }
  },
  formWrapper: {
    padding: '25px',
    margin: '0 auto'
  },
  confirmWrapper: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    },
  },
  paymentDetailsWrapper: {
    padding: '10px 15px 10px 10px',
    marginTop: '-10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  billingDetailsWrapper: {
    padding: '10px 10px 10px 15px',

  },
  checkWrapper: {
    marginTop: '15px'
  }
});

// OM FRIGGEN GGGGGG
const google = window.google = window.google ? window.google : {}

let autocomplete, zipAutocomplete;
// object that has properties sepecfic to google map
let componentForm = {
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name'
};



class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      name: '',
      country_code: '',

      fullstreet_address: '',
      address_line2: '',
      locality: '',
      administrative_area_level_1: '',
      postal_code: '',
      country: '',

      paymentprocessing: false,
      updateProcessing: false,
      errormsg: 0,
      clicked: false,
      monthlyPlan: false,
      checkedMonthly: false,
      checkedYearly: false,
      disabled: true,
      termsOpen: false,
      autocomplete: '',
      errorInput: false,
      zipErrorMsg: null,
      //elementType: null

    };
    this.submitPayment = this.submitPayment.bind(this);
    this.fillInAddress = this.fillInAddress.bind(this);
    this.fillInAddress2 = this.fillInAddress2.bind(this);
    this.initAutocomplete = this.initAutocomplete.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }



  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      //errorInput: false
    });
  };




  fillInAddress() {

    try {

      // Get the place details from the autocomplete object.
      var place = autocomplete.getPlace();
      //console.log(place);
      for (var component in componentForm) {
        document.getElementById(component).value = '';
        document.getElementById(component).disabled = false;
      }

      let fullstreet_address;

      if (place !== undefined) {
        // make switch statement to check what search term was used first
        // to determine what the autocomplete feild should have in it
        switch (place !== undefined) {
          case place.address_components[0].types[0] === "locality":
            this.setState({ fullstreet_address: " " })
            break;
          case place.address_components[0].types[0] === "postal_code":
            this.setState({ fullstreet_address: " " })
            break;
          case place.address_components[0].types[0] === "street_number":
            fullstreet_address = place.address_components[0].short_name + " " + place.address_components[1].short_name;
            this.setState({ fullstreet_address })
          default:
        }
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];

          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            document.getElementById(addressType).value = val;
            this.setState({
              [addressType]: val
            });
          }
        }
      }

    } catch (err){
      handleError(err, pathname)
    }


  }

  fillInAddress2() {

    try {

      let place2 = zipAutocomplete.getPlace();

      for (var component in componentForm) {
        document.getElementById(component).value = '';
        document.getElementById(component).disabled = false;
      }
      let fullstreet_address;
  
      if (place2 !== undefined) {
        switch (place2 !== undefined) {
          case place2.address_components[0].types[0] === "locality":
            this.setState({ fullstreet_address: " " })
            break;
          case place2.address_components[0].types[0] === "street_number":
            fullstreet_address = place2.address_components[0].short_name + " " + place2.address_components[1].short_name;
            this.setState({ fullstreet_address })
          default:
  
        }
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        for (var i = 0; i < place2.address_components.length; i++) {
          var addressType = place2.address_components[i].types[0];
  
          if (componentForm[addressType]) {
            var val = place2.address_components[i][componentForm[addressType]];
            // console.log(val);
            // console.log(addressType);
            document.getElementById(addressType).value = val;
            this.setState({
              [addressType]: val
            });
          }
        }
      }

    } catch(err){
      handleError(err, pathname)
    }

   
  }


  initAutocomplete() {
    try {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'));
      zipAutocomplete = new google.maps.places.Autocomplete(document.getElementById('postal_code'));
      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      autocomplete.setFields(['address_component']);
      zipAutocomplete.setFields(['address_component']);
      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.addListener('place_changed', this.fillInAddress);
      zipAutocomplete.addListener('place_changed', this.fillInAddress2);

    } catch(err){
      handleError(err, pathname)
    }
  }

  handleCheck = name => event => {
    this.setState({
      [name]: event.target.checked,
      disabled: !this.state.disabled
    });
  };

  async submitPayment(ev) {

    try {

        if (this.state.name === '' || this.state.fullstreet_address === '' || this.state.locality === '' || this.state.country === '' || this.state.administrative_area_level_1 === ''
      ) {
        this.setState({
          errormsg: 1,
          //errorInput: true
        });

        window.scrollTo(0, 0);
        return;
      }
      else {
        this.setState({ errormsg: 0 });
      }
      this.setState({ paymentprocessing: true });

      let { token } = await this.props.stripe.createToken({
        name: this.state.name,
        address_line1: this.state.fullstreet_address + ", " + this.state.address_line2,
        address_city: this.state.locality,
        address_country: this.state.country,
        address_state: this.state.administrative_area_level_1,
        address_zip: this.state.postal_code,
        country_code: this.state.country_code,

      });
      //console.log(token);
      if (token !== undefined) {
        axios({
          method: 'post',
          url: `${apiUrl}/rest/stripe/stripePayment`,
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            // ===== NEED TO TEST THIS =========
            token: token.id,
            subscriptionPlan: this.props.subscriptionPlan,
            name: token.card.name,
            address_city: token.card.address_city,
            address_country: token.card.address_country,
            address_line1: token.card.address_line1,
            address_state: token.card.address_state,
            address_zip: token.card.address_zip,
            country_code: token.card.country,
          }
        })
          .then((response) => {
            //console.log(response);
            localStorage.setItem('stripeStatus', "true");
            if (response.data.token)
              localStorage.setItem('token', response.data.token);
            this.setState({ complete: true });

          }).catch(e => {
            handleError(e, pathname);
            this.setState({ errormsg: 2 });
            this.setState({ paymentprocessing: false });
          });

      }
      else {
        this.setState({ errormsg: 2 });
        this.setState({ paymentprocessing: false });
      }
    } catch(err){
      handleError(err, pathname)
    }

  };




  componentDidMount() {

    //could run lodaScript Here
    if (this.props.subscriptionPlan === "2") {
      this.setState({ monthlyPlan: true })
    } else {
      this.setState({ monthlyPlan: false })
    }

  }


  render() {
    const { classes } = this.props;
    let errormsg;
    if (this.state.errormsg === 1) {
      errormsg = (
        <p className="errormsg">Please Fill the details before making a payment.</p>
      )
    }
    else if (this.state.errormsg === 2) {
      errormsg = (
        <p className="errormsg">Bummer!! Something went wrong. Please contact our Support Team for assistance.</p>

      )
    }
    let complete;
    if (this.state.complete) {
      complete = (
        <div>
          <h1> Purchase complete</h1>
          <p> Redirecting to Search Page</p>
          <Redirect to='/stt/search' />
        </div>
      )
    }

    return (
      <div style={{ margin: '0px' }}>
        {(this.state.paymentprocessing) ?
            (<Typography className="paymsg" variant="h5" color="textSecondary">
             Payment Processing... Please do not refresh the page.
            </Typography>) : ''
        }
            
              <React.Fragment>
                <span>{complete}</span>
                <span>{errormsg}</span>
               <Paper className={classes.formWrapper}>
                {this.props.subscriptionPlan === "2" ?
                  <Typography variant="h5" color="primary">Monthly Plan Payment </Typography> :
                  <Typography variant="h5" color="primary">Annual Plan Payment </Typography>
                }
                  <br />
                  <BillingForm
                    name={this.state.name}
                    fullstreet_address={this.state.fullstreet_address}
                    address_line2={this.state.address_line2}
                    locality={this.state.locality}
                    administrative_area_level_1={this.state.administrative_area_level_1}
                    country={this.state.country}
                    postal_code={this.state.postal_code}
                    cvcErrorMsg={this.state.cvcErrorMsg}
                    handleChange={this.handleChange}
                    monthlyPlan={this.state.monthlyPlan}
                    disabled={this.state.disabled}
                    submitPayment={this.submitPayment}
                    checkMonthly={this.state.checkMonthly}
                    initAutocomplete={this.initAutocomplete}
                    handleCheck={this.handleCheck}
                    // indicator which form we are on
                    checkoutForm={true}

                />
             </Paper>
            </React.Fragment>
      </div>
    );
  }
}
CheckoutForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(injectStripe(CheckoutForm));