import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import axios from 'axios'
import { apiUrl } from '../helpers';
import { handleError } from '../utils/errorHandling/errorHelper';

let pathname = window.location.pathname;

class PrivateRoute extends React.Component {
  _isMounted = false;
  state = {
    stripeStatus: '',
    free: ''
  }
  componentDidMount() {
    this._isMounted = true;
    axios({
      method: 'get',
      url: `${apiUrl}/rest/history/users`,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
      }
    })
      .then((res) => {

        this.setState({ stripeStatus: res.data[0].stripeActive });
        this.setState({ free: res.data[0].free });

      }).catch((e) => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
   
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>

          (localStorage.getItem('token') ? (localStorage.getItem('stripeStatus') ? (<Component {...props} />) : (<Redirect to={{ pathname: '/paymentplan', state: { from: props.location } }} />))
            : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />))
        }
      />

    );
  }
}
export default PrivateRoute
