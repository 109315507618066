import React from 'react';


export const STT_SVG = ({
    style = {},

    //fill = '#fff',
    width = '100%',
    className = 'surveyLogo',
    height = '100%',
    viewBox = '0 0 491 490.98',
}) =>
    <svg
        width={width}
        style={style}
        height={height}
        viewBox={viewBox}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g>
            <path d="M245.5,490.93c-80.97,0-161.95-0.01-242.92,0.04c-1.92,0-2.59-0.23-2.58-2.43   C0.07,326.52,0.07,164.47,0,2.44c0-2.2,0.67-2.43,2.58-2.43C83.55,0.05,164.53,0.05,245.5,0.05c0,80.64,0,161.27,0.01,241.91   c0,1.18,0.05,2.36,0.09,3.53c-0.03,1.09-0.09,2.18-0.09,3.26C245.5,329.48,245.5,410.21,245.5,490.93z M73.27,322.22   c0.33-0.83,1.14-1.13,1.78-1.61c15.21-11.21,30.46-22.38,45.63-33.64c1.6-1.19,2.6-1.19,4.2,0c15.17,11.26,30.41,22.43,45.63,33.64   c0.64,0.47,1.45,0.79,1.77,1.6c0.02-0.04,0.04-0.09,0.05-0.14c-0.04,0.03-0.08,0.07-0.12,0.1c0.86-1.33-0.1-2.48-0.48-3.6   c-5.86-17.55-11.74-35.09-17.72-52.6c-0.68-1.97-0.32-2.95,1.41-4.16c15.17-10.65,30.25-21.41,45.36-32.15   c0.83-0.59,1.63-1.24,2.99-2.29c-1.34,0-1.94-0.01-2.55,0c-18.9,0.14-37.81,0.25-56.71,0.49c-2.18,0.03-3.12-0.5-3.79-2.67   c-5.54-17.84-11.23-35.64-16.91-53.44c-0.25-0.77-0.21-1.78-1.24-2.41c-0.33,0.99-0.63,1.83-0.89,2.67   c-5.63,17.72-11.31,35.43-16.82,53.18c-0.68,2.19-1.62,2.69-3.8,2.67c-18.9-0.24-37.81-0.35-56.71-0.47   c-0.64-0.01-1.45-0.43-1.97,0.49c0.74,0.55,1.45,1.1,2.18,1.63c15.1,10.73,30.19,21.5,45.35,32.16c1.83,1.29,2.32,2.3,1.55,4.55   c-6.03,17.58-11.9,35.23-17.79,52.86c-0.33,0.98-1.1,1.96-0.32,3.07c-0.04-0.03-0.08-0.07-0.12-0.1   C73.24,322.12,73.26,322.17,73.27,322.22z"
                fill='#1976D2' />
            <path d="M245.6,245.49c-0.03-1.18-0.09-2.36-0.09-3.53c-0.01-80.64-0.01-161.27-0.01-241.91   c81,0,161.99,0.01,242.99-0.05c2.05,0,2.51,0.46,2.5,2.5c-0.06,81-0.05,161.99-0.05,242.99   C409.16,245.49,327.38,245.49,245.6,245.49z"
                fill='#FEFEFE' />
            <path d="M245.6,245.49c81.78,0,163.57,0,245.35,0c0,81.04-0.01,162.08,0.05,243.12c0,1.96-0.4,2.37-2.37,2.37   c-81.05-0.06-162.09-0.05-243.13-0.05c0-80.73,0-161.45,0.01-242.18C245.51,247.67,245.56,246.58,245.6,245.49z"
                fill='#F44336' />
            <path d="M73.34,322.19c-0.78-1.12-0.01-2.1,0.32-3.08c5.89-17.63,11.75-35.28,17.79-52.86   c0.77-2.26,0.28-3.27-1.55-4.55c-15.16-10.66-30.24-21.42-45.35-32.16c-0.73-0.52-1.45-1.07-2.18-1.63   c0.52-0.92,1.34-0.5,1.97-0.49c18.9,0.12,37.81,0.23,56.71,0.47c2.18,0.03,3.11-0.48,3.8-2.67c5.51-17.75,11.19-35.46,16.82-53.18   c0.27-0.85,0.56-1.68,0.89-2.67c1.03,0.63,0.99,1.64,1.24,2.41c5.68,17.8,11.37,35.6,16.91,53.44c0.68,2.17,1.61,2.69,3.79,2.67   c18.9-0.25,37.81-0.35,56.71-0.49c0.61-0.01,1.22,0,2.55,0c-1.36,1.04-2.15,1.69-2.99,2.29c-15.1,10.73-30.19,21.5-45.36,32.15   c-1.72,1.21-2.08,2.19-1.41,4.16c5.98,17.51,11.86,35.05,17.72,52.6c0.38,1.12,1.34,2.27,0.48,3.6l0.07,0.03   c-0.33-0.83-1.14-1.13-1.78-1.61c-15.21-11.21-30.46-22.38-45.63-33.64c-1.6-1.19-2.6-1.19-4.2,0   c-15.16,11.26-30.41,22.43-45.62,33.64c-0.64,0.47-1.45,0.78-1.78,1.61L73.34,322.19z"
                fill='#FEFEFE' />
            <path d="M73.27,322.22c-0.02-0.05-0.03-0.1-0.04-0.14c0.04,0.03,0.08,0.07,0.12,0.1   C73.34,322.19,73.27,322.22,73.27,322.22z"
                fill='#FEFEFE' />
            <path d="M172.22,322.19c0.04-0.03,0.08-0.07,0.12-0.1c-0.02,0.04-0.04,0.09-0.05,0.14   C172.29,322.22,172.22,322.19,172.22,322.19z"
                fill='#FEFEFE' />
        </g>

    </svg>;