import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Button from "@material-ui/core/Button";
import axios from "axios";
import decode from "jwt-decode";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ReCaptcha } from "react-recaptcha-google";
import { RECAPTCHA_SITE_KEY } from "../helpers";
import Footerhome from "./Footerhome";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";
import moment from "moment";

let pathname = window.location.pathname;

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `${theme.spacing(1 / 2)}px ${theme.spacing(1 / 4)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeStatus: false,
      email: "",
      password: "",
      redirect: 0,
      errormsg: false,
      invalidmsg: false,
      loginclick: false,
      recaptchastatus: false,
      returningUser: false,
      recaptchaFail: false,
      recaptchaTriggerd: false,
    };
    //this.returningUserLogin = this.returningUserLogin.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  onLoadRecaptcha() {
    try {
      if (this.captcha) {
        this.captcha.reset();
        this.captcha.execute();
      }
    } catch (err) {
      handleError(err, pathname);
    }
  }

  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
    //console.log(recaptchaToken, "<= your recaptcha token")
    try {
      if (
        recaptchaToken &&
        (recaptchaToken !== undefined || recaptchaToken !== null)
      ) {
        this.setState({ reCaptchaToken: recaptchaToken });
      }
    } catch (err) {
      handleError(err, pathname);
    }
  }

  componentDidMount() {
    document.title = "Sign In - Search Texas Tax";

    //this.verifyReturningUser();

    try {
      // execute invisible reCaptcha
      if (this.captcha) {
        this.captcha.reset();
        this.captcha.execute();
      }
    } catch (err) {
      handleError(err, pathname);
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClick() {
    if (this.state.email === "" || this.state.password === "") {
      this.setState({ errormsg: true });
      this.setState({ invalidmsg: false });
      return;
    }

    axios({
      method: "post",
      url: `${apiUrl}/rest/basic/userlogin`,
      data: {
        email: this.state.email,
        password: this.state.password,
        // "g-recaptcha-response": this.state.reCaptchaToken,
      },
    })
      .then(res => {
        if (res.data === "Invalid email/password") {
          this.setState({ invalidmsg: true });
          this.setState({ errormsg: false });
          return;
        }
        // if post req successful
        if (res.data.result) {
          // set token and user name to session storage
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data.result.fname);

          if (res.data.result[0].free === 1) {
            localStorage.setItem("stripeStatus", true);
            this.setState({ stripeStatus: true });
            // console.log(localStorage.getItem('stripeStatus'));

            // setting the first redirect
            this.setState({ redirect: 1 });
            return;
          } else {
            axios({
              method: "get",
              url: `${apiUrl}/rest/stripe/stripePayment`,
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
              },
            })
              .then(res => {
                if (res.data === "true") {
                  localStorage.setItem("stripeStatus", res.data);
                  this.setState({ stripeStatus: true });
                } else {
                  if (res.data.token) {
                    localStorage.setItem("token", res.data.token);
                  }
                }
                // console.log(localStorage.getItem('stripeStatus'));
                this.setState({ redirect: 1 });
              })
              .catch(e => {
                handleError(e, pathname);
                //window.location.replace('/logout');
              });
            return;
          }
        } else if (res.data === "Fail") {
          // alert user of recaptch fail
          this.setState({ recaptchaFail: true });
          //window.location.reload();
        } else {
          // if post req not successful
          localStorage.removeItem("token");
        }
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
  }

  // What does this
  handleClickRegister() {
    this.setState({ loginclick: true });
  }

  componentDidUpdate(prevState) {
    if (
      this.state.recaptchaFail === true &&
      this.state.recaptchaTriggerd === false
    ) {
      //console.log("recaptcha faild, reset recaptcha");
      if (this.captcha) {
        //console.log("recaptcha from CDM", this.captcha)
        this.captcha.reset();
        this.captcha.execute();
        this.setState({ recaptchaTriggerd: true });
      }
    }
  }

  render() {
    const { loginclick } = this.state;
    if (loginclick) {
      return <Redirect to="/register" />;
    }

    let errormsg;
    if (this.state.errormsg === true) {
      errormsg = (
        <p className="errormsg">
          Please Enter a registered Email ID and password to login.
        </p>
      );
    }

    let invalidmsg;
    if (this.state.invalidmsg === true) {
      invalidmsg = <p className="errormsg">Invalid Email/ Password</p>;
    }

    // checking it outside?? instead of redirecting within the call.
    if (
      this.state.redirect === 1 ||
      this.state.redirect === 2 ||
      this.state.returningUser === true
    ) {
      return <Redirect to="/stt/search/" />;
    }

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <a href="//searchtexastax.com">
              <Typography
                className="headerlogo"
                variant="h6"
                color="inherit"
                noWrap
              >
                <img
                  className="logoimage"
                  src="/stt-logo.svg"
                  alt="stt-logo.svg"
                />
                <span> Search Texas Tax</span>
              </Typography>
            </a>
            <Button
              className="headerbutton"
              color="inherit"
              onClick={() => {
                this.handleClickRegister();
              }}
            >
              Sign Up
            </Button>
          </Toolbar>
        </AppBar>
        <div className="register_text">
          <Typography variant="h5" color="inherit" className={classes.grow}>
            Login and start searching
          </Typography>
        </div>
        <Paper className="login_paper">
          {errormsg}
          {invalidmsg}
          {this.state.recaptchaFail ? (
            <p className="errormsg">
              Hold your horses! You clicked a little too fast. Try logging in
              again.
            </p>
          ) : null}
          <form
            className={classes.container}
            noValidate
            autoComplete="on"
            onSubmit={this.onSubmit}
          >
            <TextField
              id="outlined-name"
              label="Email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange("email")}
              margin="normal"
              variant="outlined"
              validators={["required"]}
              errormessages={["this field is required"]}
            />

            <TextField
              id="standard-password-input"
              label="Password"
              className={classes.textField}
              value={this.state.password}
              onChange={this.handleChange("password")}
              type="password"
              autoComplete="current-password"
              margin="normal"
              variant="outlined"
            />
            <div className="get-data">
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => {
                  this.handleClick();
                }}
              >
                Login
              </Button>
            </div>
            <div className="signuplink">
              <Link to="/passwordrequest">Forgot Password?</Link>
            </div>
          </form>
        </Paper>
        <Paper className="signup_paper">
          <div className="signuplinknew">
            <Link to="/register">New User? Click to Sign Up!</Link>
          </div>
        </Paper>
        <ReCaptcha
          ref={el => {
            this.captcha = el;
          }}
          size="invisible"
          render="explicit"
          sitekey={RECAPTCHA_SITE_KEY}
          onloadCallback={this.onLoadRecaptcha}
          verifyCallback={this.verifyCallback}
        />
        <p className="recap">
          Secure Login with reCAPTCHA subject to Google{" "}
          <a
            href="https://policies.google.com/terms?hl=en"
            rel="noreferrer noopener"
            target="_blank"
          >
            Terms
          </a>{" "}
          &
          <a
            href="https://policies.google.com/privacy?hl=en"
            rel="noreferrer noopener"
            target="_blank"
          >
            {" "}
            Privacy
          </a>
        </p>
        <Footerhome />
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(Login));
