import React from 'react'
import { Redirect, Route } from 'react-router-dom';
import axios from 'axios';
import decode from 'jwt-decode';
import moment from 'moment';
import { apiUrl } from '../../helpers';
import { handleError } from '../../utils/errorHandling/errorHelper';


let pathname = window.location.pathname;

const isTokenExpired = token => {
    try {
      const decoded = decode(token);
      //console.log(decoded);
      if (decoded.exp < moment()) {
        // Checking if token is expired.
        return true;
      } else {
        return false;
      }
    } catch (err) {
      //console.log(err);
      return false;
    }
};



class ReturnUserRoute extends React.Component {

    state = {
        returningUser: false
    }

    verifyReturningUser(){
        let token = localStorage.getItem('token');
          if (token || token !== undefined || token !== " ") {
            try {
              let validateToken = isTokenExpired(token);
              // if token is valid try to login user
              if (validateToken) {
                  //check if user is unsubscribed, DOES NOT WORK ON ADMIN/FREE ACCOUNTS
                  axios({
                    method: 'get',
                    url: `${apiUrl}/rest/history/checkstatus`,
                    headers: {
                      authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
                    }
                  }).then((res) => {
                    const unsubscribed = res.data;
                    //console.log(unsubscribed);
                    if(unsubscribed !== true){
                      this.setState({ returningUser: true})
                    } 
                  });  
                 }
              } catch(err){
                handleError(err, pathname);
              }      
          }
    }

    componentDidMount(){
        this.verifyReturningUser();
    }

  render() {
    const { component: Component, ...rest } = this.props;
    const {returningUser} = this.state;
    return (
      <Route
        {...rest}
        render={props =>
          returningUser === false ? (
                 <Component {...props} />
          ) : (
              <Redirect to={{ pathname: '/stt/search', state: { from: props.location } }} />
            )
        }
      />
    )
  }
}

export default ReturnUserRoute;