/* eslint-disable no-new-object */
import React from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { Chart } from "react-google-charts";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CSVLink } from "react-csv";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";

let pathname = window.location.pathname;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `${theme.spacing(1 / 2)}px ${theme.spacing(1 / 4)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
});

const durations = [
  {
    value: "1",
    label: "1 year",
  },
  {
    value: "3",
    label: "3 years",
  },
  {
    value: "5",
    label: "5 years",
  },
  {
    value: "10",
    label: "10 years",
  },
  {
    value: "100",
    label: "All",
  },
];

class BrandInsights extends React.Component {
  state = {
    posts: [],
    rawposts: [],
    newposts: [],
    brandposts: [],
    token: "",
    dataLoadingStatus: "loading",
    chartData: [],
    value: 0,
    duration: "1",
    labelWidth: 0,
    age: "",
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleChange1 = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    this.setState({
      dataLoadingStatus: "loading",
    });
    axios({
      method: "post",
      url: `${apiUrl}/rest/brands/brand/`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json", //the token is a variable which holds the token
      },
      data: {
        brand: this.props.match.params.brand,
        brandNot: this.props.match.params.brandNot,
      },
    })
      .then(res => {
        //const [metadata, data] = res.data

        const columns = [
          { type: "date", label: "Month" },
          { type: "number", label: "Total Revenue" },
          { type: "number", label: "RevPar" },
        ];
        let rows = [];
        var currentd = new Date();
        var currentyear = currentd.getFullYear();
        for (let row of res.data) {
          let { date, RevPar } = row;
          if (
            row.year >= currentyear - event.target.value &&
            row.year <= currentyear
          ) {
            date = new Date(row.year + "-" + row.month);
            date = new Date(row.year, row.month - 1, 1);
            rows.push([date, row.sum_room_receipts, RevPar]);
          }
        }
        this.setState({
          chartData: [columns, ...rows],
          dataLoadingStatus: "ready",
        });
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e, pathname);
      });
  };
  handleClick(
    taxpayer_number,
    location_number,
    location_name,
    location_city,
    location_county
  ) {
    window.scrollTo(0, 0);
    axios({
      method: "post",
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
      url: `${apiUrl}/rest/history`,
      data: {
        taxpayer_number: taxpayer_number,
        location_number: location_number,
        location_name: location_name,
        location_city: location_city,
        location_county: location_county,
        type: 0,
      },
    }).catch(e => {
      handleError(e, pathname);
    });
  }
  componentDidMount() {
    //this.dateConvert(0);
    if (this.props.match.params.brandNot === 0) {
      this.props.match.params.brandNot = "";
    }
    document.title = "Brand Insights";
    axios({
      method: "post",
      url: `${apiUrl}/rest/brands/testbrand`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json", //the token is a variable which holds the token
      },
      data: {
        brandId: this.props.match.params.id,
        brand: this.props.match.params.brand,
        brandNot: this.props.match.params.brandNot,
      },
    })
      .then(res => {
        //const [metadata, data] = res.data

        const columns = [
          { type: "date", label: "Month" },
          { type: "number", label: "Total Revenue" },
          { type: "number", label: "RevPar" },
        ];
        let rows = [];
        var currentd = new Date();
        var currentyear = currentd.getFullYear();
        for (let row of res.data) {
          let { date, RevPar } = row;
          if (row.year >= currentyear - 1 && row.year <= currentyear) {
            date = new Date(row.year + "-" + row.month);
            date = new Date(row.year, row.month - 1, 1);
            rows.push([date, row.sum_room_receipts, RevPar]);
          }
        }

        this.setState({
          chartData: [columns, ...rows],
          dataLoadingStatus: "ready",
        });
        const posts = res.data;

        this.setState({ posts: posts });
        var i = 0;
        var j = 0;
        var a = 0;

        var newposts = [];
        for (i = 0; i < posts.length; i++) {
          if (a === 0) {
            newposts.push(new Object());
            newposts[newposts.length - 1].year = posts[i].year;
            if (posts[i].month === 1) {
              newposts[newposts.length - 1].january =
                posts[i].sum_room_receipts;
            }
            if (posts[i].month === 2) {
              newposts[newposts.length - 1].february =
                posts[i].sum_room_receipts;
            }
            if (posts[i].month === 3) {
              newposts[newposts.length - 1].march = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 4) {
              newposts[newposts.length - 1].april = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 5) {
              newposts[newposts.length - 1].may = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 6) {
              newposts[newposts.length - 1].june = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 7) {
              newposts[newposts.length - 1].july = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 8) {
              newposts[newposts.length - 1].august = posts[i].sum_room_receipts;
            }
            if (posts[i].month === 9) {
              newposts[newposts.length - 1].september =
                posts[i].sum_room_receipts;
            }
            if (posts[i].month === 10) {
              newposts[newposts.length - 1].october =
                posts[i].sum_room_receipts;
            }
            if (posts[i].month === 11) {
              newposts[newposts.length - 1].november =
                posts[i].sum_room_receipts;
            }
            if (posts[i].month === 12) {
              newposts[newposts.length - 1].december =
                posts[i].sum_room_receipts;
            }
            a = 1;
            continue;
          }

          for (j = 0; j < newposts.length; j++) {
            if (newposts[j].year === posts[i].year) {
              if (posts[i].month === 1) {
                newposts[j].january = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 2) {
                newposts[j].february = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 3) {
                newposts[j].march = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 4) {
                newposts[j].april = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 5) {
                newposts[j].may = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 6) {
                newposts[j].june = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 7) {
                newposts[j].july = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 8) {
                newposts[j].august = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 9) {
                newposts[j].september = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 10) {
                newposts[j].october = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 11) {
                newposts[j].november = posts[i].sum_room_receipts;
              }
              if (posts[i].month === 12) {
                newposts[j].december = posts[i].sum_room_receipts;
                a = 0;
              }
              break;
            }
          }
        }

        this.setState({ newposts: newposts });

        //Brand Insight Individual hotels

        axios({
          method: "post",
          url: `${apiUrl}/rest/brands/brandhotels`,
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json", //the token is a variable which holds the token
          },
          data: { brandId: this.props.match.params.id },
        })
          .then(res => {
            const brandposts = res.data;
            this.setState({ brandposts: brandposts });
          })
          .catch(e => {
            handleError(e, pathname);
            //window.location.replace('/login');
          });
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }

  dateConvert(date) {
    // let newDate;
    return (date = moment(date).format("l"));
  }

  render() {
    const { classes } = this.props;
    let chartdisplay;
    if (this.state.dataLoadingStatus === "ready") {
      chartdisplay = (
        <div>
          <TextField
            id="outlined-select-currency"
            select
            className={classes.textField}
            value={this.state.duration}
            onChange={this.handleChange1("duration")}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
            {durations.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          ></form>
          <Chart
            chartType="Line"
            loader={<div>Loading Chart</div>}
            width={"100%"}
            height={"400px"}
            data={this.state.chartData}
            options={{
              chartArea: { height: "100%", width: "90%" },
              hAxis: {
                format: "yyyy",
              },
              vAxis: {
                format: "short",
              },
              title: "Debt incurred over time.",
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: "sum_room_receipts" },
                1: { axis: "Number_Of_Rooms_Available" },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  sum_room_receipts: { label: "Monthly Revenue" },
                  all: {
                    format: {
                      pattern: "currency",
                    },
                  },
                },
                x: {
                  all: {
                    format: {
                      pattern: "MMM dd, yyyy",
                    },
                  },
                },
              },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      );
    } else {
      chartdisplay = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }

    const { value } = this.state;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="data_title">
            <div className="row">
              <div className="col">
                <h1>{this.props.match.params.name}</h1>
              </div>
              <div className="col">
                <Typography className={classes.subtext} color="textSecondary">
                  Hotel Occupancy Tax Receipts
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className="graph-tabs">
            <AppBar position="static">
              <Tabs
                className="parenttab"
                value={value}
                onChange={this.handleChange}
                scrollable
                scrollButtons="auto"
              >
                <Tab
                  className="tablabel"
                  label="Monthly Revenue and RevPAR Comparison"
                />
              </Tabs>
            </AppBar>
            {value === 0 && <TabContainer>{chartdisplay}</TabContainer>}
          </Paper>
        </Grid>
        <Grid item xs={12} className="tablehead">
          <MaterialTable
            columns={[
              {
                title: "Year",
                field: "year",
                type: "numeric",
                defaultSort: "desc",
              },
              {
                title: "January",
                field: "january",
                type: "html",
                render: rowData => {
                  if (rowData.january === undefined) {
                    rowData.january = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.january
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "February",
                field: "february",
                type: "html",
                render: rowData => {
                  if (rowData.february === undefined) {
                    rowData.february = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.february
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "March",
                field: "march",
                type: "html",
                render: rowData => {
                  if (rowData.march === undefined) {
                    rowData.march = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.march
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "April",
                field: "april",
                type: "html",
                render: rowData => {
                  if (rowData.april === undefined) {
                    rowData.april = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.april
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "May",
                field: "may",
                type: "html",
                render: rowData => {
                  if (rowData.may === undefined) {
                    rowData.may = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.may
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "June",
                field: "june",
                type: "html",
                render: rowData => {
                  if (rowData.june === undefined) {
                    rowData.june = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.june
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "July",
                field: "july",
                type: "html",
                render: rowData => {
                  if (rowData.july === undefined) {
                    rowData.july = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.july
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "August",
                field: "august",
                type: "html",
                render: rowData => {
                  if (rowData.august === undefined) {
                    rowData.august = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.august
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "September",
                field: "september",
                type: "html",
                render: rowData => {
                  if (rowData.september === undefined) {
                    rowData.september = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.september
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "October",
                field: "october",
                type: "html",
                render: rowData => {
                  if (rowData.october === undefined) {
                    rowData.october = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.october
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "November",
                field: "november",
                type: "html",
                render: rowData => {
                  if (rowData.november === undefined) {
                    rowData.november = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.november
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
              {
                title: "December",
                field: "december",
                type: "html",
                render: rowData => {
                  if (rowData.december === undefined) {
                    rowData.december = 0;
                  }
                  return (
                    <div>
                      $&nbsp;
                      {rowData.december
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
            ]}
            data={this.state.newposts}
            title={`Monthly Performance: ${this.props.match.params.name}`}
            options={{
              columnsButton: true,
              exportButton: true,
            }}
          />
        </Grid>
        <Grid item xs={12} className="tablehead1">
          <CSVLink
            data={this.state.posts}
            className="csvdownload"
            filename="BrandCompleteData.csv"
          >
            <Button className="csvbutton" title="Export As CSV">
              <Icon className="csvicon">save_alt</Icon>
            </Button>
          </CSVLink>
          <MaterialTable
            columns={[
              {
                title: "Year",
                field: "year",
                type: "numeric",
                defaultSort: "desc",
              },
              { title: "Month", field: "month" },
              {
                title: "Total Number of Rooms",
                field: "sum_room_capacity",
                type: "numeric",
              },
              {
                title: "Total Revenue",
                field: "sum_room_receipts",
                type: "html",
                render: rowData => {
                  return (
                    <div>
                      ${" "}
                      {rowData.sum_room_receipts
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      .00
                    </div>
                  );
                },
              },
            ]}
            data={this.state.posts}
            title={`Complete Data Set: ${this.props.match.params.name}`}
            options={{
              columnsButton: true,
            }}
          />
        </Grid>

        <Grid item xs={12} className="tablehead1">
          <CSVLink
            data={this.state.brandposts}
            className="csvdownload"
            filename="BrandInsightData.csv"
          >
            <Button className="csvbutton" title="Export As CSV">
              <Icon className="csvicon">save_alt</Icon>
            </Button>
          </CSVLink>
          <MaterialTable
            columns={[
              {
                title: "Hotel Name",
                field: "location_name",
                render: rowData => {
                  return (
                    <Link
                      to={`/stt/hotelinfo/${rowData.location_name
                        .replace("/", "(slash)")
                        .replace("#", "(hash)")}/${rowData.taxpayer_number}/${
                        rowData.location_number
                      }`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {rowData.location_name}
                    </Link>
                  );
                },
                type: "html",
              },
              { title: "Address", field: "location_address" },
              {
                title: "City",
                render: rowData => {
                  return (
                    <Link
                      to={`/stt/city/${rowData.location_city}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {rowData.location_city}
                    </Link>
                  );
                },
              },
              { title: "State", field: "location_state" },
              { title: "Zip Code", field: "location_zip" },
              {
                title: "County",
                render: rowData => {
                  return (
                    <Link
                      to={`/stt/county/${rowData.verified_county}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {rowData.verified_county}
                    </Link>
                  );
                },
              },
              {
                title: "Start Date",
                field: "responsibility_begin_date",
                type: "html",
                render: rowData => {
                  if (rowData.responsibility_begin_date === 0) {
                    return <div> </div>;
                  } else {
                    return <div>{rowData.responsibility_begin_date}</div>;
                  }
                },
              },
              {
                title: "End Date",
                field: "responsibility_end_date",
                type: "html",
                render: rowData => {
                  if (rowData.responsibility_end_date === 0) {
                    return <div> </div>;
                  } else {
                    return <div>{rowData.responsibility_end_date}</div>;
                  }
                },
              },
            ]}
            data={this.state.brandposts}
            title={`All ${this.props.match.params.name} Properties`}
            options={{
              columnsButton: true,
            }}
          />
        </Grid>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </Grid>
    );
  }
}
BrandInsights.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(BrandInsights);
