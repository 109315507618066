import axios from "axios";
import { apiUrl } from "../../helpers";

export const handleError = (error, url) => {
  //console.log(error.stack.toString());
  //console.log('raw error ' + error.message);
  let errorUrl;
  let errorMessage;
  // If error came from axios call
  if (error.config) {
    let eConfigUrl = error.config.url.split(`${apiUrl}/rest`);
    let eConfigMethod = error.config.method.toUpperCase();
    errorMessage = "Api call failed at " + eConfigMethod + " " + eConfigUrl[1];
    errorUrl = "Site URL: " + url;

    //errorMessage = " failed at " + eConfigUrl[1];
  } else {
    // pass cauth error with url
    errorUrl = url;
    errorMessage =
      error.message ||
      "" + " " + error.stack.toString() ||
      error.toString() ||
      "";
  }
  console.log(errorUrl, errorMessage);
  axios({
    method: "post",
    headers: { authorization: "Bearer " + localStorage.getItem("token") },
    url: `${apiUrl}/rest/basic/errorlog`,
    data: { error: errorMessage, url: errorUrl },
  })
    .then(res => {
      //console.log("error response", res);
    })
    .catch(e => {
      //console.log(e);
    });
};
