// This file is shared across the demos.

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import Layers from '@material-ui/icons/Layers';
import Public from '@material-ui/icons/Public';
import { Link } from 'react-router-dom';


export const mailFolderListItemsTrial = (
  <div>
  <Link to='/trial/hotel'>
    <ListItem button>
      <ListItemIcon>
        <SearchIcon />
      </ListItemIcon>
      <ListItemText primary="Sample Hotel" />
    </ListItem>
    </Link>
     <Link to='/trial/city'>
    <ListItem button>
      <ListItemIcon>
        <Layers />
      </ListItemIcon>
      <ListItemText primary="Sample City" />
    </ListItem>
    </Link>
    <Link to='/trial/county'>
    <ListItem button>
      <ListItemIcon>
        <Public />
      </ListItemIcon>
      <ListItemText primary="Sample County" />
    </ListItem>
    </Link>

  </div>
);

