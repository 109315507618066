import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import { Redirect } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Footer from "../components/Footer";
import TrialRoute from "../components/TrialRoute";
import { mailFolderListItemsTrial } from "./TrialTileData";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100%",
    minHeight: "100vh",
    height: "auto",
  },
});

class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false,
    fname: "",
  };
  componentDidMount() {}
  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };
  handleClicklogin() {
    this.setState({ loginclick: true });
  }
  handleClickregister() {
    this.setState({ registerclick: true });
  }

  render() {
    const { classes, theme } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <List>{mailFolderListItemsTrial}</List>
        </List>
        <Divider />
      </div>
    );
    const { loginclick } = this.state;
    if (loginclick) {
      return <Redirect to="/login" />;
    }
    const { registerclick } = this.state;
    if (registerclick) {
      return <Redirect to="/register" />;
    }
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/stt/search">
              <Typography
                className="headerlogo"
                variant="h6"
                color="inherit"
                noWrap
              >
                <img
                  className="logoimage"
                  src="/stt-logo.svg"
                  alt="/stt-logo.svg"
                />
                <span>Search Texas Tax</span>
              </Typography>
            </Link>
            <div className="welcome">
              <Button
                color="inherit"
                onClick={() => {
                  this.handleClickregister();
                }}
              >
                Sign Up
              </Button>
              <Button
                color="inherit"
                onClick={() => {
                  this.handleClicklogin();
                }}
              >
                Login
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className="sttnew">
          <div className={classes.toolbar} />
          <TrialRoute />

          <Footer />
        </main>
      </div>
    );
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
