import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Footerhome from "./Footerhome";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `${theme.spacing(1 / 2)}px ${theme.spacing(1 / 4)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  headerText: {
    textAlign: "center",
    marginTop: "32px",
    marginBottom: "38px",
  },
  cardWrapper: {
    maxWidth: "300px!important",
    //border: '1px solid red'
  },
  card: {
    padding: "12px",
    maxWidth: "240px!important",
    minHeight: "275px!important",
    marginLeft: "10%",
    marginRight: "0px!important",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "25px",
    },
  },
  priceBody: {
    minHeight: "160px",
  },
});

class Login extends React.Component {
  state = {
    subscriptionPlan: "",
    redirect: false,
    logoutclick: false,
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    ////console.log(event.target.value);
  };

  // handleClick() {
  //   if (this.state.email === '' || this.state.password === '') {
  //     this.setState({ errormsg: true })
  //     this.setState({ invalidmsg: false })
  //     return;
  //   }

  // }

  handleClicklogout() {
    this.setState({ logoutclick: true });
  }

  render() {
    const { logoutclick } = this.state;
    if (logoutclick) {
      ////console.log(logoutclick);
      return <Redirect to="/logout" />;
    }

    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/stt/search" />;
    }

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Link to="/paymentplan">
              <Typography
                className="headerlogo"
                variant="h6"
                color="inherit"
                noWrap
              >
                <img className="logoimage" src="/stt-logo.svg" alt="img.svg" />
                <span> Search Texas Tax</span>
              </Typography>
            </Link>

            <Button
              className="headerbutton"
              color="inherit"
              onClick={() => {
                this.handleClicklogout();
              }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <div className="payment-plan">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h5"
                color="inherit"
                className={classes.headerText}
              >
                Select a Payment Plan
              </Typography>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6} className={classes.cardWrapper}>
                  <Card className={classes.card}>
                    <CardContent className={classes.priceBody}>
                      <Typography
                        className={classes.title}
                        variant="h5"
                        gutterBottom
                      >
                        Monthly
                      </Typography>
                      <Typography
                        className={classes.title}
                        variant="h2"
                        gutterBottom
                      >
                        $15.00
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        Per Month, Billed Monthly
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <div className="get-data get-dataplan">
                        <Link to="/paymentform/2">
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className="paymentbutton"
                          >
                            Sign Up
                          </Button>
                        </Link>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.cardWrapper}>
                  <Card className={classes.card}>
                    <CardContent className={classes.priceBody}>
                      <Typography
                        className={classes.title}
                        variant="h5"
                        gutterBottom
                      >
                        Yearly
                      </Typography>
                      <Typography
                        className={classes.title}
                        variant="h2"
                        gutterBottom
                      >
                        $12.50
                      </Typography>
                      <Typography className={classes.pos} color="textSecondary">
                        Per Month, <strong>$150 </strong>Billed Once Yearly
                      </Typography>
                    </CardContent>
                    <CardActions className="second-link">
                      <div className="get-data get-dataplan">
                        <Link to="/paymentform/3">
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className="paymentbutton"
                          >
                            Sign Up
                          </Button>
                        </Link>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Footerhome />
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Login);
