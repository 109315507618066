import ReactGA from "react-ga";
import React from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";

let pathname = window.location.pathname;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fab: {
    margin: theme.spacing(2),
  },

  absolute: {
    backgroundColor: "#d50000",
    "&:hover": {
      backgroundColor: "#f44336",
    },
  },
});
// const portfoliotypes = [
//   {
//     value: "0",
//     label: "Hotel"
//   },
//   {
//       value: "1",
//       label: "MBT"
//     }

// ];
class Brands extends React.Component {
  state = {
    posts: [],
    token: "",
    dataLoadingStatus: "loading",
    chartData: [],
    value: 0,
    type: "0",
    portfolio: "",
    errormsg: false,
    id: "",
    portfoliotypes: 0,
    open: false,
    pid: "",
    title: "Create New Portfolio",
  };
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClick() {
    if (this.state.portfolio === "") {
      this.setState({ errormsg: true });
      return;
    }
    axios({
      method: "post",
      url: `${apiUrl}/rest/portfolio`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
      data: { portfolio: this.state.portfolio, type: this.state.type },
    })
      .then(res => {
        if (res.data) {
          this.setState({ anchorEl: null });
          axios({
            method: "get",
            url: `${apiUrl}/rest/portfolio`,
            headers: {
              authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
            },
          })
            .then(res => {
              const posts = res.data;
              this.setState({ posts: posts });
            })
            .catch(e => {
              handleError(e, pathname);
              //window.location.replace('/login');
            });
          this.setState({ portfolio: null });
          this.setState({ type: 0 });
          this.setState({ title: "Portfolio Created" });
          setTimeout(
            function() {
              this.setState({ title: "Create New Portfolio" });
            }.bind(this),
            5000
          );
        }
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }
  handleClickOpen = id => {
    this.setState({ open: true });
  };
  handleClickDelete(id) {
    axios({
      method: "delete",
      url: `${apiUrl}/rest/portfolio`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
      data: { id: id },
    })
      .then(res => {
        this.setState({ open: false });
        axios({
          method: "get",
          url: `${apiUrl}/rest/portfolio`,
          headers: {
            authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
          },
        })
          .then(res => {
            const posts = res.data;
            this.setState({ posts: posts });
          })
          .catch(e => {
            handleError(e, pathname);
            // window.location.replace('/login');
          });
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    // this.setState({
    //   anchorEl: null,
    //   errormsg: false,
    //   portfolio: '',
    //   type: '0',
    //   open: false
    //  });
    this.setState({ errormsg: false });
    this.setState({ portfolio: "" });
    this.setState({ type: "0" });
    this.setState({ open: false });
  };
  componentDidMount() {
    document.title = "Brands (Beta)- Search Texas Tax";
    axios({
      method: "get",
      url: `${apiUrl}/rest/brands`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
    })
      .then(res => {
        const posts = res.data;
        this.setState({ posts: posts });
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e, pathname);
        //window.location.replace('/login');
      });
  }

  render() {
    const { classes } = this.props;
    //const { anchorEl } = this.state;
    //  const open = Boolean(anchorEl);
    //   const { value } = this.state;

    let errormsg;
    if (this.state.errormsg === true) {
      errormsg = (
        <p className="errormsg">Please Enter a portfolio name to create one.</p>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="data_title">
            <div className="row">
              <div className="col">
                <h1>Brands</h1>
              </div>
              <div className="col">
                <Typography className={classes.subtext} color="textSecondary">
                  Please select a brand or parent company below to see details.
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <CSVLink
            data={this.state.posts}
            className="csvdownload"
            filename="ListOfBrands.csv"
          >
            <Button className="csvbutton" title="Export As CSV">
              <Icon className="csvicon">save_alt</Icon>
            </Button>
          </CSVLink>
          <MaterialTable
            columns={[
              {
                title: "Brand Name",
                field: "name",
                render: rowData => {
                  if (rowData.brandNot === "") {
                    return (
                      <Link
                        to={`/stt/brand/${rowData.id}/${rowData.brand}/0/${rowData.name}`}
                      >
                        {rowData.name}
                      </Link>
                    );
                  } else {
                    return (
                      <Link
                        to={`/stt/brand/${rowData.id}/${rowData.brand}/${rowData.brandNot}/${rowData.name}`}
                      >
                        {rowData.name}
                      </Link>
                    );
                  }
                },
              },
              {
                title: "Parent Company",
                field: "parent",
                render: rowData => {
                  return (
                    <Link
                      to={`/stt/brand/${rowData.parentId}/${rowData.parent}`}
                    >
                      {rowData.parent}
                    </Link>
                  );
                },
              },
              {
                title: "# of Locations",
                field: "locations",
              },
            ]}
            data={this.state.posts}
            title="List of Brands"
            options={{
              pageSize: 10,
            }}
          />
        </Grid>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </Grid>
    );
  }
}
Brands.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Brands);
