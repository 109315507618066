import React, { Component } from 'react';
import InfoWindow from './InfoWindow';
import './Marker.css';

const checkDiv = (content, mouseOver, mouseOut) => {
        return <InfoWindow 
                mouseOver={mouseOver} 
                mouseOut={mouseOut}>{content}
        </InfoWindow>
}

const GoogleMapMarker = (props) => {
        const { color, name } = props;
        return (
                <div className="marker"
                        style={{ backgrounColor: color, cursor: 'pointer' }}
                        title={name}
                        onMouseOver={props.mouseOver}
                        onMouseOut={props.mouseOut}
                >
                        {checkDiv(props.children, props.mouseOver, props.mouseOut)}
                </div>
        )
}

export default GoogleMapMarker;