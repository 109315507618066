/* eslint-disable no-new-object */
import React from "react";
import axios from "axios";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import { Chart } from "react-google-charts";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import ReactGA from "react-ga";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";

let pathname = window.location.pathname;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  Red: {
    color: "red",
    top: "5px",
    position: "relative",
    fontSize: "21px!important",
    fontWeight: "1000!important",
  },
  Green: {
    color: "green",
    top: "5px",
    position: "relative",
    fontSize: "21px!important",
    fontWeight: "1000!important",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `${theme.spacing(1 / 2)}px ${theme.spacing(1 / 4)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
  newOwner: {
    fontSize: "18px!important",
  },
  propertyChange: {
    width: "220px",
    fontSize: ".8rem",
    textAlign: "left",
    padding: "12px",
    margin: "0px",
    color: "#fff",
  },
  popoverCell: {
    minWidth: "100px!important",
    maxWidth: "210px!important",
  },
});

const durations = [
  {
    value: "2016",
    label: "1 year",
  },
  {
    value: "2015",
    label: "3 years",
  },
  {
    value: "2013",
    label: "5 years",
  },
  {
    value: "2008",
    label: "10 years",
  },
  {
    value: "2000",
    label: "All",
  },
];

class MBTCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      rawposts: [],
      newposts: [],
      yearposts: [],
      token: "",
      dataLoadingStatus: "loading",
      chartData: [],
      value: 0,
      duration: "2016",
      labelWidth: 0,
      age: "",
      anchorEl: null,
      openedPopoverId: "",
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleChange1 = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    ////////console.log(event.target.value);
    axios
      .get(`${apiUrl}/rest/mbt/city/${this.props.match.params.city}`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
        },
      })
      .then(res => {
        try {
          const columns = [
            { type: "date", label: "Month" },
            { type: "number", label: "Total Revenue" },
          ];
          let rows = [];
          var d = new Date();
          var n = d.getFullYear();
          for (let row of res.data) {
            let { date } = row;
            if (row.year > event.target.value && row.year <= n) {
              date = new Date(row.year + "-" + row.month);
              //////console.log(date);
              date = new Date(row.year, row.month - 1, 1);
              rows.push([date, row.Total_Receipts]);
              //////console.log(rows);
            }
          }
          this.setState({
            chartData: [columns, ...rows],
            dataLoadingStatus: "ready",
          });
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
  };
  componentDidMount() {
    document.title = this.props.match.params.city + ",TX - Search Texas Tax";
    ReactGA.initialize("UA-80211377-3");
    ReactGA.pageview(window.location.pathname);

    axios
      .get(`${apiUrl}/rest/mbt/city/${this.props.match.params.city}`, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
        },
      })
      .then(res => {
        const columns = [
          { type: "date", label: "Month" },
          { type: "number", label: "Total Revenue" },
        ];
        let rows = [];
        var d = new Date();
        var n = d.getFullYear();

        try {
          for (let row of res.data) {
            let { date } = row;
            if (row.year > 2016 && row.year <= n) {
              date = new Date(row.year + "-" + row.month);
              //////console.log(date);
              date = new Date(row.year, row.month - 1, 1);
              rows.push([date, row.Total_Receipts]);
            }
          }
          this.setState({
            chartData: [columns, ...rows],
            dataLoadingStatus: "ready",
          });
        } catch (err) {
          handleError(err, pathname);
        }

        const rawposts = res.data;
        this.setState({ rawposts: rawposts });

        const posts = res.data;
        this.setState({ posts: posts });

        var i = 0;
        var j = 0;
        var a = 0;

        var newposts = [];
        try {
          for (i = 0; i < posts.length; i++) {
            if (a === 0) {
              newposts.push(new Object());
              newposts[newposts.length - 1].year = posts[i].year;
              if (posts[i].month === 1) {
                newposts[newposts.length - 1].january = posts[i].Total_Receipts;
                //////////console.log(temp['January']);
              }
              if (posts[i].month === 2) {
                newposts[newposts.length - 1].february =
                  posts[i].Total_Receipts;
              }
              if (posts[i].month === 3) {
                newposts[newposts.length - 1].march = posts[i].Total_Receipts;
              }
              if (posts[i].month === 4) {
                newposts[newposts.length - 1].april = posts[i].Total_Receipts;
              }
              if (posts[i].month === 5) {
                newposts[newposts.length - 1].may = posts[i].Total_Receipts;
              }
              if (posts[i].month === 6) {
                newposts[newposts.length - 1].june = posts[i].Total_Receipts;
              }
              if (posts[i].month === 7) {
                newposts[newposts.length - 1].july = posts[i].Total_Receipts;
              }
              if (posts[i].month === 8) {
                newposts[newposts.length - 1].august = posts[i].Total_Receipts;
              }
              if (posts[i].month === 9) {
                newposts[newposts.length - 1].september =
                  posts[i].Total_Receipts;
              }
              if (posts[i].month === 10) {
                newposts[newposts.length - 1].october = posts[i].Total_Receipts;
              }
              if (posts[i].month === 11) {
                newposts[newposts.length - 1].november =
                  posts[i].Total_Receipts;
              }
              if (posts[i].month === 12) {
                newposts[newposts.length - 1].december =
                  posts[i].Total_Receipts;
              }
              // ////////console.log("Inside a");
              //newposts.push(temp);
              a = 1;
              continue;
            }

            for (j = 0; j < newposts.length; j++) {
              if (newposts[j].year === posts[i].year) {
                if (posts[i].month === 1) {
                  newposts[j].january = posts[i].Total_Receipts;
                  //////////console.log(temp['January']);
                }
                if (posts[i].month === 2) {
                  newposts[j].february = posts[i].Total_Receipts;
                  //////////console.log(newposts[j]);
                }
                if (posts[i].month === 3) {
                  newposts[j].march = posts[i].Total_Receipts;
                }
                if (posts[i].month === 4) {
                  newposts[j].april = posts[i].Total_Receipts;
                }
                if (posts[i].month === 5) {
                  newposts[j].may = posts[i].Total_Receipts;
                }
                if (posts[i].month === 6) {
                  newposts[j].june = posts[i].Total_Receipts;
                }
                if (posts[i].month === 7) {
                  newposts[j].july = posts[i].Total_Receipts;
                }
                if (posts[i].month === 8) {
                  newposts[j].august = posts[i].Total_Receipts;
                }
                if (posts[i].month === 9) {
                  newposts[j].september = posts[i].Total_Receipts;
                }
                if (posts[i].month === 10) {
                  newposts[j].october = posts[i].Total_Receipts;
                }
                if (posts[i].month === 11) {
                  newposts[j].november = posts[i].Total_Receipts;
                }
                if (posts[i].month === 12) {
                  newposts[j].december = posts[i].Total_Receipts;
                  a = 0;
                }
                break;
              }
            }
          }
        } catch (err) {
          handleError(err, pathname);
        }

        var yearposts = [];

        try {
          for (var k = 0; k < newposts.length; k++) {
            yearposts.push(new Object());

            yearposts[k].year = newposts[k].year;
            yearposts[k].total_revenue =
              newposts[k].january +
              newposts[k].february +
              newposts[k].march +
              newposts[k].april +
              newposts[k].may +
              newposts[k].june +
              newposts[k].july +
              newposts[k].august +
              newposts[k].september +
              newposts[k].october +
              newposts[k].november +
              newposts[k].december;
          }

          for (var l = 0; l < yearposts.length; l++) {
            if (l === 0) {
              yearposts[l].totalRevChange = "0";
              //  yearposts[l].RevPARChange = '0';
            } else {
              yearposts[l].totalRevChange =
                "$ " +
                (yearposts[l].total_revenue - yearposts[l - 1].total_revenue)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " (" +
                (
                  (yearposts[l].total_revenue /
                    yearposts[l - 1].total_revenue) *
                    100 -
                  100
                ).toFixed(2) +
                "%)";
              // yearposts[l].RevPARChange = '$ '+(yearposts[l].RevPar-yearposts[l-1].RevPar).toFixed(2)+ ' (' + ((((yearposts[l].RevPar/yearposts[l-1].RevPar)*100)-100).toFixed(2))+"%)";
            }
          }
          this.setState({
            yearposts,
            newposts,
          });
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .catch(e => {
        handleError(e, pathname);
        window.location.replace("/logout");
      });
  }

  render() {
    const { classes } = this.props;
    let chartdisplay;
    if (this.state.dataLoadingStatus === "ready") {
      chartdisplay = (
        <div>
          <TextField
            id="outlined-select-currency"
            select
            className={classes.textField}
            value={this.state.duration}
            onChange={this.handleChange1("duration")}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
            {durations.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          ></form>
          <Chart
            chartType="Line"
            loader={<div>Loading Chart</div>}
            width={"100%"}
            height={"400px"}
            data={this.state.chartData}
            options={{
              chartArea: { height: "100%", width: "90%" },
              hAxis: {
                format: "yyyy",
              },
              vAxis: {
                format: "short",
              },
              title: "Debt incurred over time.",
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: "Total_Receipts" },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Receipts: { label: "Monthly Revenue" },
                  all: {
                    format: {
                      pattern: "currency",
                    },
                  },
                },
                x: {
                  all: {
                    format: {
                      pattern: "MMM dd, yyyy",
                    },
                  },
                },
              },
              legend: { position: "none" },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      );
    } else {
      chartdisplay = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }

    const { value } = this.state;

    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Paper className="data_title">
              <div className="row">
                <div className="col">
                  <h1>{this.props.match.params.city}</h1>
                </div>
                <div className="col">
                  <Typography className={classes.subtext} color="textSecondary">
                    Hotel Occupancy Tax Receipts
                  </Typography>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <AppBar position="static">
                <Tabs
                  className="parenttab"
                  value={value}
                  onChange={this.handleChange}
                  scrollable
                  scrollButtons="auto"
                >
                  <Tab className="tablabel" label="Monthly Revenue" />
                </Tabs>
              </AppBar>
              {value === 0 && <TabContainer>{chartdisplay}</TabContainer>}
            </Paper>
          </Grid>

          <Grid item xs={12} className="tablehead1">
            <CSVLink
              data={this.state.newposts}
              className="csvdownload"
              filename="MBTCityMonthlyRevenue.csv"
            >
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              columns={[
                {
                  title: "Year",
                  field: "year",
                  type: "numeric",
                  defaultSort: "desc",
                },
                {
                  title: "January",
                  field: "january",
                  type: "html",
                  render: rowData => {
                    if (rowData.january === undefined) {
                      rowData.january = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.january
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "February",
                  field: "february",
                  type: "html",
                  render: rowData => {
                    if (rowData.february === undefined) {
                      rowData.february = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.february
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "March",
                  field: "march",
                  type: "html",
                  render: rowData => {
                    if (rowData.march === undefined) {
                      rowData.march = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.march
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "April",
                  field: "april",
                  type: "html",
                  render: rowData => {
                    if (rowData.april === undefined) {
                      rowData.april = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.april
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "May",
                  field: "may",
                  type: "html",
                  render: rowData => {
                    if (rowData.may === undefined) {
                      rowData.may = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.may
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "June",
                  field: "june",
                  type: "html",
                  render: rowData => {
                    if (rowData.june === undefined) {
                      rowData.june = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.june
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "July",
                  field: "july",
                  type: "html",
                  render: rowData => {
                    if (rowData.july === undefined) {
                      rowData.july = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.july
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "August",
                  field: "august",
                  type: "html",
                  render: rowData => {
                    if (rowData.august === undefined) {
                      rowData.august = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.august
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "September",
                  field: "september",
                  type: "html",
                  render: rowData => {
                    if (rowData.september === undefined) {
                      rowData.september = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.september
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "October",
                  field: "october",
                  type: "html",
                  render: rowData => {
                    if (rowData.october === undefined) {
                      rowData.october = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.october
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "November",
                  field: "november",
                  type: "html",
                  render: rowData => {
                    if (rowData.november === undefined) {
                      rowData.november = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.november
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "December",
                  field: "december",
                  type: "html",
                  render: rowData => {
                    if (rowData.december === undefined) {
                      rowData.december = 0;
                    }
                    return (
                      <div>
                        ${" "}
                        {rowData.december
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
              ]}
              data={this.state.newposts}
              title={`Monthly Performance: ${this.props.match.params.city}`}
              options={{
                pageSizeOptions: [10, 20, 50, 100],
                columnsButton: true,
                exportButton: true,
                ordering: "desc",
              }}
            />
          </Grid>
          <Grid item xs={12} className="tablehead1">
            <CSVLink
              data={this.state.yearposts}
              className="csvdownload"
              filename="MBTCityYearlyRevenue.csv"
            >
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              columns={[
                {
                  title: "Year",
                  field: "year",
                  type: "numeric",
                  defaultSort: "desc",
                },
                {
                  title: "Total Revenue",
                  field: "Year_Revenue",
                  type: "html",
                  ordering: "desc",
                  render: rowData => {
                    return (
                      <div>
                        ${" "}
                        {rowData.total_revenue
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "Change in Revenue",
                  field: "totalRevChange",

                  render: rowData => {
                    if (rowData.totalRevChange.includes("-")) {
                      return (
                        <div>
                          {rowData.totalRevChange}
                          <Icon className={classes.Red}>arrow_downward</Icon>
                        </div>
                      );
                    } else if (rowData.totalRevChange !== "0") {
                      return (
                        <div>
                          {rowData.totalRevChange}
                          <Icon className={classes.Green}>arrow_upward</Icon>
                        </div>
                      );
                    } else return <div>{rowData.totalRevChange}</div>;
                  },
                  type: "html",
                },
              ]}
              data={this.state.yearposts}
              title={`Yearly Data: ${this.props.match.params.city}`}
              options={{
                pageSizeOptions: [10, 20, 50, 100],
                columnsButton: true,
              }}
            />
          </Grid>
          <Grid item xs={12} className="tablehead1">
            <CSVLink
              data={this.state.rawposts}
              className="csvdownload"
              filename="MBTCityCompleteDataset.csv"
            >
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              columns={[
                {
                  title: "Year",
                  field: "year",
                  type: "numeric",
                  defaultSort: "desc",
                },
                { title: "Month", field: "month" },
                {
                  title: "Total Revenue",
                  field: "Total_Receipts",
                  type: "html",
                  render: rowData => {
                    return (
                      <div>
                        ${" "}
                        {rowData.Total_Receipts.toString().replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                        .00
                      </div>
                    );
                  },
                },
              ]}
              data={this.state.rawposts}
              title={`Complete Data Set: ${this.props.match.params.city}`}
              options={{
                pageSizeOptions: [10, 20, 50, 100],
                columnsButton: true,
              }}
            />
          </Grid>
        </Grid>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </div>
    );
  }
}
MBTCity.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MBTCity);
