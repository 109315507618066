// This file is shared across the demos.

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import AccountBox from '@material-ui/icons/AccountBox';
import TrendingUp from '@material-ui/icons/TrendingUp';
import History from '@material-ui/icons/History';
import Layers from '@material-ui/icons/Layers';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Public from '@material-ui/icons/Public';
import { Link } from 'react-router-dom';


export const mailFolderListItems = (
  <div>
  <Link to='/stt/search'>
    <ListItem button>
      <ListItemIcon>
        <SearchIcon />
      </ListItemIcon>
      <ListItemText style={{margin: '0', color: '#292929'}} primary="Search" />
    </ListItem>
    </Link>
     <Link to='/stt/portfolio'>
    <ListItem button>
      <ListItemIcon>
        <Layers />
      </ListItemIcon>
      <ListItemText style={{margin: '0', color: '#292929'}} primary="Portfolio" />
    </ListItem>
    </Link>
    <Link to='/stt/brands'>
    <ListItem button>
      <ListItemIcon>
        <Public />
      </ListItemIcon>
      <ListItemText className="brands-link" style={{margin: '0', color: '#292929'}} primary="Brands" />
      <span className="beta-feature-badge" style={{marginLeft: '8px'}}>BETA</span>
    </ListItem>
    </Link>
    <Link to='/stt/recentlyviewed'>
    <ListItem button>
      <ListItemIcon>
        <History />
      </ListItemIcon>
      <ListItemText style={{margin: '0', color: '#292929'}} primary="Recently Viewed" />
    </ListItem>
    </Link>
    <Link to='/stt/trends'>
        <ListItem button>
          <ListItemIcon>
            <TrendingUp />
          </ListItemIcon>
          <ListItemText style={{margin: '0', color: '#292929'}} primary="Hotel Trends" />
        </ListItem>
        </Link>
  </div>
);

export const otherMailFolderListItems = (
  <div>
  <Link to='/stt/settings'>
    <ListItem button>
      <ListItemIcon>
        <AccountBox />
      </ListItemIcon>
      <ListItemText style={{margin: '0', color: '#292929'}} primary="My Account" />
    </ListItem>
    </Link>

    <Link to = '/logout'>
    <ListItem button>
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      <ListItemText style={{margin: '0', color: '#292929'}} primary="Logout" />
    </ListItem>
    </Link>
  </div> 
);