/* eslint-disable no-new-object */
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { dollar } from "./helper";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Chart } from "react-google-charts";
import { CSVLink } from "react-csv";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ReactGA from "react-ga";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Link } from "react-router-dom";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import { durations } from "../utils/googleChartHelper";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import ExploreOffIcon from "@material-ui/icons/ExploreOff";
import GoogleMapReact from "./GoogleMap";
import Fab from "@material-ui/core/Fab";
import API from "../utils/API";
import { Hidden } from "@material-ui/core";

const longText = `
    This is the history of the names under which this property has filed its Texas State hotel occupancy tax. The dates reflect when the name was changed on state tax filings.
    `;

//                   blue,   scarlette,    golden,   green,    purpple,    aqua,   lightorange, booger-green, indigo, blossom-pink, dark-aqua, dark-pink
let chartColors = [
  "#4285f4",
  "#db4437",
  "#f4b400",
  "#0f9d58",
  "#ab47bc",
  "#00acc1",
  "#ff7043",
  "#9e9d24",
  "#5c6bc0",
  "#f06292",
  "#00796b",
  "#c2185b",
];
chartColors = [...chartColors];

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const addSpace = num => {
  let str = " ";
  for (let i = 0; i < num; i++) str += " ";
  return str;
};

const styles = theme => ({
  card: {
    minHeight: "300px",
    [theme.breakpoints.down("xs")]: {
      minHeight: "300px",
      maxHeight: "400px",
    },
    [theme.breakpoints.up("sm")]: {
      // minHeight: '300px',
      maxHeight: "300px",
      overflow: "auto",
    },
  },
  popover: {
    pointerEvents: "none",
    //padding: '15px!important',
    //margin: '15px!important',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  absolute: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    backgroundColor: "#d50000",
    zIndex: "2000",
    "&:hover": {
      backgroundColor: "#f44336",
    },
  },
  typography: {
    margin: 0,
    padding: 0,
  },
  typography3: {
    marginTop: 0,
    padding: 0,
    fontSize: "100%",
    [theme.breakpoints.down("xs")]: {
      fontSize: ".5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  button: {
    textTransform: "Capitalize",
    color: "#fff",
  },
  listItem: {
    paddingTop: "4px",
    paddingBottom: "4px",
    marginTop: "0px",
    marginBottom: "0px",
  },
  legend: {
    position: "none",
  },
  fab: {
    margin: theme.spacing(2),
  },
  Red: {
    color: "red",
    top: "5px",
    position: "relative",
    fontSize: "21px!important",
    fontWeight: "1000!important",
  },
  Green: {
    color: "green",
    top: "5px",
    position: "relative",
    fontSize: "21px!important",
    fontWeight: "1000!important",
  },
  gridRow: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
  },
  iconHover: {
    position: "relative",
    fontSize: "1.5rem",
    color: "#ffc107",
    "&:hover": {
      color: "#ff9800",
    },
  },
  subtext2: {
    height: 25,
    fontSize: ".9rem",
    paddingTop: 3,
    paddingLeft: 5,
  },
  link: {
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "none",
      color: "#2196f3",
    },
  },
  propertyLink: {
    color: "black",
    "&:hover": {
      textDecoration: "none",
      color: "#1e88e5",
    },
  },
  divider: {
    marginTop: 10,
    zIndex: 3,
  },
  mbt: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#0d47a1",
    borderRadius: 5,
    fontSize: ".8rem",
    padding: ".5px 2px",
  },
  mbtDot: {
    height: 10,
    width: 10,
    paddingRight: 5,
    backgroundColor: "#0d47a1",
    borderRadius: "50%",
    display: "inline-block",
  },
  hotelDot: {
    height: 10,
    width: 10,
    paddingRight: 5,
    backgroundColor: "#d50000",
    borderRadius: "50%",
    display: "inline-block",
  },
  hotel: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#d50000",
    borderRadius: 5,
    fontSize: ".8rem",
    padding: "1px 2px",
  },
  description: {
    fontSize: ".8rem",
    textAlign: "center",
    margin: 0,
    paddingTop: 10,
    backgroundColor: "#fafafa",
  },
  propertyName: {
    fontSize: ".8rem",
    //color: '#212121'
  },
  propertyAddress: {
    fontSize: ".8rem",
    margin: 0,
    padding: 0,
    //color: '#212121'
  },
  dotContainer: {
    margin: 0,
    //paddingLeft: 10
  },
  taxInfo: {
    fontSize: "1rem",
    //textAlign: 'center',
    //paddingBottom: 10,
    //color: '#212121'
  },
  taxHeading: {
    fontSize: "1rem",
    //textAlign:'center'
    paddingBottom: 15,
  },
  taxTitle: {
    fontSize: "1rem",
    padding: 0,
    marging: 0,
    display: "inline",
  },
  taxAddress: {
    //textAlign: 'left',
    fontSize: ".8rem",
    //color: '#212121'
  },
  expansionDetails: {
    backgroundColor: "#fafafa",
    marging: 0,
    padding: 0,
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  expansionDetails2: {
    backgroundColor: "#fafafa",
    margin: 0,
  },
  taxInfoWrapper: {
    padding: 12,
    paddingLeft: "10%",
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: 0,
      marginRight: "auto",
      marginLeft: "auto",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      padding: 0,
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  inline: {
    display: "inline",
  },
  noPortfolioMsg: {
    color: "red",
  },
  portfolioLink: {
    marginLeft: "10px",
    color: "#448aff",
    textDecoration: "underline",
    fontSize: "1rem",
  },
  newOwner: {
    fontSize: "18px!important",
  },
  propertyChange: {
    width: "220px",
    fontSize: ".8rem",
    textAlign: "left",
    padding: "12px",
    margin: "0px",
    color: "#fff",
  },
  popoverCell: {
    minWidth: "100px!important",
    maxWidth: "210px!important",
  },
});

class MBTHotelInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      rawposts: [],
      newposts: [],
      getposts: [],
      yearposts: [],
      ttmposts: [],
      token: "",
      errormsg: false,
      portfoliotypes: [],
      noPortfoliosMsg: false,
      portfolioSelected: {},
      opensnack: false,
      value: 0,
      duration: "1",
      duration1: "1",
      durationttm: "1",
      durationttmrevpar: "1",
      type: "",
      urlType: "",
      title: "Add this Hotel to Portfolio",
      open: false,
      taxname: "",
      taxnum: "",
      taxaddress: "",
      taxcity: "",
      taxstate: "",
      taxzip: "",
      postscity: "",
      postsaddress: "",
      postscounty: "",
      postszip: "",
      multiProperties: false,
      otherTaxProperties: [],
      matchedAdresses: [],
      hotelName: this.props.match.params.hotelname
        .replace("(slash)", "/")
        .replace("(hash)", "#"),
      expanded: null,
      postsHotelName: "",
      postsLocationNum: "",
      postsCountyName: "",
      anchorEl: null,
      openedPopoverId: "",
      selectedRow: null,
      openPopover: false,
      latestFiler_type: "",
      dataLoadingStatustimeline: false,
      hotelMBTFinanceData: [],

      //timeline state
      timelineDate: [],
      chartColorsIndex: 0,
      monthlyLoading: true,
      yearlyLoading: true,
      completeLoading: true,
      displayMessage: "SEARCHING...",
      latitude: "",
      longitude: "",
    };
  }

  // save to user history
  onSave() {
    let {
      taxnum,
      postsLocationNum,
      postsHotelName,
      postscity,
      postsCountyName,
    } = this.state;

    if (postsHotelName !== "" || postsHotelName !== undefined) {
      axios({
        method: "post",
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
        },
        url: `${apiUrl}/rest/history`,
        data: {
          taxpayer_number: taxnum,
          location_number: postsLocationNum,
          location_name: postsHotelName,
          location_city: postscity,
          location_county: postsCountyName,
          type: 1,
        },
      })
        .then(res => {})
        .catch(e => {
          ReactGA.exception({
            description: "An error occurred",
          });
          handleError(e);
          //window.location.replace('/login');
        });
    } else {
      let error = "some property key pair values missing";
      handleError(error);
    }
  }

  handleChangePanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = name => event => {
    if (this.state.portfoliotypes)
      this.state.portfoliotypes.map(item => {
        if (item.id === event.target.value) {
          this.setState({ portfolioSelected: item });
        }
      });
    this.setState({
      [name]: event.target.value,
    });
  };
  handleChangetab = (event, value) => {
    this.setState({ value });
  };

  handleClick() {
    if (this.state.type === "") {
      this.setState({ errormsg: 1 });
      return;
    } else {
      this.setState({ errormsg: 0 });
    }
    axios({
      method: "post",
      url: `${apiUrl}/rest/portfolio/hotel`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
      data: {
        taxpayer_number: this.props.match.params.taxpayernumber,
        id: this.state.type,
        location_name: this.props.match.params.hotelname,
        location_number: this.props.match.params.locationnumber,
      },
    })
      .then(res => {
        if (res.data) {
          this.setState({ open: false });
          this.setState({ opensnack: true });
        }
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e);
        //window.location.replace('/logout');
      });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
    if (this.state.portfolio === "") {
      this.setState({ errormsg: true });
      return;
    }
    axios({
      method: "get",
      url: `${apiUrl}/rest/portfolio`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
    })
      .then(res => {
        const rawposts1 = res.data;
        //console.log(rawposts1);
        const updatedposts = [];
        for (var i = 0; i < rawposts1.length; i++) {
          if (rawposts1[i].type === 1) {
            updatedposts[i] = rawposts1[i];
          }
        }
        if (updatedposts.length === 0) {
          this.setState({ noPortfoliosMsg: true });
        }
        this.setState({ portfoliotypes: updatedposts });
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e);
        //window.location.replace('/logout');
      });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ errormsg: 0 });
  };

  // ===== Monthly ======
  handleChange1 = name => event => {
    this.setState({
      [name]: event.target.value,
    });

    let { posts } = this.state;
    const [metadata, data] = posts;
    const columns = [
      { type: "date", label: "Month" },
      { type: "number", label: "Total Revenue" },
    ];

    let rows = [];
    var d = new Date();
    var n = d.getFullYear();
    for (let row of posts) {
      let { date, total_receipts } = row;

      if (
        row.year >= posts[posts.length - 1].year - event.target.value &&
        row.year <= posts[posts.length - 1].year
      ) {
        date = new Date(row.year + "-" + row.month);
        date = new Date(row.year, row.month - 1, 1);
        rows.push([date, total_receipts]);
      }
    }

    this.setState({
      chartData: [columns, ...rows],
      dataLoadingStatus: "ready",
    });
  };

  componentDidMount() {
    // variable to cancel axios request
    this.axiosCancelSource = axios.CancelToken.source();

    window.scrollTo(0, 0);

    const columnstimeline = [
      { type: "string", id: "Role" },
      { type: "string", id: "Name" },
      { type: "date", id: "Start" },
      { type: "date", id: "End" },
    ];

    const rowstimeline = [];

    // API.getOneHotelLatLong(
    //   this.props.match.params.taxpayernumber,
    //   this.props.match.params.locationnumber)
    // .then( res => {
    //   // console.log(`getOneHotelLatLong function called`)
    //   // console.log(res.data)
    //     this.setState({
    //       latitude: res.data[0].latitude,
    //       longitude: res.data[0].longitude
    //     })
    //   })
    // .catch(err => console.log(err))

    // ===== CALL FOR TIMELINE =========
    axios({
      method: "post",
      url: `${apiUrl}/rest/mbt/mbthistory`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
      data: {
        location_number: this.props.match.params.locationnumber,
        taxpayer_number: this.props.match.params.taxpayernumber,
      },
      //data: { location_address: this.state.postsaddress, location_number: this.props.match.params.locationnumber, location_city: this.state.postscity }
      cancelToken: this.axiosCancelSource.token,
    })
      .then(res => {
        let { chartColorsIndex, timelineDate } = this.state;

        // console.log(res.data);
        let unsortedPoststimeline = res.data;
        // Was getting google chart error for the property not sorted by date
        const poststimeline = unsortedPoststimeline.sort(
          (a, b) =>
            new moment(a.responsibility_begin_date).format("YYYYMMDD") -
            new moment(b.responsibility_begin_date).format("YYYYMMDD")
        );
        let postsHotelName;
        let postsaddress;
        this.setState({ poststimeline });
        //console.log("poststimeline", this.state.poststimeline);

        // if there are more then one location name
        if (poststimeline.length > 1) {
          let latest = poststimeline.length - 1;
          postsHotelName = poststimeline[latest].location_name;
          postsaddress = poststimeline[latest].location_address;

          poststimeline.forEach(element => {
            element.color = chartColors[chartColorsIndex++];
            timelineDate[
              moment(element.responsibility_begin_date, "YYYYMMDD")
                .format("YYYY-MMMM")
                .toLowerCase()
            ] = element;
          });
          //console.log('timelineDate', timelineDate)

          let count = 1;
          for (let i = 0; i < poststimeline.length; i++) {
            //if  last....
            if (i + 1 === poststimeline.length) {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name + " ",
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(
                  poststimeline[i].responsibility_end_date || moment(),
                  "YYYYMMDD"
                ),
              ]);
            } else if (
              i !== 0 &&
              poststimeline[i].taxpayer_name !==
                poststimeline[i - 1].taxpayer_name
            ) {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name + new Array(count++).join(" "),
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(
                  poststimeline[i + 1].responsibility_begin_date,
                  "YYYYMMDD"
                ),
              ]);
            } else {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name,
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(
                  poststimeline[i + 1].responsibility_begin_date,
                  "YYYYMMDD"
                ),
              ]);
              //moment(sortedArray[i + 1].responsibility_begin_date, 'YYYYMMDD').add(-1, 'month')])
            }
          }
          //console.log('rowstimeline', rowstimeline)
          this.setState({
            postsHotelName,
            postsaddress,
            dataLoadingStatustimeline: true,
            chartDatatimeline: [columnstimeline, ...rowstimeline],
          });
        } else {
          // just get the only property
          postsHotelName = poststimeline[0].location_name;
          postsaddress = poststimeline[0].location_address;

          poststimeline.forEach(element => {
            element.color = chartColors[chartColorsIndex];
            timelineDate[
              moment(element.responsibility_begin_date, "YYYYMMDD")
                .format("YYYY-MMMM")
                .toLowerCase()
            ] = element;
          });
          //console.log('timelineDate', timelineDate)

          for (let i = 0; i < poststimeline.length; i++) {
            //if  last....
            if (i + 1 === poststimeline.length) {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name,
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(),
              ]);
            } else {
              rowstimeline.push([
                "Hotel Timeline",
                poststimeline[i].location_name,
                moment(poststimeline[i].responsibility_begin_date, "YYYYMMDD"),
                moment(
                  poststimeline[i + 1].responsibility_begin_date,
                  "YYYYMMDD"
                ).add(-1, "month"),
              ]);
            }
          }
          //console.log('rowstimeline', rowstimeline)
          this.setState({
            postsHotelName,
            postsaddress,
            dataLoadingStatustimeline: true,
            chartDatatimeline: [columnstimeline, ...rowstimeline],
          });
        }

        // ===== call to get MBT property data ==========
        axios
          .get(
            `${apiUrl}/rest/mbt/${this.props.match.params.hotelname}/${this.props.match.params.taxpayernumber}/${this.props.match.params.locationnumber}`,
            {
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"),
              },
              cancelToken: this.axiosCancelSource.token,
            }
          )
          .then(res => {
            // currently receiving from oldest to latest (ascending)
            //const posts = res.data.reverse();
            const posts = res.data;
            this.setState({ posts: posts });

            const postsLocationNum = posts[posts.length - 1].location_number;
            const postscity = posts[posts.length - 1].location_city;
            const postszip = posts[posts.length - 1].location_zip;
            const postscounty = posts[posts.length - 1].verified_county;
            const postsCountyName =
              posts[posts.length - 1].location_county_name;
            const taxname = posts[posts.length - 1].taxpayer_name;
            const taxaddress = posts[posts.length - 1].taxpayer_address;
            const taxnum = posts[posts.length - 1].taxpayer_number;
            const taxcity = posts[posts.length - 1].taxpayer_city;
            const taxstate = posts[posts.length - 1].taxpayer_state;
            const taxzip = posts[posts.length - 1].taxpayer_zip;
            const latestFiler_type = posts[posts.length - 1].filer_type;
            //console.log("latest filer", latestFiler_type);

            // console.log("GET MBT Basic setting state")
            this.setState({
              postsLocationNum,
              postscity,
              postszip,
              postscounty,
              postsCountyName,
              taxname,
              taxaddress,
              taxnum,
              taxcity,
              taxstate,
              taxzip,
              latestFiler_type,
            });

            // Then Set the doc title
            document.title =
              this.state.postsHotelName +
              ", " +
              this.state.postscity +
              ",TX - Search Texas Tax";
            ReactGA.initialize("UA-80211377-3");
            ReactGA.pageview(window.location.pathname);

            const [metadata, data] = posts;

            const columns = [
              { type: "date", label: "Month" },
              { type: "number", label: "Total Revenue" },
            ];

            let rows = [];
            for (let row of posts) {
              let { date, total_receipts } = row;
              if (
                row.year >= posts[posts.length - 1].year - 1 &&
                row.year <= posts[posts.length - 1].year
              ) {
                date = new Date(row.year + "-" + row.month);
                date = new Date(row.year, row.month - 1, 1);
                rows.push([date, total_receipts]);
              }
            }

            this.setState({
              chartData: [columns, ...rows],
              dataLoadingStatus: "ready",
            });

            const rawposts = res.data;
            this.setState({ rawposts: rawposts, completeLoading: false });

            //let posts = res.data;

            var i = 0;
            var j = 0;
            var a = 0;
            var newposts = [];

            for (i = 0; i < posts.length; i++) {
              if (a === 0) {
                // ////////console.log(newposts);
                newposts.push(new Object());
                ////////console.log(newposts);
                newposts[newposts.length - 1].year = posts[i].year;
                if (posts[i].month === 1) {
                  newposts[newposts.length - 1].january =
                    posts[i].total_receipts;
                }
                if (posts[i].month === 2) {
                  newposts[newposts.length - 1].february =
                    posts[i].total_receipts;
                }
                if (posts[i].month === 3) {
                  newposts[newposts.length - 1].march = posts[i].total_receipts;
                }
                if (posts[i].month === 4) {
                  newposts[newposts.length - 1].april = posts[i].total_receipts;
                }
                if (posts[i].month === 5) {
                  newposts[newposts.length - 1].may = posts[i].total_receipts;
                }
                if (posts[i].month === 6) {
                  newposts[newposts.length - 1].june = posts[i].total_receipts;
                }
                if (posts[i].month === 7) {
                  newposts[newposts.length - 1].july = posts[i].total_receipts;
                }
                if (posts[i].month === 8) {
                  newposts[newposts.length - 1].august =
                    posts[i].total_receipts;
                }
                if (posts[i].month === 9) {
                  newposts[newposts.length - 1].september =
                    posts[i].total_receipts;
                }
                if (posts[i].month === 10) {
                  newposts[newposts.length - 1].october =
                    posts[i].total_receipts;
                }
                if (posts[i].month === 11) {
                  newposts[newposts.length - 1].november =
                    posts[i].total_receipts;
                }
                a = 1;
                if (posts[i].month === 12) {
                  newposts[newposts.length - 1].december =
                    posts[i].total_receipts;
                  a = 0;
                }
                continue;
              }

              for (j = 0; j < newposts.length; j++) {
                if (newposts[j].year === posts[i].year) {
                  if (posts[i].month === 1) {
                    newposts[j].january = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                    //////////////console.log(temp['January']);
                  }
                  if (posts[i].month === 2) {
                    newposts[j].february = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                    //////////////console.log(newposts[j]);
                  }
                  if (posts[i].month === 3) {
                    newposts[j].march = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 4) {
                    newposts[j].april = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 5) {
                    newposts[j].may = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 6) {
                    newposts[j].june = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 7) {
                    newposts[j].july = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 8) {
                    newposts[j].august = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 9) {
                    newposts[j].september = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 10) {
                    newposts[j].october = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 11) {
                    newposts[j].november = posts[i].total_receipts;
                    if (i < posts.length - 1) {
                      if (posts[i + 1].year !== posts[i].year) {
                        a = 0;
                      }
                    }
                  }
                  if (posts[i].month === 12) {
                    newposts[j].december = posts[i].total_receipts;
                    a = 0;
                  }
                  break;
                }
              }
            }

            // convert to descending for table
            let newpostsReversed = newposts.reverse();
            this.setState({ newposts: newpostsReversed });

            var getposts = [];
            for (let i = 0; i < newposts.length; i++) {
              getposts.push(new Object());
              getposts[i].Year = newposts[i].year;
              getposts[i].January = newposts[i].january;
              getposts[i].February = newposts[i].february;
              getposts[i].March = newposts[i].march;
              getposts[i].Q1 =
                (newposts[i].january || 0) +
                (newposts[i].february || 0) +
                (newposts[i].march || 0);
              getposts[i].April = newposts[i].april;
              getposts[i].May = newposts[i].may;
              getposts[i].June = newposts[i].june;
              getposts[i].Q2 =
                (newposts[i].april || 0) +
                (newposts[i].may || 0) +
                (newposts[i].june || 0);
              getposts[i].July = newposts[i].july;
              getposts[i].August = newposts[i].august;
              getposts[i].September = newposts[i].september;
              getposts[i].Q3 =
                (newposts[i].july || 0) +
                (newposts[i].august || 0) +
                (newposts[i].september || 0);
              getposts[i].October = newposts[i].october;
              getposts[i].November = newposts[i].november;
              getposts[i].December = newposts[i].december;
              getposts[i].Q4 =
                (newposts[i].october || 0) +
                (newposts[i].november || 0) +
                (newposts[i].december || 0);
            }
            this.setState({ getposts: getposts, monthlyLoading: false });

            // ===== Checking for Other Properties under same Tax Payer number =====
            axios
              .get(`${apiUrl}/rest/search/taxpayerproperties/${taxnum}`, {
                headers: {
                  authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
                },
                cancelToken: this.axiosCancelSource.token,
              })
              .then(res => {
                //console.log("GET taxpayer match res gotten")
                if (res.data.length > 1) {
                  let otherProperties = res.data;
                  let taxProperties = [];
                  // check if other property has the same name and type to remove from propertieslist
                  for (let i = 0; i < otherProperties.length; i++) {
                    if (
                      otherProperties[i].location_name ===
                        this.state.hotelName &&
                      otherProperties[i].location_address ===
                        this.state.postsaddress &&
                      otherProperties[i].type === "mbt"
                    ) {
                      continue;
                    }
                    taxProperties.push(otherProperties[i]);
                  }
                  //console.log("GET taxpayer match setting state")
                  this.setState({ otherTaxProperties: taxProperties });
                }
              })
              .catch(e => {
                ReactGA.exception({
                  description: "An error occurred",
                });
                handleError(e);
                //window.location.replace('/logout');
              });

            // ======= Match Address to Locate other Properties by same TaxPayer
            axios({
              method: "post",
              url: `${apiUrl}/rest/search/addressmatch/`,
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
              },
              data: {
                address: postsaddress,
                zip: postszip,
              },
              cancelToken: this.axiosCancelSource.token,
            })
              .then(res => {
                if (res.data.length > 1) {
                  //console.log("POST address match response gotten")
                  let otherProperties = res.data;
                  let matchLocation = [];
                  for (let i = 0; i < otherProperties.length; i++) {
                    if (
                      otherProperties[i].location_name ===
                        this.state.hotelName &&
                      otherProperties[i].type === "mbt"
                    ) {
                      continue;
                    }
                    if (otherProperties[i].type === "mbt") {
                      continue;
                    }
                    matchLocation.push(otherProperties[i]);
                  }
                  //console.log("POST address match setting state")
                  this.setState({ matchedAdresses: matchLocation });
                }
              })
              .catch(e => {
                ReactGA.exception({
                  description: "An error occurred",
                });
                handleError(e);
                //window.location.replace('/logout');
              });
          })
          .catch(e => {
            handleError(e);
          });

        API.getHotelFinanceRecordYearlyMBT(
          this.props.match.params.taxpayernumber,
          this.props.match.params.locationnumber
        )
          .then(res => {
            const newArr = [];
            res.data.forEach((element, index) => {
              if (res.data[index - 1]) {
                element.changeInTotalRev = (
                  element.total_revenue - res.data[index - 1].total_revenue
                ).toLocaleString();
                element.changeInTotalRevPercent = (
                  (element.total_revenue - res.data[index - 1].total_revenue) /
                  100
                ).toLocaleString();
                //  element.total_revenue  = `$ ${element.total_revenue.toLocaleString()}.00`
              } else {
                element.changeInTotalRev = `0`;
                element.changeInTotalRevPercent = `0`;
              }
              newArr.push(element);
              // console.log("this is the newArr")
              // console.log(newArr)
            });
            this.setState({ hotelMBTFinanceData: res.data });
          })
          .catch(err => console.log(err));

        // ======= yearly data ===========
        // .then(res => {
        //     var yearlyposts = res.data;
        //     console.log("yearlyvalues", yearlyposts);
        //     for (var l = 0; l < yearlyposts.length; l++) {
        //       // checking for the first year and setting RevChange and RevParChange to 0, since there is no comparision yet
        //       if (l === 0) {
        //         yearlyposts[l].totalRevChange = '$0 (0%)';
        //         yearlyposts[l].RevPARChange = '$0 (0%)';
        //       } else {
        //         yearlyposts[l].totalRevChange = '$ ' + (yearlyposts[l].total_revenue - yearlyposts[l - 1].total_revenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' (' + ((((yearlyposts[l].total_revenue / yearlyposts[l - 1].total_revenue) * 100) - 100).toFixed(2)) + "%)";
        //         yearlyposts[l].RevPARChange = '$ ' + (yearlyposts[l].RevPar - yearlyposts[l - 1].RevPar).toFixed(2) + ' (' + ((((yearlyposts[l].RevPar / yearlyposts[l - 1].RevPar) * 100) - 100).toFixed(2)) + "%)";
        //       }
        //     }

        //     if (res.data !== undefined) {
        //       axios.get(`${apiUrl}/rest/mbt/testlastyearlyvalues/${yearlyposts[yearlyposts.length - 1].year}/${this.props.match.params.hotelname}/${this.props.match.params.taxpayernumber}/${this.props.match.params.locationnumber}/${latestFiler_type}`,
        //         {
        //           headers: {
        //             authorization: 'Bearer ' + localStorage.getItem('token'), //the token is a variable which holds the token
        //           }
        //         }
        //       ).then(res1 => {
        //         console.log("lastyear value", res1);
        //         // gets last year's totals and substracts to yearlyposts same year & caclulates percentage
        //         if (res1.data[0]) {
        //           yearlyposts[yearlyposts.length - 1].totalRevChange = '$ ' + (yearlyposts[yearlyposts.length - 1].total_revenue - res1.data[0].total_revenue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' (' + ((((yearlyposts[yearlyposts.length - 1].total_revenue / res1.data[0].total_revenue) * 100) - 100).toFixed(2)) + "%)";
        //           yearlyposts[yearlyposts.length - 1].RevPARChange = '$ ' + (yearlyposts[yearlyposts.length - 1].RevPar - res1.data[0].RevPar).toFixed(2) + ' (' + ((((yearlyposts[yearlyposts.length - 1].RevPar / res1.data[0].RevPar) * 100) - 100).toFixed(2)) + "%)";

        //           this.setState({ yearlyposts: yearlyposts });
        //           this.setState({ showyear: true });
        //         }
        //         else {
        //           // dont show the yearly table
        //           this.setState({ showyear: false });
        //         }
        //       }).catch((e) => {
        //         handleError(e);
        //       })
        //     }
        //   }).catch((e) => {
        //     handleError(e);
        //   })
      })
      .catch(e => {
        ReactGA.exception({
          description: "An error occurred",
        });
        handleError(e);
        //window.location.replace('/login');
      });
  }

  componentWillUnmount() {
    //console.log("UNMOUNTED")
    // Then save to recently viewed
    this.onSave();
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  render() {
    let errormsg;
    if (this.state.errormsg === 1) {
      errormsg = (
        <p className="errormsg">
          Please Select a Portfolio to Add this Hotel to it.
        </p>
      );
    }
    const { classes, theme } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    let chartdisplaytime;
    if (this.state.dataLoadingStatustimeline) {
      chartdisplaytime = (
        <Chart
          chartType="Timeline"
          graph_id={shortid.generate()}
          loader={<CircularProgress className="loading" size={50} />}
          data={this.state.chartDatatimeline}
          width="100%"
          height="100px"
          options={{
            timeline: { groupByRowLabel: true },
            avoidOverlappingGridLines: false,
            colors: chartColors,
          }}
        />
      );
    } else {
      chartdisplaytime = <CircularProgress className="loading" size={50} />;
    }

    let chartdisplay;
    if (this.state.dataLoadingStatus === "ready") {
      chartdisplay = (
        <div>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="outlined-select-currency"
              select
              className={classes.textField}
              value={this.state.duration}
              onChange={this.handleChange1("duration")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
            >
              {durations.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </form>
          <Chart
            chartType="Line"
            graph_id={shortid.generate()}
            loader={<CircularProgress className="loading" size={50} />}
            width={"100%"}
            height={"400px"}
            data={this.state.chartData}
            options={{
              chartArea: { height: "100%", width: "90%" },
              hAxis: {
                format: "yyyy",
              },
              vAxis: {
                format: "short",
              },
              title: "Debt incurred over time.",
              series: {
                // Gives each series an axis name that matches the Y-axis below.
                0: { axis: "Total_Revenue" },
              },
              axes: {
                // Adds labels to each axis; they don't have to match the axis names.
                y: {
                  Total_Revenue: { label: "Monthly Revenue" },
                  all: {
                    format: {
                      pattern: "currency",
                    },
                  },
                },
                x: {
                  all: {
                    format: {
                      pattern: "MMM. yyyy",
                    },
                  },
                },
              },
              legend: { position: "none" },
            }}
            rootProps={{ "data-testid": "2" }}
          />
        </div>
      );
    } else {
      chartdisplay = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }

    const {
      value,
      otherTaxProperties,
      matchedAdresses,
      timelineDate,
    } = this.state;
    return (
      <div>
        <Grid container spacing={3}>
          {/* {this.state.latitude && this.state.longitude === [] ? */}
          <>
            <Grid item xs={12}>
              <Paper className="data_title">
                <div className="row">
                  <div className="col">
                    <h2 style={{ padding: 0, margin: 0 }}>
                      {this.state.postsHotelName}
                    </h2>
                  </div>
                  <div className="col">
                    <Typography
                      className={classes.subtext}
                      color="textSecondary"
                    >
                      {this.state.postsaddress},{" "}
                      <Link
                        className="clickable"
                        to={`/stt/city/${this.state.postscity}`}
                      >
                        {this.state.postscity}
                      </Link>
                      , TX {this.state.postszip} &#x25CF;{" "}
                      <Link
                        className="clickable"
                        to={`/stt/county/${this.state.postsCountyName}`}
                      >
                        {" "}
                        {this.state.postsCountyName}
                      </Link>{" "}
                      County
                      <Tooltip title={longText} placement="right-end">
                        <Icon className={classes.Green}>contact_support</Icon>
                      </Tooltip>
                    </Typography>
                  </div>
                  <div className="clear"></div>
                  {chartdisplaytime}
                </div>
              </Paper>
            </Grid>
          </>
          {/* </> :
          <>
          <Grid item xs={12} sm={8}> 
            <Paper className="data_title">
              <div className="row">
                <div className="col">
                  <h2 style={{ padding: 0, margin: 0 }}>{this.state.postsHotelName}</h2>
                </div>
                <div className="col">
                  <Typography className={classes.subtext} color="textSecondary">
                    {this.state.postsaddress}, <Link className="clickable" to={`/stt/city/${this.state.postscity}`} >{this.state.postscity}</Link>, TX
                    {" "}{this.state.postszip} &#x25CF; <Link className="clickable" to={`/stt/county/${this.state.postsCountyName}`} > {this.state.postsCountyName}</Link> County
                    <Tooltip title={longText} placement="right-end">
                    <Icon className={classes.Green}>contact_support</Icon>
                    </Tooltip>
                  </Typography>
                </div>
                <div className="clear"></div>
                  {chartdisplaytime}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
                <GoogleMapReact 
                  lat={this.state.latitude} 
                  long={this.state.longitude} 
                  address={this.state.location_address}
                />
              <Grid 
              container
              direction="column"
              alignItems="center" 
              justify="center" 
              style={{height: "200px"}} 
              xs={12}>
                  <Typography>Coordinates not found</Typography>
                  <ExploreOffIcon fontSize="large" />
              </Grid>
            </Paper>
          </Grid>
          </> */}

          {/* <Grid item xs={3} className="chartmobilegrid">
            <ExpansionPanel expanded={this.state.expanded === 'panel1'} onChange={this.handleChangePanel('panel1')} style={{ backgroundColor: '#cfd8dc' }} >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="button" style={{ color: 'black' }}>Lodging Data</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <Grid container style={{ padding: 5 }}>
                  <Grid item xs={12}>
                    <div className={classes.description}>
                      <Typography><em>Lodging properties with the same address.</em></Typography>
                    </div>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid container container style={{ padding: 10, paddingLeft: 20 }}>
                    {
                      matchedAdresses.length > 0 ?
                        matchedAdresses
                          .slice(0, 10)
                          .map((item, index) => (
                            <React.Fragment>
                              <Grid item xs={12} style={{ marginBottom: 10 }}>
                                <Link className={classes.propertyLink} to={`/stt/hotelinfo/${item.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${item.taxpayer_number}/${item.location_number}`}>
                                  <span className={classes.propertyName}><strong>{item.location_name}</strong></span>
                                  <p className={classes.propertyAddress}>Address: {item.location_address}, {item.location_city}, {item.location_state}, {item.location_zip}</p>
                                </Link>
                              </Grid>
                              <br />
                            </React.Fragment>

                          ))
                        :
                        <React.Fragment>
                          <Grid item xs={12} >
                            <Typography variant="subtitle2" style={{ textAlign: 'center' }}>There are no other properties.</Typography>
                          </Grid>
                        </React.Fragment>
                    }
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel >
            <ExpansionPanel expanded={this.state.expanded === 'panel2'} onChange={this.handleChangePanel('panel2')} style={{ backgroundColor: '#cfd8dc' }}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="button" style={{ color: 'black' }}>Taxpayer's Information</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails2}>
                <Grid container className={classes.taxInfoWrapper}>
                  <Grid item xs={12}>
                    <Typography className={classes.taxHeading}><strong>OWNER INFORMATION</strong></Typography>
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: 10 }}>
                    <Typography variant="subtitle2" className={classes.taxTitle}><strong>{this.state.taxname}</strong></Typography>
                    <Typography variant="body1">
                      <div style={{ marging: 0, padding: 0 }}>{this.state.taxaddress}.</div>
                      <div style={{ marging: 0, padding: 0 }}>{this.state.taxcity}, {this.state.taxstate}, {this.state.taxzip}</div>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.taxTitle}><strong>Taxpayer Number: </strong></Typography>
                    <span>{this.state.taxnum}</span>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={this.state.expanded === 'panel3'} onChange={this.handleChangePanel('panel3')} style={{ backgroundColor: '#cfd8dc' }}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="button" style={{ color: 'black' }}>Taxpayer's Other Properties</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionDetails}>
                <Grid container style={{ padding: 5 }}>
                  <Grid item xs={12}>
                    <div className={classes.description}>
                      <Typography><em>Other properties owned by Tax Payer.</em></Typography>
                      <Typography style={{ marginTop: 10 }}><span className={classes.hotelDot}></span> Hotel   <span className={classes.mbtDot}></span> MBT </Typography>
                    </div>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid container style={{ padding: 20 }}>
                    {otherTaxProperties.length > 0 ?
                      otherTaxProperties
                        .slice(0, 10)
                        .map((item, index) => (
                          item.type === "hotel" ?
                            <React.Fragment>
                              <Grid container style={{ marginTop: 10 }}>
                                <Grid item xs={1} className={classes.dotContainer}>
                                  <span className={classes.hotelDot}></span>
                                </Grid>
                                <Grid item xs={11}>
                                  <Link className={classes.propertyLink} to={{ pathname: `/stt/hotelinfo/${item.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${item.taxpayer_number}/${item.location_number}`, state: "desiredState" }}>
                                    <span className={classes.propertyName}><strong>{item.location_name}</strong></span>
                                    <p className={classes.propertyAddress}>Address: {item.location_address}, {item.location_city}, {item.location_state}, {item.location_zip}</p>
                                  </Link>
                                </Grid>
                              </Grid>
                            </React.Fragment> :
                            <React.Fragment>
                              <Grid container style={{ marginTop: 10 }}>
                                <Grid item xs={1} className={classes.dotContainer}>
                                  <span className={classes.mbtDot}></span>
                                </Grid>
                                <Grid item xs={11}>
                                  <Link className={classes.propertyLink} to={{ pathname: '/stt/mbtredirect', state: `/stt/mbthotelinfo/${item.location_name.replace('/', "(slash)").replace('#', "(hash)")}/${item.taxpayer_number}/${item.location_number}` }}>
                                    <span className={classes.propertyName}><strong>{item.location_name}</strong></span>
                                    <p className={classes.propertyAddress}>Address: {item.location_address}, {item.location_city}, {item.location_state}, {item.location_zip}</p>
                                  </Link>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                        ))
                      :
                      <React.Fragment>
                        <Grid item xs={12} >
                          <Typography variant="subtitle2" style={{ textAlign: 'center' }}><em>There are no other properties.</em></Typography>
                        </Grid>
                      </React.Fragment>
                    }
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid> */}

          <Grid item xs={12} sm={6} md={4}>
            <Paper
              // style={{ height: '25vh', overflow: 'auto' }}
              className={classes.card}
            >
              <CardHeader title="Lodging Records" />
              {matchedAdresses.length > 0 ? (
                matchedAdresses.slice(0, 10).map((item, index) => (
                  <React.Fragment key={index}>
                    <CardContent className={classes.listItem}>
                      <Link
                        className={classes.propertyLink}
                        to={`/stt/hotelinfo/${item.location_name
                          .replace("/", "(slash)")
                          .replace("#", "(hash)")}/${item.taxpayer_number}/${
                          item.location_number
                        }`}
                      >
                        <Typography className={classes.typography}>
                          <strong>{item.location_name}</strong>
                        </Typography>
                        <Typography className={classes.typography3}>
                          {item.location_address},{item.location_state},
                          {item.location_zip}
                        </Typography>
                      </Link>
                    </CardContent>
                  </React.Fragment>
                ))
              ) : (
                <CardContent>
                  <Typography>There are no other properties.</Typography>
                </CardContent>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper className={classes.card}>
              <CardHeader title="Owner Information" />
              <CardContent className={classes.listItem}>
                <Typography className={classes.typography}>
                  <strong>{this.state.taxname}</strong>
                </Typography>
                <Typography className={classes.typography3}>
                  {this.state.taxaddress}
                </Typography>
                <Typography className={classes.typography3}>
                  {this.state.taxcity}, {this.state.taxstate},{" "}
                  {this.state.taxzip}
                </Typography>
              </CardContent>
              <CardContent className={classes.listItem}>
                <Typography>
                  <strong>Taxpayer Number: </strong>{" "}
                  <span>{this.state.taxnum}</span>
                </Typography>
                {this.state.postsfiler ? (
                  <Typography>
                    <strong>Filer Type: </strong>
                    <span>{this.state.postsfiler}</span>
                  </Typography>
                ) : (
                  false
                )}
              </CardContent>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {/* <Paper style={{height: '25vh', overflow: 'auto'}}> */}
            <Paper className={classes.card}>
              <CardHeader title="Taxpayer other Properties" />
              <List>
                {otherTaxProperties.length > 0 ? (
                  otherTaxProperties.map((item, index) => (
                    <React.Fragment>
                      {item.type === "hotel" ? (
                        <Link
                          className={classes.propertyLink}
                          to={{
                            pathname: `/stt/hotelinfo/${item.location_name
                              .replace("/", "(slash)")
                              .replace("#", "(hash)")}/${
                              item.taxpayer_number
                            }/${item.location_number}`,
                          }}
                        >
                          <ListItem>
                            {item.type === "hotel" ? (
                              <Chip
                                style={{ marginRight: 10 }}
                                color="secondary"
                                label="Hotel"
                                size="small"
                                mx={2}
                              />
                            ) : (
                              <Chip
                                style={{ marginRight: 10 }}
                                color="primary"
                                label="MBT"
                                size="small"
                                mx={2}
                              />
                            )}
                            <ListItemText
                              style={{ padding: 0, margin: 0 }}
                              className={classes.typography}
                              primary={<strong>{item.location_name}</strong>}
                              secondary={
                                <React.Fragment>
                                  <Typography>
                                    {item.location_address},{" "}
                                    {item.location_city}, {item.location_state}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                            <Divider />
                          </ListItem>
                        </Link>
                      ) : (
                        <Link
                          className={classes.propertyLink}
                          to={{
                            pathname: "/stt/mbtredirect",
                            state: `/stt/mbthotelinfo/${item.location_name
                              .replace("/", "(slash)")
                              .replace("#", "(hash)")}/${
                              item.taxpayer_number
                            }/${item.location_number}`,
                          }}
                        >
                          <ListItem>
                            {item.type === "hotel" ? (
                              <Chip
                                style={{ marginRight: 10 }}
                                color="secondary"
                                label="Hotel"
                                size="small"
                                mx={2}
                              />
                            ) : (
                              <Chip
                                style={{ marginRight: 10 }}
                                color="primary"
                                label="MBT"
                                size="small"
                                mx={2}
                              />
                            )}
                            <ListItemText
                              style={{ padding: 0, margin: 0 }}
                              primary={<strong>{item.location_name}</strong>}
                              secondary={
                                <React.Fragment>
                                  <Typography>
                                    {item.location_address},{" "}
                                    {item.location_city}, {item.location_state}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                            <Divider />
                          </ListItem>
                        </Link>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <CardContent>
                    <Typography>There are no other properties.</Typography>
                  </CardContent>
                )}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} className="chartmobilegrid">
            <Paper>
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={this.handleChangetab}
                  scrollable
                  scrollButtons="auto"
                >
                  <Tab label="Monthly Revenue" />
                </Tabs>
              </AppBar>
              {value === 0 && <TabContainer>{chartdisplay}</TabContainer>}
            </Paper>
          </Grid>
          <Grid item xs={12} className="tablehead1">
            <CSVLink
              data={this.state.getposts}
              className="csvdownload"
              filename="MBTMonthly&QuarterlyResults.csv"
            >
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>

            <MaterialTable
              isLoading={this.state.monthlyLoading}
              columns={[
                {
                  title: "Year",
                  field: "year",
                  type: "numeric",
                  defaultSort: "desc",
                },
                {
                  title: "January",
                  field: "january",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-january`] ===
                      "undefined"
                    ) {
                      return rowData.january ? (
                        <div>
                          $&nbsp;
                          {rowData.january
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.january ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.january
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="january"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "February",
                  field: "february",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-february`] ===
                      "undefined"
                    ) {
                      return rowData.february ? (
                        <div>
                          $&nbsp;
                          {rowData.february
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.february ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.february
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="february"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "March",
                  field: "march",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-march`] ===
                      "undefined"
                    ) {
                      return rowData.march ? (
                        <div>
                          $&nbsp;
                          {rowData.march
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.march ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.march
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="march"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "Quarter 1",
                  field: "march",
                  type: "html",
                  render: rowData => {
                    return (
                      <div style={{ fontWeight: "700" }}>
                        $&nbsp;
                        {(
                          (rowData.january || 0) +
                          (rowData.february || 0) +
                          (rowData.march || 0)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                  cellStyle: {
                    backgroundColor: "#f5f5f5",
                  },
                  headerStyle: {
                    fontWeight: "800",
                    maxWidth: "125px",
                    //backgroundColor: '#f5f5f5',
                  },
                },
                {
                  title: "April",
                  field: "april",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-april`] ===
                      "undefined"
                    ) {
                      return rowData.april ? (
                        <div>
                          $&nbsp;
                          {rowData.april
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.april ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.april
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="april"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "May",
                  field: "may",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-may`] === "undefined"
                    ) {
                      return rowData.may ? (
                        <div>
                          $&nbsp;
                          {rowData.may
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.may ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.may
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="may"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "June",
                  field: "june",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-june`] ===
                      "undefined"
                    ) {
                      return rowData.june ? (
                        <div>
                          $&nbsp;
                          {rowData.june
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.june ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.june
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="june"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "Quarter 2",
                  field: "june",
                  type: "html",
                  render: rowData => {
                    return (
                      <div style={{ fontWeight: "700" }}>
                        $&nbsp;
                        {(
                          (rowData.april || 0) +
                          (rowData.may || 0) +
                          (rowData.june || 0)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                  cellStyle: {
                    backgroundColor: "#f5f5f5",
                    //color: '#FFF'
                  },
                  headerStyle: {
                    fontWeight: "800",
                    maxWidth: "125px",
                  },
                },
                {
                  title: "July",
                  field: "july",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-july`] ===
                      "undefined"
                    ) {
                      return rowData.july ? (
                        <div>
                          $&nbsp;
                          {rowData.july
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.july ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.july
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="july"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "August",
                  field: "august",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-august`] ===
                      "undefined"
                    ) {
                      return rowData.august ? (
                        <div>
                          $&nbsp;
                          {rowData.august
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.august ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.august
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="august"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "September",
                  field: "september",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-september`] ===
                      "undefined"
                    ) {
                      return rowData.september ? (
                        <div>
                          $&nbsp;
                          {rowData.september
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.september ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.september
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="september"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "Quarter 3",
                  field: "september",
                  type: "html",
                  render: rowData => {
                    return (
                      <div style={{ fontWeight: "700" }}>
                        $&nbsp;
                        {(
                          (rowData.july || 0) +
                          (rowData.august || 0) +
                          (rowData.september || 0)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                  cellStyle: {
                    backgroundColor: "#f5f5f5",
                    //color: '#FFF'
                  },
                  headerStyle: {
                    fontWeight: "800",
                    maxWidth: "125px",
                  },
                },
                {
                  title: "October",
                  field: "october",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-october`] ===
                      "undefined"
                    ) {
                      return rowData.october ? (
                        <div>
                          $&nbsp;
                          {rowData.october
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.october ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.october
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="october"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "November",
                  field: "november",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-november`] ===
                      "undefined"
                    ) {
                      return rowData.november ? (
                        <div>
                          $&nbsp;
                          {rowData.november
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.november ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.november
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="november"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.openPopover}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "December",
                  field: "december",
                  type: "html",
                  render: rowData => {
                    if (
                      typeof timelineDate[`${rowData.year}-december`] ===
                      "undefined"
                    ) {
                      return rowData.december ? (
                        <div>
                          $&nbsp;
                          {rowData.december
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          .00
                        </div>
                      ) : (
                        <div>$&nbsp;0.00</div>
                      );
                    } else {
                      return (
                        <div className={classes.popoverCell}>
                          {rowData.december ? (
                            <span>
                              {" "}
                              $&nbsp;
                              {rowData.december
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              .00{" "}
                            </span>
                          ) : (
                            <span>$&nbsp;0.00</span>
                          )}
                          <PopoverComponent
                            classes={classes}
                            month="december"
                            openedPopoverId={this.state.openedPopoverId}
                            anchorEl={this.state.anchorEl}
                            open={this.state.open}
                            handlePopoverClose={this.handlePopoverClose}
                            handlePopoverOpen={this.handlePopoverOpen}
                            rowData={rowData}
                            timelineDate={timelineDate}
                          />
                        </div>
                      );
                    }
                  },
                },
                {
                  title: "Quarter 4",
                  field: "december",
                  type: "html",
                  render: rowData => {
                    return (
                      <div style={{ fontWeight: "700" }}>
                        $&nbsp;
                        {(
                          (rowData.october || 0) +
                          (rowData.november || 0) +
                          (rowData.december || 0)
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                  cellStyle: {
                    backgroundColor: "#f5f5f5",
                  },
                  headerStyle: {
                    fontWeight: "800",
                    maxWidth: "125px",
                  },
                },
              ]}
              data={this.state.newposts}
              title={`Monthly & Quarterly Performance: ${this.state.postsHotelName}`}
              onRowClick={(evt, selectedRow) => this.setState({ selectedRow })}
              options={{
                //pageSize: 10,
                pageSizeOptions: [10, 20, 50, 100],
                columnsButton: true,
                loadingType: "linear",
                rowStyle: rowData => ({
                  backgroundColor:
                    this.state.selectedRow &&
                    this.state.selectedRow.tableData.id === rowData.tableData.id
                      ? "#f5f5f5"
                      : "#FFF",
                }),
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: this.state.displayMessage,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                overflow: "Hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <MaterialTable
                title={`Yearly Revenue: ${this.state.hotelName}`}
                onRowClick={(evt, selectedRow) =>
                  this.setState({ selectedRow })
                }
                isLoading={this.state.yearlyLoading ? false : true}
                data={this.state.hotelMBTFinanceData}
                options={{
                pageSizeOptions: [10, 20, 50, 100],
                  columnsButton: true,
                  loadingType: "linear",
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: this.state.displayMessage,
                  },
                }}
                columns={[
                  {
                    title: "Year",
                    field: "year",
                    type: "numeric",
                    defaultSort: "desc",
                  },
                  {
                    title: "Total Revenue",
                    field: "total_revenue",
                    type: "html",
                    defaultSort: "desc",
                    render: rowData => {
                      return (
                        <div>
                          $&nbsp;{rowData.total_revenue.toLocaleString()}.00
                        </div>
                      );
                    },
                  },
                  {
                    title: "Change in Revenue",
                    field: "changeInTotalRev",
                    type: "html",
                    defaultSort: "desc",
                    render: rowData => {
                      return (
                        <Grid container>
                          <Grid item xs={11}>
                            $&nbsp;{rowData.changeInTotalRev}.00 (
                            {rowData.changeInTotalRevPercent}%)
                            {rowData.changeInTotalRev.includes("-") ? (
                              <Icon className={classes.Red}>
                                arrow_downward
                              </Icon>
                            ) : (
                              <Icon className={classes.Green}>
                                arrow_upward
                              </Icon>
                            )}
                          </Grid>
                        </Grid>
                      );
                    },
                  },
                ]}
              />
            </div>
          </Grid>

          {/* ======= YEARLY DATA ============ */}
          {/* <Grid item xs={12} className="tablehead1">
              <CSVLink data={this.state.yearlyposts} className="csvdownload" filename="YearlyRevenueResults.csv">
                <Button className="csvbutton" title="Export As CSV">
                  <Icon className="csvicon">save_alt</Icon>
                </Button>
              </CSVLink>
              <MaterialTable
                columns={[
                  { title: 'Year', field: 'year', type: 'numeric', defaultSort: 'desc' },
                  {
                    title: 'Total Revenue', field: 'total_revenue', type: 'html',
                    render: rowData => { return (<div>$ {rowData.total_revenue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</div>) }
                  },
                  {
                    title: 'Change in Revenue', field: 'totalRevChange',

                    render: rowData => {

                      if (rowData.totalRevChange.includes("-")) {
                        return (
                          <Grid container className={classes.colMinWidth}>
                            <Grid item xs={11} >
                              {rowData.totalRevChange}
                            </Grid>
                            <Grid item xs={1} >
                              <Icon className={classes.Red}>arrow_downward</Icon>
                            </Grid>
                          </Grid>
                        )
                      } else if (rowData.totalRevChange !== "$0 (0%)") {
                        return (
                          <Grid container className={classes.colMinWidth} >
                            <Grid item xs={11} >
                              {rowData.totalRevChange}

                            </Grid>
                            <Grid item xs={1} >
                              <Icon className={classes.Green}>arrow_upward</Icon>
                            </Grid>
                          </Grid>
                        )
                      }
                      else return (
                        <Grid container className={classes.colMinWidth}>
                          <Grid item xs={11} >
                            {rowData.totalRevChange}

                          </Grid>
                          <Grid item xs={1} >
                            <Icon className={classes.Grey}>minimize</Icon>
                          </Grid>
                        </Grid>
                      )
                    },
                    type: 'html'
                  },
                  {
                    title: 'RevPar', field: 'RevPar', type: 'html',
                    render: rowData => { return (<div>$ {rowData.RevPar}</div>) }
                  },
                  {
                    title: 'Change in RevPar', field: 'RevPARChange',
                    render: rowData => {
                      if (rowData.RevPARChange.includes("-")) {
                        return (
                          <Grid container className={classes.RevParMinWidth}>
                            <Grid item xs={11} >
                              {rowData.RevPARChange}
                            </Grid>
                            <Grid item xs={1} >
                              <Icon className={classes.Red}>arrow_downward</Icon>
                            </Grid>
                          </Grid>
                        )
                      } else if (rowData.RevPARChange !== "$0 (0%)") {
                        return (
                          <Grid container className={classes.RevParMinWidth}>
                            <Grid item xs={11} >
                              {rowData.RevPARChange}
                            </Grid>
                            <Grid item xs={1} >
                              <Icon className={classes.Green}>arrow_upward</Icon>
                            </Grid>
                          </Grid>
                        )
                      }
                      else return (
                        <Grid container className={classes.RevParMinWidth}>
                          <Grid item xs={11} >
                            {rowData.RevPARChange}
                          </Grid>
                          <Grid item xs={1} >
                            <Icon className={classes.Grey}>minimize</Icon>
                          </Grid>
                        </Grid>
                      )
                    },
                    type: 'html'
                  }
                ]}
                data={this.state.yearlyposts}
                title={`Yearly Revenue: ${this.state.postsHotelName}`}
                options={{
                  columnsButton: true
                }}
              />
            </Grid> */}
          {/* <Grid item xs={8}>
            <Paper style={{height: '25vh', overflow: 'auto'}}>
                <CardHeader title={this.state.postsHotelName} />
                <CardContent className={classes.listItem}>
                <Typography color="textSecondary">
                    {this.state.postsaddress.toLowerCase()}, 
                  <Link className="clickable" to={`/stt/city/${this.state.postscity}`} >
                    {this.state.postscity.toLowerCase()}
                  </Link>
                    {this.state.postszip} &#x25CF;
                  <Link className="clickable" to={`/stt/county/${this.state.postscounty}`} >
                    {this.state.postscounty.toLowerCase()}
                  </Link> 
                  <Tooltip title={longText} placement="right-end">
                    <Icon className={classes.Green}>contact_support</Icon>
                  </Tooltip>
                </Typography>
                </CardContent>
                <div className="col question-mark">
                  <Tooltip title={longText} placement="right-end">
                    <Icon className={classes.Green}>contact_support</Icon>
                  </Tooltip>
                </div> 
                <div className="clear"></div>
                <CardContent>
                  {chartdisplaytime}
                </CardContent>
            </Paper>
          </Grid>  */}

          <Grid item xs={12} className="tablehead1">
            <CSVLink
              data={this.state.rawposts}
              className="csvdownload"
              filename="MBTCompleteDataset.csv"
            >
              <Button className="csvbutton" title="Export As CSV">
                <Icon className="csvicon">save_alt</Icon>
              </Button>
            </CSVLink>
            <MaterialTable
              isLoading={this.state.completeLoading}
              columns={[
                {
                  title: "Year",
                  field: "year",
                  type: "numeric",
                  defaultSort: "desc",
                },
                { title: "Month", field: "month" },
                {
                  title: "Wine Revenue",
                  field: "wine_receipts",
                  type: "html",
                  render: rowData => {
                    return (
                      <div>
                        $&nbsp;
                        {rowData.wine_receipts
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "Liquor Revenue",
                  field: "liquor_receipts",
                  type: "html",
                  render: rowData => {
                    return (
                      <div>
                        $&nbsp;
                        {rowData.liquor_receipts
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "Beer Revenue",
                  field: "beer_receipts",
                  type: "html",
                  render: rowData => {
                    return (
                      <div>
                        $&nbsp;
                        {rowData.beer_receipts
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
                {
                  title: "Total Revenue",
                  field: "total_receipts",
                  type: "html",
                  render: rowData => {
                    return (
                      <div>
                        $&nbsp;
                        {rowData.total_receipts
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        .00
                      </div>
                    );
                  },
                },
              ]}
              data={this.state.rawposts}
              title={`Complete Data Set: ${this.state.postsHotelName}`}
              options={{
                pageSizeOptions: [10, 20, 50, 100],
                columnsButton: true,
                loadingType: "linear",
              }}
              localization={{
                body: {
                  emptyDataSourceMessage: this.state.displayMessage,
                },
              }}
            />
          </Grid>
        </Grid>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />

        <Tooltip title={this.state.title}>
          <Fab
            color="secondary"
            className={classes.absolute}
            onClick={this.handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Select a Portfolio from the dropdown to add this hotel to it"}
          </DialogTitle>
          <DialogContent>
            {errormsg}
            <TextField
              id="search_type"
              select
              label="Portfolio Type"
              className="portfolio-type"
              value={this.state.type}
              onChange={this.handleChange("type")}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
            >
              {this.state.portfoliotypes.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.portfolio}
                </MenuItem>
              ))}
            </TextField>
            {this.state.noPortfoliosMsg ? (
              <p className={classes.noPortfolioMsg}>
                <p>You do not have any portfolios of this type created. </p>
                <Link className={classes.linkMsg} to="/stt/portfolio">
                  {" "}
                  Create one here.
                </Link>
              </p>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.handleClick();
              }}
            >
              Add to Portfolio
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.opensnack}
          autoHideDuration={4000}
          onClose={this.handleCloseSnack}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={
            <span id="message-id">
              Property Added to
              <Link
                className={classes.portfolioLink}
                to={`/stt/portfoliohotel/${this.state.portfolioSelected.type}/${this.state.portfolioSelected.id}/${this.state.portfolioSelected.portfolio}`}
              >
                {this.state.portfolioSelected.portfolio}
              </Link>
            </span>
          }
          action={[]}
        />
      </div>
    );
  }
}
MBTHotelInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(MBTHotelInfo));

// ======= POPOVER CLASS ========
class PopoverComponent extends Component {
  constructor(props) {
    super(props);
    //color = timelineDate[`${props.rowData.year}-${props.month}`].color;
    //console.log("popover Props", props);
    this.state = {
      openedPopoverId: "",
      anchorEl: "",
      month: "",
      classes: "",
      rowData: "",
      color: "",
      popOpen: false,
      timelineData: "",
    };
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  handlePopoverOpen(event, popoverId) {
    this.setState({
      openedPopoverId: popoverId,
      anchorEl: event.target,
    });
  }
  handlePopoverClose = () => {
    this.setState({
      openedPopoverId: null,
      anchorEl: null,
    });
  };

  componentWillMount() {
    let {
      month,
      classes,
      openedPopoverId,
      anchorEl,
      rowData,
      color,
      timelineDate,
    } = this.props;
    let colorBackground =
      timelineDate[`${this.props.rowData.year}-${this.props.month}`].color;
    this.setState({
      month,
      classes,
      openedPopoverId,
      anchorEl,
      rowData,
      timelineDate,
      color: colorBackground,
    });
  }

  render() {
    let {
      month,
      classes,
      openedPopoverId,
      anchorEl,
      rowData,
      color,
      timelineDate,
    } = this.state;

    return (
      <React.Fragment>
        <Popover
          id={month}
          className={classes.popover}
          style={{
            backgroundColor: `${color}!important`,
            opacity: ".5!important",
          }}
          open={openedPopoverId === month}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <div style={{ backgroundColor: `${color}` }}>
            <div className={classes.propertyChange}>
              <p style={{ marginBottom: "2px", marginTop: "0px" }}>
                {moment(
                  timelineDate[
                    `${rowData.year}-${month}`
                  ].responsibility_begin_date.toString()
                ).format("YYYY[-]MM[-]DD")}
                &nbsp;
              </p>
              <p style={{ marginBottom: "2px", marginTop: "0px" }}>
                Property started reporting with the name{" "}
                <em>
                  {timelineDate[`${rowData.year}-${month}`].location_name}
                </em>
                , under{" "}
                <em>
                  {timelineDate[`${rowData.year}-${month}`].taxpayer_name}
                </em>
              </p>
            </div>
          </div>
        </Popover>
        <span style={{ color: `${color}` }}>
          <Icon
            className={classes.newOwner}
            aria-owns={openedPopoverId ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={e => this.handlePopoverOpen(e, month)}
            onMouseLeave={this.handlePopoverClose}
          >
            contact_support
          </Icon>
        </span>
      </React.Fragment>
    );
  }
}
