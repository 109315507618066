import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Send from "@material-ui/icons/Send";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import { STT_SVG } from "../utils/logo/SttLogoExport";
//import classNames from 'classnames';

import { Redirect } from "react-router-dom";
import decode from "jwt-decode";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: "flex",
    backgroundColor: "#1565c0",
    height: "100vh",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
    //marginTop: '10px'
  },
  formControl: {
    margin: theme.spacing(3),
    textAlign: "left",
    maxWidth: "300px",
    marginLeft: "145px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "80px",
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
    textAlign: "left",
    //paddingLeft: '125px',
    marginTop: "20px",
  },
  surveyWrapper: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: "100px",
    padding: "25px",
    minHeight: "600px",
    maxHeight: "750px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
      maxHeight: "100vh",
    },
  },
  formContainer: {
    textAlign: "center",
    maxWidth: "480px",
  },
  textField: {
    maxWidth: "220px",
  },
  sendButton: {
    backgroundColor: "#f44336!important",
    color: "#fff",
    minWidth: "200px",
    maxWidth: "200px",
    marginTop: "20px",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  logoWrapper: {
    width: "60px",
    height: "60px",
    borderRadius: "200px",
    borderWidth: "3px",
    borderColor: "#cfd8dc",
    borderStyle: "solid",
    marginRight: "auto",
    marginLeft: "auto",
  },
});

class UserFeedback extends Component {
  state = {
    value: "",
    text: "",
    userEmail: "",
    showText: false,
    loading: false,
    success: false,
    redirect: false,
    redirectMsg: false,
  };

  handleChange = event => {
    this.setState({ value: event.target.value });

    if (event.target.value === "option5 - other") {
      this.setState({ showText: !this.state.showText });
    } else {
      this.setState({ showText: false });
    }
  };

  handleTextChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = () => {
    let { text, value, userEmail } = this.state;
    if (!this.state.loading) {
      this.setState(
        {
          success: false,
          loading: true,
        },
        () => {
          // make axios call here and send data
          // console.log(text);
          // console.log(value);
          this.timer = setTimeout(() => {
            this.setState({
              loading: false,
              success: true,
            });
            window.scrollTo(0, 0);
            // repeat with the interval of 2 seconds
            /// this.redirectToLogin();
          }, 2000);
        }
      );
    }
  };

  redirectToLogin() {
    let { success } = this.state;
    if (success) {
      this.setState(
        {
          redirectMsg: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 2000);
        }
      );
    }
  }

  setUserEmail(token) {
    try {
      const decoded = decode(token);
      //console.log(decoded.a.email);
      this.setState({ userEmail: decoded.a.email });
      localStorage.removeItem("token");
    } catch (err) {
      handleError(
        "could not decode token on UserFeedback.js line 172",
        window.location.pathname
      );
    }
  }

  componentDidMount() {
    this.setUserEmail(localStorage.getItem("token"));
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    clearTimeout(this.redirectTimer);
  }

  render() {
    const { loading, success } = this.state;
    const { classes } = this.props;
    // how does this work
    // const buttonClassname = classNames({
    //   [classes.buttonSuccess]: success,
    // });

    //console.log(buttonClassname);

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Link to="/login">
              <Typography
                className="headerlogo"
                variant="h6"
                color="inherit"
                noWrap
              >
                <img
                  className="logoimage"
                  src="/stt-logo.svg"
                  alt="stt-logo.svg"
                />
                <span>Search Texas Tax</span>
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>
        <Paper className={classes.surveyWrapper}>
          <Grid container className={classes.formContainer}>
            <div className={classes.logoWrapper}>
              <STT_SVG />
            </div>
            <Grid item xs={12}>
              {this.state.success ? (
                <p className="success">We Appreciate your feedback!</p>
              ) : null}
              {this.state.redirectMsg ? <small>Redirecting...</small> : null}
              {this.state.redirect ? <Redirect to="/login" /> : null}
              <div style={{ marginTop: "20px" }}>
                <Typography variant="h4">We value Your Feedback!</Typography>
                <div style={{ textAlign: "center" }}>
                  <p>Please select a reason for ubsubscribing.</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  Please select a reason.
                </FormLabel>
                <RadioGroup
                  aria-label="reasons"
                  name="reason"
                  className={classes.group}
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <FormControlLabel
                    value="option1 - too expensive"
                    control={<Radio />}
                    label="It's too expensive."
                  />
                  <FormControlLabel
                    value="option2 - too many technical difficulties"
                    control={<Radio />}
                    label="I'm having too many technical difficulties."
                  />
                  <FormControlLabel
                    value="option3 - Didn't work as expected"
                    control={<Radio />}
                    label="Didn't work as expected."
                  />
                  <FormControlLabel
                    value="option4 - Not using the site often enough."
                    control={<Radio />}
                    label="Not using the site often enough."
                  />
                  <FormControlLabel
                    value="option5 - other"
                    control={<Radio />}
                    label="Other (please specify)."
                  />
                </RadioGroup>
                {this.state.showText ? (
                  <TextField
                    id="outlined-multiline-static"
                    label="Please Specify"
                    multiline
                    rows="5"
                    inputProps={{ maxLength: 500 }}
                    value={this.state.text}
                    onChange={this.handleTextChange("text")}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                  />
                ) : null}
                {this.state.success ? (
                  <Button variant="contained" className={classes.sendButton}>
                    Sent!
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.sendButton}
                    disabled={loading}
                    onClick={this.handleSubmit}
                  >
                    Send <Send className={classes.rightIcon} />
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(UserFeedback);
