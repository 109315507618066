import React, { Component } from 'react'
import { Link } from 'react-router-dom';
//import Footer from '../components/Footer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


const styles = {
  root: {
    width: '100%',
    height: '100vh',
    paddingTop: 125,

    //marginTop: 50,
  },

  typography: {
    padding: 12,
    fontWeight: 'bold'
  },
  link: {
    fontSize: 22

  },

};


class NotFound extends Component {

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Grid container className={classes.grid} direction="column" alignItems="center" justify="space-between">
          <Grid item xs={12}><Typography className={classes.typography} component="h3" variant="h3">Oops!</Typography></Grid>
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.typography} component="h4" variant="h4">Page Not Found.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.typography} variant="h5">Sorry, the URL you are trying to reach does not exist.</Typography>
          </Grid>
          <Grid container direction="column" alignItems="center" justify="space-between">
            <Grid item xs={12}>
              <Typography className={classes.link} variant="subtitle1">You may have typed in a wrong location. </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.link} variant="subtitle1"> Please try again by returning to the <Link className="link-style" to="/stt/search"><strong>Search page here.</strong></Link></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(NotFound);
