import React from "react";
import axios from "axios";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import { Chart } from "react-google-charts";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";

let pathname = window.location.pathname;

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    margin: 0,
  },
  pinkAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#d50000",
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 13,
  },
  greenAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#0d47a1",
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 12,
  },
  recent: {},
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "relative",
    bottom: theme.spacing(10),
    left: theme.spacing(170),
  },
});
// const portfoliotypes = [
//   {
//     value: "0",
//     label: "Hotel"
//   },
//   {
//     value: "1",
//     label: "MBT"
//   }

// ];
class Trends extends React.Component {
  state = {
    posts: [],
    token: "",
    dataLoadingStatus: "loading",
    chartData: [],
    value: 0,
    type: "",
    portfolio: "",
    errormsg: false,
    barArray: [],
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    //////console.log(event.target.value);
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
    //////console.log("ji");
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ errormsg: false });
    this.setState({ portfolio: "" });
  };
  componentDidMount() {
    document.title = "Hotel Trends- Search Texas Tax";
    ReactGA.initialize("UA-80211377-3");
    ReactGA.pageview(window.location.pathname);

    axios({
      method: "get",
      url: `${apiUrl}/rest/admin/newhotels`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
    })
      .then(res => {
        const posts = res.data;
        var barArray = [];

        this.setState({ posts: posts });
        barArray.push(["Year", "Number of Hotels added each Year"]);

        try {
          for (var i = 0; i < posts.length; i++) {
            if (posts[i].year !== 2000) {
              barArray.push([posts[i].year.toString(), posts[i].newHotels]);
            }
          }
          //console.log(barArray);
          this.setState({
            barArray: barArray,
            dataLoadingStatus: "ready",
          });
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
  }

  render() {
    //const { classes, theme } = this.props;
    //const {  anchorEl } = this.state;
    //const open = Boolean(anchorEl);
    const { value } = this.state;

    let seconddonut;
    if (this.state.dataLoadingStatus === "ready") {
      seconddonut = (
        <Chart
          chartType="Bar"
          width="100%"
          height="400px"
          data={this.state.barArray}
          options={{
            axes: {
              // Adds labels to each axis; they don't have to match the axis names.
              y: {
                Total_Revenue: { label: "Monthly Revenue" },
                all: {
                  format: {
                    pattern: "decimal",
                  },
                },
              },
            },
            legend: { position: "none" },
          }}
        />
      );
    } else {
      seconddonut = (
        <div className="loadingparent">
          {" "}
          <CircularProgress className="loading" size={50} />
        </div>
      );
    }
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <AppBar position="static">
              <Tabs
                className="parenttab"
                value={value}
                onChange={this.handleChange}
                scrollable
                scrollButtons="auto"
              >
                <Tab
                  className="tablabel"
                  label="New Hotels Added each Year (This includes Single Unit Properties) "
                />
              </Tabs>
            </AppBar>
            {value === 0 && <TabContainer>{seconddonut}</TabContainer>}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <MaterialTable
            columns={[
              {
                title: "Year",
                field: "year",
                type: "numeric",
                defaultSort: "desc",
              },
              { title: "New Hotels", field: "newHotels", type: "numeric" },
            ]}
            data={this.state.posts}
            title="New Hotels Added Each Year"
            options={{
                pageSizeOptions: [10, 20, 50, 100],
              pageSize: 5,
              search: false,
              columnsButton: false,
              exportButton: false,

              showEmptyDataSourceMessage: true,
            }}
          />

          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
        </Grid>
      </Grid>
    );
  }
}
Trends.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Trends);
