import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
// import { withGoogleMap, GoogleMap, InfoWindow, withScriptjs } from 'react-google-maps'
import GoogleMapReact from 'google-map-react'
import GoogleMapMarkers from './GoogleMapMarker'
import API from '../utils/API'

const styles = theme => ({
  googleMapRender: {
    [theme.breakpoints.down('xs')]: {
      height: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '300px',
    },
    [theme.breakpoints.up('md')]: {
      height: '200px',
    },
  },
})

class GoogleMap extends React.Component {
  state = {
    showInfoWindow: false,
    index: '',
    address: this.props.address,
  }

  static defaultProps = {
    zoom: 15,
  }

  handleMouseOver = index => {
    return () => {
      this.setState({ showInfoWindow: true, index: index })
    }
  }

  handleMouseExit = (index, props) => {
    this.setState({ showInfoWindow: false })
  }

  renderInfoWindow = props => {}

  render() {
    const { classes, theme } = this.props

    return (
      // <div style={{ height: '200px'}}>
      <div className={classes.googleMapRender}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBmJVfKWEzlTuTmzkLA3AZ1RoEpFqJlUB4' }}
          center={{
            lat: Number(`${this.props.lat}`),
            lng: Number(`${this.props.long}`),
          }}
          defaultZoom={this.props.zoom}
        >
          <GoogleMapMarkers
            mouseOver={this.handleMouseOver(0)}
            mouseOut={this.handleMouseExit}
            lat={this.props.lat}
            lng={this.props.long}
            text="my Marker"
            color="blue"
          >
            {/* {(this.state.showInfoWindow === true) ? (
                                    <div>This is the window {`${this.state.address}`}</div> ) : false
                                     }  */}
            {/* {this.renderInfoWindow()} */}
          </GoogleMapMarkers>
        </GoogleMapReact>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(GoogleMap))
