import React from 'react';
import {STT_SVG} from '../../utils/logo/SttLogoExport';
import Typography from "@material-ui/core/Typography";
import moment from 'moment';
import Script from 'react-load-script';


export default function Maitenance() {
    return (
        <div className="page-body">
            {/* <h4 style={{ marginTop: '-100px', marginBottom: '80px', fontWeight: 400}}>Sorry !</h4> */}
        <div class="c-animation">
            <div class="c-gear">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 26.531 27.063" className="gear gear2" gear-status="start" gear-duration="start" id="gear2">
                    <path id="gear_01" class="cls-1" d="M198.092,158.179q0.357-.641.661-1.3,0.222-.477.418-0.9,3.816-.013,3.96-0.919,0.169-.837-3.4-2.1a6.677,6.677,0,0,0-.167-2.153q3.546-1.968,3.258-2.791-0.314-.893-4.228-0.071a11.459,11.459,0,0,0-1.128-1.677l0.064-.137q2.158-3.42,1.488-4.018t-3.752,2.039l-0.185.15a9.378,9.378,0,0,0-.853-0.457l-0.646-.3c0-.079,0-0.159,0-0.238q0.248-4.134-.62-4.312t-2.354,3.733l-0.086.186a7.084,7.084,0,0,0-1.987.166l-0.076-.085q-1.82-3.552-2.659-3.314-0.816.309-.245,4.253a0.663,0.663,0,0,0,.027.19,12.412,12.412,0,0,0-1.83,1.271l-0.04-.018q-3.377-2.143-3.978-1.45-0.588.641,1.936,3.774a0.409,0.409,0,0,1,.119.114,10.411,10.411,0,0,0-.488.924q-0.217.468-.389,0.959a0.394,0.394,0,0,1-.165-0.017q-4.016-.032-4.16.875t3.578,2.188l0.181,0.084a5.929,5.929,0,0,0,.152,1.734q-3.553,1.963-3.288,2.835,0.312,0.893,4.224.079a12.645,12.645,0,0,0,1.062,1.695q-2.049,3.3-1.416,3.885t3.64-1.924q0.577,0.328,1.176.607l0.552,0.257q-0.185,3.877.694,4.051,0.82,0.156,2.243-3.371a8.914,8.914,0,0,0,2.283-.205q1.744,3.34,2.56,3.032,0.793-.26.206-4.045a10.456,10.456,0,0,0,1.674-1.06q3.159,1.994,3.785,1.371Q200.433,161.108,198.092,158.179Zm-5.784.984a6.375,6.375,0,0,1-5.14-.152,6.265,6.265,0,0,1-3.423-3.838,6.608,6.608,0,0,1,.271-5.156,6.684,6.684,0,0,1,3.8-3.58,6.264,6.264,0,0,1,5.14.152,6.37,6.37,0,0,1,3.423,3.837,6.676,6.676,0,0,1-.3,5.212A6.6,6.6,0,0,1,192.308,159.163Z" transform="translate(-176.594 -139)"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="50" viewBox="0 0 26.531 27.063" className="gear1" gear-status="start" gear-duration="start" id="gear1">
                    <path id="gear_01" class="cls-1" d="M198.092,158.179q0.357-.641.661-1.3,0.222-.477.418-0.9,3.816-.013,3.96-0.919,0.169-.837-3.4-2.1a6.677,6.677,0,0,0-.167-2.153q3.546-1.968,3.258-2.791-0.314-.893-4.228-0.071a11.459,11.459,0,0,0-1.128-1.677l0.064-.137q2.158-3.42,1.488-4.018t-3.752,2.039l-0.185.15a9.378,9.378,0,0,0-.853-0.457l-0.646-.3c0-.079,0-0.159,0-0.238q0.248-4.134-.62-4.312t-2.354,3.733l-0.086.186a7.084,7.084,0,0,0-1.987.166l-0.076-.085q-1.82-3.552-2.659-3.314-0.816.309-.245,4.253a0.663,0.663,0,0,0,.027.19,12.412,12.412,0,0,0-1.83,1.271l-0.04-.018q-3.377-2.143-3.978-1.45-0.588.641,1.936,3.774a0.409,0.409,0,0,1,.119.114,10.411,10.411,0,0,0-.488.924q-0.217.468-.389,0.959a0.394,0.394,0,0,1-.165-0.017q-4.016-.032-4.16.875t3.578,2.188l0.181,0.084a5.929,5.929,0,0,0,.152,1.734q-3.553,1.963-3.288,2.835,0.312,0.893,4.224.079a12.645,12.645,0,0,0,1.062,1.695q-2.049,3.3-1.416,3.885t3.64-1.924q0.577,0.328,1.176.607l0.552,0.257q-0.185,3.877.694,4.051,0.82,0.156,2.243-3.371a8.914,8.914,0,0,0,2.283-.205q1.744,3.34,2.56,3.032,0.793-.26.206-4.045a10.456,10.456,0,0,0,1.674-1.06q3.159,1.994,3.785,1.371Q200.433,161.108,198.092,158.179Zm-5.784.984a6.375,6.375,0,0,1-5.14-.152,6.265,6.265,0,0,1-3.423-3.838,6.608,6.608,0,0,1,.271-5.156,6.684,6.684,0,0,1,3.8-3.58,6.264,6.264,0,0,1,5.14.152,6.37,6.37,0,0,1,3.423,3.837,6.676,6.676,0,0,1-.3,5.212A6.6,6.6,0,0,1,192.308,159.163Z" transform="translate(-176.594 -139)"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16.688" height="17.688" viewBox="0 0 16.688 17.688" className="gear gear3" id="gear3">
                    <path id="gear_03" class="cls-1" d="M210.045,131.931a5.318,5.318,0,0,0-1.057.161,9.331,9.331,0,0,0-1-.909q-0.417-.325-0.812-0.576a8.012,8.012,0,0,0-.124-0.947q-0.3-2.691-1.2-2.8-0.956-.145-1.9,2.429a7.584,7.584,0,0,0-.273.773,5.746,5.746,0,0,0-2.131.852q-0.56-.3-1.035-0.536-2.48-1.074-3.024-.376-0.576.739,1.1,2.841a10.53,10.53,0,0,0,.787.942,6.043,6.043,0,0,0-.182,2.112,7.21,7.21,0,0,0-.89.631q-2.235,1.5-1.863,2.388,0.328,0.844,2.973.529c0.388-.052.814-0.117,1.275-0.194a5.607,5.607,0,0,0,.589.527,6.2,6.2,0,0,0,.97.621q0.056,0.7.131,1.36,0.33,2.636,1.209,2.789,0.939,0.077,1.89-2.422,0.255-.662.468-1.357a5.329,5.329,0,0,0,1.6-.609,10.027,10.027,0,0,0,.985.44q2.49,1.149,3.066.409,0.544-.7-1.142-2.874-0.309-.376-0.652-0.78a4.564,4.564,0,0,0,.255-1.783,7.919,7.919,0,0,0,1.1-.727q2.193-1.53,1.863-2.388T210.045,131.931Zm-2.1,3.118a3.2,3.2,0,0,1-.662,2.463,3.263,3.263,0,0,1-2.262,1.283,3.211,3.211,0,0,1-2.459-.662,3.389,3.389,0,0,1-.6-4.769,3.2,3.2,0,0,1,2.229-1.241,3.287,3.287,0,0,1,2.509.7A3.216,3.216,0,0,1,207.943,135.049Z" transform="translate(-196.375 -126.875)"/>
                </svg>
            </div>
        </div>
        <span className="logoimageM"><STT_SVG /></span>
        <div style={{ marginTop: '15px', textAlign: 'center'}}>
            <Typography className="headerlogoM" variant="h6" color="inherit"> <span className='site-title'>Search Texas Tax</span> is undergoing maintenance.</Typography>
            <p style={{ marginTop: '5px', fontSize: '1.1rem'}}>Please check back at a later time.</p>
            <div className="footerM">
                <small>© {moment().format("YYYY")} Search Texas Tax <br /><a href="//searchtexastax.com/privacy.php">Privacy</a> • <a href="//searchtexastax.com/terms.php">Terms</a></small>
            </div>
        </div>
        </div>

       
    )
}
