
// How many years user can select at a time
export const durations = [
    {
      value: "1",
      label: "1 year"
    },
    {
      value: "3",
      label: "3 years"
    },
    {
      value: "5",
      label: "5 years"
    },
    {
      value: "10",
      label: "10 years"
    },
    {
      value: "100",
      label: "All"
    }
]


// How many years user can select at a time
export const durations1 = [
    {
      value: "1",
      label: "1 year"
    },
    {
      value: "3",
      label: "3 years"
    },
    {
      value: "5",
      label: "5 years"
    },
    {
      value: "10",
      label: "10 years"
    },
    {
      value: "100",
      label: "All"
    }
  ];

export const durationsttm = [
    {
      value: "1",
      label: "1 year"
    },
    {
      value: "3",
      label: "3 years"
    },
    {
      value: "5",
      label: "5 years"
    },
    {
      value: "10",
      label: "10 years"
    },
    {
      value: "100",
      label: "All"
    }
  ];

export const durationsttmrevpar = [
    {
      value: "1",
      label: "1 year"
    },
    {
      value: "3",
      label: "3 years"
    },
    {
      value: "5",
      label: "5 years"
    },
    {
      value: "10",
      label: "10 years"
    },
    {
      value: "100",
      label: "All"
    }
  ];
  