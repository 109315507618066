import React from 'react'
import {
  Route,
  Switch
} from "react-router-dom";
import Trial from './Trial'
import TrialCity from './TrialCity'
import TrialCounty from './TrialCounty'

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const MainRoute = () => (
  <main>
    <Switch>

      <Route path='/trial/hotel' component={Trial} />
      <Route path='/trial/city' component={TrialCity} />
      <Route path='/trial/county' component={TrialCounty} />

    </Switch>
  </main>
)

export default MainRoute