import React from 'react'
import {
  Route,
  Switch,
} from "react-router-dom";
import Search from './Search'
import SearchResults from './SearchResults';
import SearchResultsUni from './SearchResultsUni';
import Portfolio from './Portfolio'
import City from './City'
import MBTCity from './MBTCity'
import County from './County'
import MBTCounty from './MBTCounty'
import HotelInfo from './HotelInfo'
import Brands from './Brands'
import Trends from './Trends'
import MBTHotelInfo from './MBTHotelInfo'
import Settings from './Settings'
import UpdatePayment from './UpdatePayment'
import Login from './Login'
import NotFound from './NotFound';
import HotelInfoRedirect from './HotelInfoRedirect';
import PrivateAdminRoute from './routing/PrivateAdminRoute';
import PaymentUpdateRoute from '../components/routing/PaymentUpdateRoute';
// import Test from './routing/Test';


import RecentlyViewed from './RecentlyViewed'
import PortfolioHotel from './PortfolioHotel'
import BrandInsights from './BrandInsights'
import BrandParent from './BrandParent'
import UpdateCard from './UpdateCard';
import Admin from './Admin';




// import Button from '@material-ui/core/Button';
// import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

//import { NotFoundProvider } from './globalState/404Message';
//import AuthState from '../context/Auth/AuthState';


const MainRoute = () => (
  <main className="main-root"> 
    {/* <AuthState> */}
    <Switch>
      <Route exact path='/login' component={Login} />
      <Route path='/stt/search' component={Search} />
      <Route exact path='/stt/results/:type/:month/:year/:item/:itemvalue' component={SearchResults} />
      <Route exact path='/stt/results/:type/:item/:itemvalue' component={SearchResultsUni} />
      <Route path='/stt/portfolio' component={Portfolio} />
      <Route path='/stt/trends' component={Trends} />
      <Route path='/stt/recentlyviewed' component={RecentlyViewed} />
      <Route path='/stt/settings' component={Settings} />
      <Route path='/stt/updatepayment' component={UpdatePayment} />
      <Route path='/stt/portfoliohotel/:type/:id/:portfolio' component={PortfolioHotel} />
      <Route path='/stt/brands' component={Brands} />
      <Route path='/stt/city/:city' component={City} />
      <Route path='/stt/mbtcity/:city' component={MBTCity} />
      <Route path='/stt/county/:county' component={County} />
      <Route path='/stt/mbtcounty/:county' component={MBTCounty} />
      <Route path='/stt/brand/:id/:brand/:brandNot/:name' component={BrandInsights} />
      <Route path='/stt/brand/:parentId/:parent' component={BrandParent} />
      <Route path='/stt/hotelinfo/:hotelname/:taxpayernumber/:locationnumber' component={HotelInfo} />
      <Route path='/stt/mbthotelinfo/:hotelname/:taxpayernumber/:locationnumber' component={MBTHotelInfo} />
      <Route exact path='/stt/mbtredirect' component={HotelInfoRedirect} />
      <PaymentUpdateRoute exact path='/stt/updatecard' component={UpdateCard} />
      <PrivateAdminRoute path='/stt/admin' component={Admin} />
      {/* <Route path='/stt/test/:value' component={Test} /> */}
      <Route component={NotFound} />
    </Switch>
    {/* </AuthState> */}
  </main>
)

export default MainRoute