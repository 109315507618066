import React, { Component } from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement, PostalCodeElement, injectStripe } from 'react-stripe-elements';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles'; 

//Import React Scrit Libraray to load Google object
import Script from 'react-load-script';
import { apiUrl, GM_KEY } from '../../helpers';
import { handleError } from '../../utils/errorHandling/errorHelper';


const styles = theme => ({
    GridMargin: {
        marginBottom: '0px',
        marginTop: '0px'
    },
    checkoutButton: {
        color: "#fff",
        backgroundColor: '#d50000',
        "&:hover": {
            backgroundColor: '#ef5350'
        }
    },
    formWrapper: {
        padding: '25px',
        margin: '0 auto'
    },
    confirmWrapper: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    paymentDetailsWrapper: {
        padding: '10px 15px 10px 10px',
        marginTop: '-10px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '0px',
        },
    },
    billingDetailsWrapper: {
        padding: '10px 10px 10px 15px',

    },
    checkWrapper: {
        marginTop: '15px'
    },
    updateCardWrapper: {
        maxWidth: '500px',
        minHeight: '780px',
        marginLeft: 'auto',
        marginRight: 'auto',
        
    },
    updateCardMsg: {
        marginTop: '10px',
        fontSize: '1rem',
        color: '#78909c'
    }
});

const google = window.google = window.google ? window.google : {}



class BillingForm extends Component {

    constructor(props) {
        super(props);
        // need to pass state up to parent
        // console.log('billing form props')
        // console.log(props);
        this.state = {
            cardErrorMsg: null,
            expiryErrorMsg: null,
            cvcErrorMsg: null,
        }

    }



    // ==== Stripe Elements onchange methods to detect errors =====
    stripeError(e) {
        if (e.error) {
            //console.log(e);
            if (e.elementType === "cardNumber") {
                this.setState({ cardErrorMsg: e.error.message });
            } else if (e.elementType === "cardExpiry") {
                this.setState({ expiryErrorMsg: e.error.message });
            }
        } else {
            this.setState({ cardErrorMsg: null, expiryErrorMsg: null });
        }
    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.checkoutForm ?

                    // ======== Checkout Form =======
                    <Grid container spacing={3}>
                        <Grid xs={12} sm={6} md={6} lg={7} >
                            <Grid container spacing={3} className={classes.billingDetailsWrapper} >
                                <Grid item xs={12}>
                                    <Typography variant="h6" className="billing_title">Billing Details</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-required"
                                        label="Billing Name"
                                        value={this.props.name}
                                        onChange={this.props.handleChange('name')}
                                        margin="normal"
                                        variant="outlined"
                                        className="billingForm_input"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="autocomplete"
                                        label="Street Address"
                                        value={this.props.fullstreet_address}
                                        onChange={this.props.handleChange('fullstreet_address')}
                                        margin="normal"
                                        variant="outlined"
                                        className="billingForm_input"

                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Secondary Address (optional)"
                                        value={this.props.address_line2}
                                        onChange={this.props.handleChange('address_line2')}
                                        margin="normal"
                                        variant="outlined"
                                        className="billingForm_input"
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        id="locality"
                                        label="City"
                                        value={this.props.locality}
                                        onChange={this.props.handleChange('locality')}
                                        margin="normal"
                                        variant="outlined"
                                        className="billingForm_input"
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        id="administrative_area_level_1"
                                        label="State"
                                        value={this.props.administrative_area_level_1}
                                        onChange={this.props.handleChange('administrative_area_level_1')}
                                        margin="normal"
                                        variant="outlined"
                                        className="billingForm_input"
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        id="country"
                                        label="Country"
                                        value={this.props.country}
                                        onChange={this.props.handleChange('country')}
                                        margin="normal"
                                        variant="outlined"
                                        className="billingForm_input"
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        id="postal_code"
                                        label="ZipCode"
                                        value={this.props.postal_code}
                                        onChange={this.props.handleChange('postal_code')}
                                        margin="normal"
                                        variant="outlined"
                                        className="billingForm_input"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} lg={5} >
                            <Grid container spacing={3} className={classes.paymentDetailsWrapper}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" className="payment_title">Payment Details</Typography>
                                </Grid>
                                <Grid item xs={12} className="stripe_element_wrapper">
                                    {this.state.cardErrorMsg !== null ? <small className="stripe_error_msg1">{this.state.cardErrorMsg}</small> : null}
                                    <CardNumberElement className="stripe_element" placeholder="Card Number" onChange={(e) => this.stripeError(e)} />
                                </Grid>
                                <Grid item xs={6} className="stripe_element_wrapper">
                                    {this.state.expiryErrorMsg !== null ? <small className="stripe_error_msg2">{this.state.expiryErrorMsg}</small> : null}
                                    <CardExpiryElement className="stripe_element" placeholder="Expiry - MM/YY" onChange={(e) => this.stripeError(e)} />
                                </Grid>
                                <Grid item xs={6} className="stripe_element_wrapper" >
                                    {this.state.cvcErrorMsg !== null ? <small className="stripe_error_msg">{this.state.cvcErrorMsg}</small> : null}
                                    <CardCVCElement className="stripe_element" placeholder="CVC" onChange={(e) => this.stripeError(e)} />
                                </Grid>
                                {this.props.monthlyPlan ?
                                    <Grid item xs={12} className={classes.confirmWrapper}>
                                        <h3 style={{ marginBottom: '0px', marginTop: '0px' }}>STT Member Agreement</h3>
                                        <Grid item xs={12}>
                                            <small >
                                                By checking this box you are agreeing to SearchTexasTax's <a href="https://searchtexastax.com/terms.php" target="_blank">Terms & Conditions</a>, and you acknowledge that your credit card will be billed monthly starting today, <em>{moment().format('L')}.</em>
                                            </small>
                                            <br />
                                        </Grid>
                                        <Grid container className={classes.checkWrapper}>
                                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                                <Checkbox
                                                    checked={this.props.checkMonthly}
                                                    onChange={this.props.handleCheck('checkMonthly')}
                                                    value="checkMonthly"
                                                />
                                                <span style={{ marginTop: '5px' }}>Yes, I agree.</span>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                                <Button className={classes.checkoutButton} variant="contained" size="large" color="primary" onClick={this.props.submitPayment} disabled={this.props.disabled}>Subscribe & Pay</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid item xs={12} className={classes.confirmWrapper}>
                                        <h3 style={{ marginBottom: '0px', marginTop: '0px' }}>STT Member Agreement</h3>
                                        <Grid item xs={12}>
                                            <small >
                                                By checking this box you are agreeing to SearchTexasTax's <a href="https://searchtexastax.com/terms.php" target="_blank">Terms & Conditions</a>, and you acknowledge that your credit card will be billed annually starting today, <em>{moment().format('L')}.</em>
                                            </small>
                                            <br />
                                        </Grid>
                                        <Grid container className={classes.checkWrapper}>
                                            <Grid item xs={12} sm={12} md={5} lg={5}>
                                                <Checkbox
                                                    checked={this.props.checkMonthly}
                                                    onChange={this.props.handleCheck('checkMonthly')}
                                                    value="checkMonthly"
                                                />
                                                <span style={{ marginTop: '5px' }}>Yes, I agree.</span>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                                <Button className={classes.checkoutButton} variant="contained" size="large" color="primary" onClick={this.props.submitPayment} disabled={this.props.disabled}>Subscribe & Pay</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    // ====== Update Card Form ======
                    <Grid container spacing={3} className={classes.updateCardWrapper} >
                        <Grid item xs={12}>
                            <Typography variant="h5" color="primary">
                                New Card Details
                            </Typography>
                            <Typography className={classes.updateCardMsg}>Enter your billing address and payment details</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-required"
                                label="Billing Name"
                                value={this.props.name}
                                onChange={this.props.handleChange('name')}
                                margin="normal"
                                variant="outlined"
                                className="billingForm_input"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="autocomplete"
                                label="Street Address"
                                value={this.state.fullstreet_address}
                                onChange={this.props.handleChange('fullstreet_address')}
                                margin="normal"
                                variant="outlined"
                                className="billingForm_input"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Secondary Address (optional)"
                                value={this.state.address_line2}
                                onChange={this.props.handleChange('address_line2')}
                                margin="normal"
                                variant="outlined"
                                className="billingForm_input"
                            />
                        </Grid>
                        <Grid item xs={12} sm={7} md={7} lg={7}>
                            <TextField
                                id="locality"
                                label="City"
                                value={this.props.locality}
                                onChange={this.props.handleChange('locality')}
                                margin="normal"
                                variant="outlined"
                                className="billingForm_input"
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <TextField
                                id="administrative_area_level_1"
                                label="State"
                                value={this.props.administrative_area_level_1}
                                onChange={this.props.handleChange('administrative_area_level_1')}
                                margin="normal"
                                variant="outlined"
                                className="billingForm_input"
                            />
                        </Grid>
                        <Grid item xs={12} sm={7} md={7} lg={7}>
                            <TextField
                                id="country"
                                label="Country"
                                value={this.props.country}
                                onChange={this.props.handleChange('country')}
                                margin="normal"
                                variant="outlined"
                                className="billingForm_input"
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={5}>
                            <TextField
                                id="postal_code"
                                label="ZipCode"
                                value={this.props.postal_code}
                                onChange={this.props.handleChange('postal_code')}
                                margin="normal"
                                variant="outlined"
                                className="billingForm_input"
                            />
                        </Grid>
                        <Grid item xs={12} >
                            {this.state.cardErrorMsg !== null ? <small className="stripe_error_msg4">{this.state.cardErrorMsg}</small> : null}
                            <CardNumberElement className="stripe_element2" placeholder="Card Number" onChange={(e) => this.stripeError(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6} mg={6} lg={6} className="stripe_element_wrapper2">
                            {this.state.expiryErrorMsg !== null ? <small className="stripe_error_msg3">{this.state.expiryErrorMsg}</small> : null}
                            <CardExpiryElement className="stripe_element2" placeholder="Expiry - MM/YY" onChange={(e) => this.stripeError(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6} mg={6} lg={6} className="stripe_element_wrapper2">
                            {this.state.cvcErrorMsg !== null ? <small className="stripe_error_msg3">{this.state.cvcErrorMsg}</small> : null}
                            <CardCVCElement className="stripe_element2" placeholder="CVC" onChange={(e) => this.stripeError(e)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={classes.checkoutButton} variant="contained" size="large" onClick={this.props.submitUpdate}>Update Card</Button>
                        </Grid>
                    </Grid>
                }

                <Script url={`https://maps.googleapis.com/maps/api/js?key=${GM_KEY}&libraries=places`}
                    onLoad={this.props.initAutocomplete}
                />
            </div>

        )
    }
}

export default withStyles(styles)(injectStripe(BillingForm));
