import React from 'react';
import moment from 'moment';
const Footer = () => (
  <div className="footer">

    <p>© {moment().format("YYYY")} Search Texas Tax • Abilene v1.19.10 <br /><a href="//searchtexastax.com/privacy.php">Privacy</a> • <a href="//searchtexastax.com/terms.php">Terms</a></p>

  </div>


)

export default Footer