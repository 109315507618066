import React from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ReactGA from "react-ga";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { apiUrl } from "../helpers";
import { handleError } from "../utils/errorHandling/errorHelper";
import Fab from "@material-ui/core/Fab";

let pathname = window.location.pathname;

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fab: {
    margin: theme.spacing(2),
  },
  avatar: {
    margin: 0,
  },
  pinkAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#d50000",
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 13,
  },
  greenAvatar: {
    margin: 0,
    color: "#fff",
    backgroundColor: "#0d47a1",
    width: 50,
    height: 30,
    borderRadius: 5,
    fontSize: 12,
  },
  absolute: {
    backgroundColor: "#d50000",
    float: "right",
    "&:hover": {
      backgroundColor: "#f44336",
    },
  },
});
const portfoliotypes = [
  {
    value: "0",
    label: "Hotel",
  },
  {
    value: "1",
    label: "MBT",
  },
];
class Portfolio extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      token: "",
      dataLoadingStatus: "loading",
      chartData: [],
      value: 0,
      type: "0",
      portfolio: "",
      errormsg: false,
      id: "",
      portfoliotypes: 0,
      open: false,
      pid: "",
      title: "Create New Portfolio",
      confirmDelete: false,
      nameValidation: false,
      label: "Portfolio Name",
      selectedItem: "",
      loading: true,
      displayMessage: "SEARCHING...",
    };

    this.toggleDeleteConfirm = this.toggleDeleteConfirm.bind(this);
  }

  handleChange = name => event => {
    try {
      let filterPercent = /%/;
      let test1 = filterPercent.test(event.target.value);
      if (test1) {
        this.setState({ nameValidation: true });
      } else {
        this.setState({
          [name]: event.target.value,
        });
        this.setState({ nameValidation: false });
      }
    } catch (err) {
      handleError(err, pathname);
    }
  };

  handleClick() {
    if (this.state.portfolio === "") {
      this.setState({ errormsg: true });
      return;
    }
    axios({
      method: "post",
      url: `${apiUrl}/rest/portfolio/`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
      data: { portfolio: this.state.portfolio, type: this.state.type },
    })
      .then(res => {
        try {
          if (res.data) {
            this.setState({ anchorEl: null });
            axios({
              method: "get",
              url: `${apiUrl}/rest/portfolio`,
              headers: {
                authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
              },
            })
              .then(res => {
                const posts = res.data;
                this.setState({
                  posts: posts,
                  //loading: false
                });
              })
              .catch(e => {
                handleError(e, pathname);
              });
            this.setState({ portfolio: null });
            this.setState({ type: 0 });
            this.setState({ title: "Portfolio Created" });
            setTimeout(
              function() {
                this.setState({ title: "Create New Portfolio" });
              }.bind(this),
              5000
            );
          }
        } catch (err) {
          handleError(err, pathname);
        }
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
  }

  handleClickOpen = id => {
    this.setState({ open: true });
  };

  handleClickDelete() {
    this.setState({ confirmDelete: true });
  }

  toggleDeleteConfirm(id) {
    try {
      this.setState({ confirmDelete: !this.state.confirmDelete });
      let { posts } = this.state;
      posts.map(post => {
        if (post.id === id) {
          this.setState({ selectedItem: post });
        }
      });
    } catch (err) {
      handleError(err, pathname);
    }
  }

  handleConfirmedDelete(id) {
    try {
      this.setState({ confirmDelete: false });
      axios({
        method: "delete",
        url: `${apiUrl}/rest/portfolio`,
        headers: {
          authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
        },
        data: { id: id },
      })
        .then(res => {
          this.setState({ open: false });
          axios({
            method: "get",
            url: `${apiUrl}/rest/portfolio`,
            headers: {
              authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
            },
          })
            .then(res => {
              const posts = res.data;
              this.setState({ posts: posts });
            })
            .catch(e => {
              handleError(e, pathname);
              //window.location.replace('/logout');
            });
        })
        .catch(e => {
          handleError(e, pathname);
          //window.location.replace('/logout');
        });
    } catch (err) {
      handleError(err, pathname);
    }
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ errormsg: false });
    this.setState({ portfolio: "" });
    this.setState({ type: "0" });
    this.setState({ open: false });
  };

  componentDidMount() {
    document.title = "Portfolio - Search Texas Tax";
    ReactGA.initialize("UA-80211377-3");
    ReactGA.pageview(window.location.pathname);

    axios({
      method: "get",
      url: `${apiUrl}/rest/portfolio`,
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"), //the token is a variable which holds the token
      },
    })
      .then(res => {
        if (res.data.length === 0) {
          this.setState({
            displayMessage: "No Records Found.",
            loading: false,
          });
        } else {
          const posts = res.data;
          this.setState({ posts: posts, loading: false });
        }
      })
      .catch(e => {
        handleError(e, pathname);
        //window.location.replace('/logout');
      });
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    //const { value } = this.state;
    let errormsg;
    if (this.state.errormsg === true) {
      errormsg = (
        <p className="errormsg">Please Enter a portfolio name to create one.</p>
      );
    }

    return (
      //      <div className= "portfolio-table">
      <Grid container spacing={3} className="searchgrid">
        <Grid item xs={12}>
          <Paper className="data_title">
            <div className="row">
              <div className="col">
                <h1 className="h-port">Portfolio</h1>
                {/* <Link to ={encodeURIComponent(`/stt/test/${testValue2}`)} >Test</Link> */}
                <Tooltip title={this.state.title}>
                  <Fab
                    color="secondary"
                    className={classes.absolute}
                    onClick={this.handleMenu}
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </div>
              <div className="clear"></div>
              <div className="col">
                <Typography color="textSecondary">
                  Select a Portfolio that you would like to view below, or
                  create a new portfolio by clicking on the Add icon.
                </Typography>
              </div>
              <Menu
                className="addportfoliomenu"
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={this.handleClose}
              >
                {errormsg}
                <TextField
                  error={this.state.nameValidation}
                  id="outlined-required"
                  label="Portfolio Name"
                  className={classes.textField}
                  value={this.state.portfolio}
                  onChange={this.handleChange("portfolio")}
                  margin="normal"
                  variant="outlined"
                />

                <TextField
                  id="search_type"
                  select
                  label="Portfolio Type"
                  className={classes.textField}
                  value={this.state.type}
                  onChange={this.handleChange("type")}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {portfoliotypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <div className="get-data">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      this.handleClick();
                    }}
                  >
                    Create
                  </Button>
                </div>
              </Menu>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            isLoading={this.state.loading}
            columns={[
              {
                title: "Portfolio",
                field: "portfolio",
                render: rowData => {
                  return (
                    <Link
                      to={`/stt/portfoliohotel/${rowData.type}/${rowData.id}/${rowData.portfolio}`}
                    >
                      {rowData.portfolio}
                    </Link>
                  );
                },
              },
              {
                title: "Type",
                render: rowData => {
                  if (rowData.type === 0) {
                    return (
                      <Avatar className={classes.pinkAvatar}>Hotel</Avatar>
                    );
                  } else {
                    return <Avatar className={classes.greenAvatar}>MBT</Avatar>;
                  }
                },
              },
              {
                title: "Options",
                render: rowData => {
                  return (
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.toggleDeleteConfirm(rowData.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  );
                },
              },
            ]}
            data={this.state.posts}
            title="Available Portfolios"
            options={{
                pageSizeOptions: [10, 20, 50, 100],
              pageSize: 5,
              loadingType: "linear",
            }}
            localization={{
              body: {
                emptyDataSourceMessage: this.state.displayMessage,
              },
            }}
            // actions={[
            //   {
            //     icon: 'delete',
            //     tooltip: 'Delete User',
            //     onClick: (event, rowData) => this.handleConfirmedDelete( rowData.id)
            //   }
            // ]}
          />
        </Grid>

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />

        <Dialog
          open={this.state.confirmDelete}
          onClose={this.toggleDeleteConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`Delete "${this.state.selectedItem.portfolio}"  ?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once deleted the action cannot be undone. Still delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDeleteConfirm} color="primary">
              Nevermind
            </Button>
            <Button
              onClick={() =>
                this.handleConfirmedDelete(this.state.selectedItem.id)
              }
              color="primary"
              autoFocus
            >
              Yes, Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}
Portfolio.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Portfolio);
