import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Footer from './Footer';



const styles = {
  root: {
    width: '100%',
    height: '100vh',
    paddingTop: 125,

  },
  typography: {
    padding: 12,
    fontWeight: 'bold'
  },
  link: {
    fontSize: 22

  },
  url: {
    fontWeight: 'bold',
    color: '#1c54b2'
  }
};


class NotFound extends Component {

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Grid container className={classes.grid} direction="column" alignItems="center" justify="space-between">
          <Grid className={classes.grid} item xs={12}>
            <Typography className={classes.typography} component="h4" variant="h4">404 Page Not Found.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.typography} variant="h5">Sorry, the URL you are trying to reach does not exist.</Typography>
          </Grid>
          <Grid container direction="column" alignItems="center" justify="space-between">
            {/* <Grid item xs={12}>
            <Typography className={classes.link} variant="subtitle1">You may have typed in a wrong location. </Typography>
          </Grid> */}
            <Grid item xs={12}>
              <Typography className={classes.link} variant="subtitle1"> Please try again by returning to the<Link to="/stt/search">   Login page here.</Link></Typography>
            </Grid>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }
}



export default withStyles(styles)(NotFound);