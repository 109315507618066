import React from "react";
import { Route, Switch } from "react-router-dom";
import HomeNav from "../layouts/HomeNav";
import TrialNav from "../layouts/TrialNav";
import PrivateRoute from "./PrivateRoute";
import PaymentRoute from "./PaymentRoute";
import ReturnUserRoute from "./routing/ReturnUserRoute";
import Login from "./Login";
import Logout from "./Logout";
import Register from "./Register";
import PaymentForm from "./PaymentForm";
import PasswordRequest from "./PasswordRequest";
import PasswordReset from "./PasswordReset";
import PaymentPlan from "./PaymentPlan";
import UserFeedback from "./UserFeedback";
import { loadReCaptcha } from "react-recaptcha-v3";
import { RECAPTCHA_SITE_KEY, apiUrl } from "../helpers";
import ReactGA from "react-ga";
import Maitenance from "./routing/Maitenance";
import NotFound2 from "./NotFound2";
import axios from "axios";
import { handleError } from "../utils/errorHandling/errorHelper";

let pathname = window.location.pathname;

//import PrivateRoute from './routing/PrivateRoute';
// ==== Will Test these  more eventualy ======
// import AuthState from '../context/Auth/AuthState';
// import AlertState from '../context/Alert/AlertState';
// import AlertSnackbar from './Alert';

const Idle = require("react-idle").default;

//@StorageContext("your-storage-id")
class App extends React.Component {
  state = {
    posts: "",
    maitenanceMode: false,
    timer: false,
  };

  componentDidMount() {
    loadReCaptcha(RECAPTCHA_SITE_KEY);
    // loadReCaptcha();
    //console.log(window.location.pathname);
    ReactGA.initialize("UA-80211377-3");
    ReactGA.pageview(window.location.pathname);

    // make the call here to see if server is down?
    axios
      .get(apiUrl + "/rest/basic/status")
      .then(res => {
        //console.log("maintence res", res)
        if (res.status === 200) {
          // sucessful, don't change anything
          this.setState({ maitenanceMode: false, timer: false });
        } else if (res.status === 503) {
          this.setState({ maitenanceMode: true, timer: true });
        }
      })
      .catch(e => {
        this.setState({ maitenanceMode: true, timer: true });
        handleError(e, pathname);
      });
  }

  componentDidUpdate(prevState) {
    if (this.state.timer === true && this.state.maitenanceMode === true) {
      // reset timer
      //console.log("component updated!")

      // keep checking every 2min until server we get 200 status
      setTimeout(
        function() {
          // make the call here to see if server is down?
          axios
            .get(apiUrl + "/rest/basic/status")
            .then(res => {
              //console.log("maintence res", res)
              if (res.status === 200) {
                // sucessful, don't change anything
                this.setState({ maitenanceMode: false, timer: false });
              } else if (res.status === 503) {
                this.setState({ maitenanceMode: true, timer: true });
              }
            })
            .catch(e => {
              this.setState({ maitenanceMode: true, timer: true });
              handleError(e, pathname);
            });
        }.bind(this),
        120000
      );
    }
  }

  render() {
    let { maitenanceMode } = this.state;
    return (
      <Idle
        // ==== timout in 10 hours if idle ? ====
        timeout={36000000}
        render={({ idle }) => (
          <div>
            {idle ? (
              window.location.replace("/logout")
            ) : (
              // <AuthState>
              //   <AlertState>
              //     <AlertSnackbar />
              <React.Fragment>
                {maitenanceMode ? (
                  <Switch>
                    <Route component={Maitenance} />
                  </Switch>
                ) : (
                  <Switch>
                    <PrivateRoute exact path="/stt/*" component={HomeNav} />
                    <Route exact path="/trial/*" component={TrialNav} />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/login" component={Login} />
                    <ReturnUserRoute exact path="/" component={Login} />
                    <Route
                      exact
                      path="/passwordreset/token/:token"
                      component={PasswordReset}
                    />
                    <Route
                      exact
                      path="/passwordrequest"
                      component={PasswordRequest}
                    />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/register/:email" component={Register} />
                    <Route exact path="/feedback" component={UserFeedback} />
                    <PaymentRoute
                      exact
                      path="/paymentplan/"
                      component={PaymentPlan}
                    />
                    <PaymentRoute
                      path="/paymentform/:subscriptionPlan"
                      component={PaymentForm}
                    />
                    <Route component={NotFound2} />
                  </Switch>
                )}
              </React.Fragment>
              //   </AlertState>
              // </AuthState>
            )}
          </div>
        )}
      />
    );
  }
}

export default App;
